export default function formatToIndianCurrency(amount) {
    if (amount == null || isNaN(amount)) return '₹ 0.00';
    amount = typeof amount !== 'number' ? parseFloat(amount) : amount;
    amount = Math.round(amount * 100) / 100;
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(amount);
  }
  