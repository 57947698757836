import axios from 'axios';


export async function fetchCompanyData(apiEndPoint, companyId) {
  if(!companyId) return;
  try {
    const companyResponse = await axios.get(`${apiEndPoint}/company/${companyId}`);
    return companyResponse.data;
  } catch (error) {
    console.error('Error fetching company data:', error);
    throw error;
  }
}


export async function fetchCompanyLogo(apiEndPoint, companyId) {
  if(!companyId) return;
  try {
    const payload = { entityId: companyId, fileCategory: 'logo', moduleName: 'company' };
    const logoResponse = await axios.post(`${apiEndPoint}/company/getFileSignedURL/`, payload);
    return Object.values(logoResponse.data)[0];
  } catch (error) {
    console.error('Error fetching company logo:', error);
    throw error;
  }
}
