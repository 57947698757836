<template>
  <div>
    <div
      class="modal fade"
      id="DebitNoteDetailsModal"
      tabindex="-1"
      aria-labelledby="DebitNoteDetailsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content d-flex flex-column">
          <div class="modal-header d-flex justify-content-between">
            <h5
              class="modal-title text-decoration-underline"
              id="DebitNoteDetailsModalLabel"
              style="visibility: hidden"
            >
              Purchase Order
            </h5>
            <div class="d-flex align-content-center">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-toggle="tooltip"
                data-placement="top-start"
                title="close"
              ></button>
            </div>
          </div>
          <div
            class="modal-body d-flex flex-column DebitNoteDetailsModal"
            ref="printContent"
          >
            <div class="content-section flex-grow-1">
              <p
                class="text-center text-decoration-underline"
                style="font-size: 18px; font-weight: 600"
              >
                Debit Note
              </p>
              <section style="margin-top: 20px">
                <div
                  class="d-flex justify-content-center flex-column"
                  style="margin: 0 5%"
                >
                  <div class="row mb-3">
                    <!-- Company Information -->
                    <div class="col-md-6 text-start" style="font-weight: 400">
                      <strong>{{ CompanyData.companyName }}</strong> <br />
                      {{ CompanyData.address }}, {{ CompanyData.city }}<br />
                      {{ CompanyData.state }} - {{ CompanyData.pincode }}<br />
                      <span>Phone No.: {{ CompanyData.contactNumber }}</span
                      ><br />
                      <span>Email ID: {{ CompanyData.companyEmail }}</span
                      ><br />
                      <span>GST No.: </span><br />
                    </div>
                    <div class="col-md-2 text-start"></div>
                    <!-- Company Logo and PO Info -->
                    <div class="col-md-4 text-start">
                      <img
                        v-if="$CompanyLogoUrl"
                        :src="$CompanyLogoUrl"
                        alt="Company Logo"
                        id="companylogoInput"
                        class="img-fluid mb-2"
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <!-- Vendor Address -->

                    <div class="col-md-6 text-start">
                      <div class="text-center p-2 w-75" style="background-color: #ccc">
                        Debit To
                      </div>
                      <br />
                      <br />

                      {{ DebitNote["vendors.companyName"] }}
                      {{ DebitNote["addresses.name"] }}, {{ DebitNote["addresses.city"]
                      }}<br />
                      {{ DebitNote["addresses.state"] }} -
                      {{ DebitNote["addresses.pincode"] }}<br />
                      <span>Phone No.: {{ DebitNote["addresses.contactNo"] }}</span
                      ><br />
                      <span>Email ID: {{ DebitNote["addresses.email"] }}</span
                      ><br />
                      <span>GST No.: </span><br />
                    </div>
                    <div class="col-md-2 text-start"></div>

                    <div class="col-md-4 text-start">
                      <strong style="visibility: hidden">Shipping Address</strong><br />
                      <span>Date: {{ DebitNote.date }}</span
                      ><br />
                      <span>Debit Note No. : {{ DebitNote.debitNoteNo }}</span
                      ><br />
                      <span>Invoice Document No.: {{ DebitNote.documentNo }} </span><br />
                      <br />
                      <div
                        class="text-center text-black p-2"
                        style="background-color: #ccc; margin-top: 20px"
                      >
                        Due Amount: &nbsp; &nbsp;
                        {{ $formatToIndianCurrency(DebitNote.totalAmount) }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive mt-1">
                  <table class="table table-bordered">
                    <caption class="d-none">
                      Product Details
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Product Name(Description)</th>
                        <th>HSN Code</th>
                        <th>Artical No.</th>
                        <th>Quantity</th>
                        <th>Unit Type</th>
                        <th>Unit Price</th>
                        <th>GST(%)</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in lineItems" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item["productLists.productName"] }} ({{
                            item["productLists.description"]
                          }})
                        </td>
                        <td class="text-end">
                          {{ item["productLists.HSNCode"] }}
                        </td>
                        <td class="text-end">{{ item["productLists.HSNCode"] }}</td>
                        <td class="text-end">{{ item.quantity }}</td>
                        <td>{{ item["productLists.unitType"] }}</td>
                        <td class="text-end">
                          {{ $formatToIndianCurrency(item.unitPrice) }}
                        </td>
                        <td class="text-end">{{ item.gst }}</td>
                        <td class="text-end">
                          {{ $formatToIndianCurrency(item.totalAmount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div class="footer-section">
              <pre v-if="DebitNote.reason && DebitNote.reason.trim() !== ''">{{
                DebitNote.reason
              }}</pre>

              <div class="d-flex justify-content-end">
                <div
                  class="mt-1"
                  style="width: 300px; max-width: auto; margin-left: 20px"
                >
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <strong>Amount:</strong>
                      <span>{{ $formatToIndianCurrency(DebitNote.amount) }}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                      <strong>CGST:</strong>
                      <span>{{ $formatToIndianCurrency(DebitNote.cgst) }}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                      <strong>SGST:</strong>
                      <span>{{ $formatToIndianCurrency(DebitNote.sgst) }}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                      <strong>Total Amount:</strong>
                      <span>{{ $formatToIndianCurrency(DebitNote.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table class="w-100">
              <caption class="d-none"></caption>
              <br />
              <tr class="d-none">
                <th class="text-start">Vendor Signature</th>
                <th class="text-end">Authority Signature</th>
              </tr>
              <tr>
                <td class="text-start">Vendor Signature</td>
                <td class="text-end">Authority Signature</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
  name: "DebitNoteDetailsModal",
  props: {
    SelectedDebitNoteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      CompanyLogo: null,
      lineItems: [],

      DebitNote: {},
      CompanyData: {},
      isDataFetched: false, // Flag to indicate if data fetching is complete
    };
  },
  watch: {
    SelectedDebitNoteId(newVal) {
      this.fetchCompanyData();

      this.fetchDebitNoteById(newVal);
    },
  },

  methods: {
    calculateTotalAmount() {
      if (!this.lineItems || this.lineItems.length === 0) return 0;
      return this.lineItems.reduce((sum, product) => {
        return sum + product.unitPrice * product.quantity - product.discount;
      }, 0);
    },

    calculateCgst() {
      if (!this.lineItems || this.lineItems.length === 0) return 0;
      return this.lineItems.reduce((sum, product) => {
        const taxableAmount = product.unitPrice * product.quantity - product.discount;
        const cgstAmount = (taxableAmount * (product.gst / 2)) / 100;
        return sum + cgstAmount;
      }, 0);
    },

    calculateSgst() {
      if (!this.lineItems || this.lineItems.length === 0) return 0;
      return this.lineItems.reduce((sum, product) => {
        const taxableAmount = product.unitPrice * product.quantity - product.discount;
        const sgstAmount = (taxableAmount * (product.gst / 2)) / 100;
        return sum + sgstAmount;
      }, 0);
    },

    calculateGrandTotal() {
      const totalAmount = this.calculateTotalAmount();
      const totalGst = this.calculateCgst() + this.calculateSgst();
      return totalAmount + totalGst;
    },

    fetchCompanyData() {
      axios
        .get(`${this.$apiEndPoint}/company/${this.$LocalUser.user.companyId}`)
        .then((response) => {
          this.CompanyData = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async fetchDebitNoteById(SelectedDebitNoteId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/debitNote/getById/${SelectedDebitNoteId}`
        );
        this.lineItems = response.data.lineitems;
        this.DebitNote = response.data;
        this.isDataFetched = true; // Set the flag to true after data is fetched
      } catch (error) {
        this.$handleError(error);
      }
    },
    downloadPDF() {
      if (!this.isDataFetched) {
        this.$handleError("Data not fetched yet. Please wait."); 
        return;
      }

      // Delay the download slightly to ensure all rendering is complete
      setTimeout(() => {
        const contentToPrint = document.querySelector(".DebitNoteDetailsModal");

        // Store the original styles before modification
        const originalWidth = contentToPrint.style.width;
        const originalMargin = contentToPrint.style.margin;
        const originalBorder = contentToPrint.style.border;
        const originalPadding = contentToPrint.style.padding;

        // Temporarily change the styles for PDF generation
        contentToPrint.style.width = "100%";
        contentToPrint.style.margin = "0%";
        contentToPrint.style.border = "none";
        contentToPrint.style.padding = "0%";

        const fileName = `DebitNote_${this.DebitNote.debitNoteNo}.pdf`;

        // Generate the PDF and restore the original styles afterward
        html2pdf(contentToPrint, {
          filename: fileName,
          margin: [10, 10, 10, 10], // top, left, bottom, right
          html2canvas: { scale: 2 },
          jsPDF: { format: "a4", orientation: "portrait" },
        }).then(() => {
          // Restore the original styles after the PDF is generated
          contentToPrint.style.width = originalWidth;
          contentToPrint.style.margin = originalMargin;
          contentToPrint.style.border = originalBorder;
          contentToPrint.style.padding = originalPadding;
        });
      }, 0);
    },
  },
};
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-fullscreen {
  margin-left: 0%;
  width: 100%;
}
.modal-body {
  width: 50%;
  margin: 0 25%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  border: 1px solid #000000;
}

.content-section {
  flex-grow: 1;
}

.table-bordered th {
  background-color: #ccc;
}

pre {
  display: block;
  margin: 10px;
  text-align: left;
  width: 80%;
  min-height: 100px;
  max-height: auto;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  word-break: break-all;
  white-space: pre-wrap;
}

#companylogoInput {
  min-height: 60px;
  max-height: 60px;
  width: auto;
  padding: 5px;
  border-radius: 5px;
  max-width: 250px;
}

.mt-auto {
  margin-top: auto;
}

.content-section {
  flex-grow: 1; /* This will allow the content section to take up remaining space */
}

.footer-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  margin-top: auto; /* Pushes the footer to the bottom */
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #ccc; /* Optional: to create separation from content */
}
.table-responsive th,
.table-responsive td {
  font-size: 10px;
}

@media screen and (max-width: 768px) {
  .modal-fullscreen {
    margin-left: 0%;
    width: 100%;
  }
  .modal-content {
    width: 100%;
  }
  .modal-dialog {
    width: 100%;
  }
  .modal-body {
    padding: 0px;
  }
}
</style>
