<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild" style="background-color: #f4f4f4">
        <div class="FinalEstimate border-black">
          <div class="row align-items-center FinalEstimateHeader m-2">
            <div class="col-8">
              <h5 class="p-1">Estimate</h5>
            </div>

            <div
              class="col-4 p-1 d-flex justify-content-end"
              v-if="fetchedEstimateData.status !== 'Rejected'"
            >
              <div class="text-nowrap" style="font-size: 12px" v-if="!isApproved">
                Unit Converter:
                <select
                  v-model="routeParams.widthUnitType"
                  @change="changeunitConverter(routeParams.widthUnitType)"
                  style="
                    border: 2px solid #000000;
                    border-radius: 5px;
                    background-color: transparent;
                    padding: 2px;
                    cursor: pointer;
                  "
                >
                  <option
                    v-for="(unit, index) in unitTypes"
                    :key="index"
                    :value="unit"
                    style="margin-bottom: 30px"
                  >
                    {{ unit }}
                  </option>
                </select>
              </div>

              <img
                v-if="isApproved"
                src="../../../assets/icons/new_releases.png"
                style="cursor: not-allowed"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Approved"
                alt=""
              />
              <img
                v-else
                src="../../../assets/icons/unApprove.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Approve"
                @click="toggleApprovalStatus"
              />
              <img
                v-if="isApproved || fetchedEstimateData.status === 'Sent'"
                src="../../../assets/icons/sentMailGreenIcon.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Sent"
                data-bs-toggle="modal"
                data-bs-target="#emailModal"
              />
              <img
                v-else
                src="../../../assets/icons/sentMail.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Send"
                data-bs-toggle="modal"
                data-bs-target="#emailModal"
              />
              <img
                src="../../../assets/icons/pinkDownold.png"
                alt=""
                v-if="fetchedEstimateData['awsFiles.awsFileName']"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Download"
                @click="
                  $downloadFile(
                    fetchedEstimateData['awsFiles.awsFileName'],
                    fetchedEstimateData['awsFiles.fileName'],
                    'estimate',
                    'sales'
                  )
                "
              />
              <!-- <img
                src="../../../assets/icons/pinkDownold.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Download"
                @click="generatePDF()"
              /> -->
              <img
                src="../../../assets/icons/cancel.png"
                alt=""
                @click="goBack"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Close"
              />
            </div>
            <div class="col-4 p-1 d-flex justify-content-end" v-else>
              <img
                v-if="isApproved"
                src="../../../assets/icons/new_releases.png"
                @click="toggleApprovalStatus"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Approve"
                alt=""
              />
              <img
                v-else
                src="../../../assets/icons/unApprove.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="UnApprove"
                @click="toggleApprovalStatus"
              />
              <img
                v-if="isApproved || fetchedEstimateData.status === 'Sent'"
                src="../../../assets/icons/sentMailGreenIcon.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Sent"
                data-bs-toggle="modal"
                data-bs-target="#emailModal"
              />
              <img
                v-else
                src="../../../assets/icons/sentMail.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Send"
                data-bs-toggle="modal"
                data-bs-target="#emailModal"
              />
              <img
                src="../../../assets/icons/pinkDownold.png"
                alt=""
                v-if="fetchedEstimateData['awsFiles.awsFileName']"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Download"
                @click="
                  $downloadFile(
                    fetchedEstimateData['awsFiles.awsFileName'],
                    fetchedEstimateData['awsFiles.fileName'],
                    'estimate',
                    'sales'
                  )
                "
              />
              <!-- <img
                src="../../../assets/icons/pinkDownold.png"
                alt=""
                data-toggle="tooltip"
                data-placement="bottom"
                title="Download"
                @click="generatePDF()"
              /> -->
              <img
                src="../../../assets/icons/cancel.png"
                alt=""
                @click="goBack"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Close"
              />
            </div>
          </div>

          <div
            class="EstimateContent mb-2 overflow-y-auto"
            v-if="fetchedEstimateData.uploadedEstimateStatus === 0"
          >
            <div
              class="row align-items-center align-content-md-start container-fluid headerFormOfEstimate"
            >
              <div class="col-md-4 col-12">
                <div class="glass-effect mb-1" v-if="companyLogo">
                  <img
                    :src="companyLogo.signedUrl"
                    alt="Company Logo"
                    id="companylogoInput"
                    class="img-fluid"
                  />

                  <div class="glass-overlay"></div>
                </div>

                <div class="col-md-12 d-flex EstimateLogoHeader" v-if="companyDetails">
                  <div>
                    <span style="font-weight: 600">{{ companyDetails.companyName }}</span>
                    <br />
                    <span>
                      <span>
                        {{ companyDetails.address }} {{ companyDetails.city }}
                        {{ companyDetails.state }}
                      </span>
                    </span>
                    <br />

                    <span>
                      {{ companyDetails.contactNumber }}
                    </span>
                    <br />
                    <span>
                      {{ companyDetails.companyEmail }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-12 order-md-first">
                <section class="EstimateHeader">
                  <div class="row">
                    <label for="clientName" class="col-2">Client Name</label>
                    <span class="col-1">:</span>
                    <p class="form-control-static col-8">{{ routeParams.clientName }}</p>
                  </div>
                  <div class="row">
                    <label for="contactNumber " class="col-2">Contact No</label>
                    <span class="col-1">:</span>
                    <p class="form-control-static col-9">{{ routeParams.contactNo }}</p>
                  </div>

                  <div class="row">
                    <label for="emailId" class="col-2 mb-1">Email ID</label>
                    <span class="col-1">:</span>
                    <p class="form-control-static col-9">{{ routeParams.email }}</p>
                  </div>
                  <div class="row">
                    <label for="propertyName" class="col-2 text-nowrap mb-1"
                      >Address</label
                    >
                    <span class="col-1">:</span>
                    <p class="form-control-static col-9">{{ routeParams.address }}</p>
                  </div>
                  <div class="row">
                    <label for="estimateNumber" class="col-2">Estimate No. </label>
                    <span class="col-1">:</span>
                    <p class="form-control-static col-9">
                      {{ routeParams.estimateId }}
                    </p>
                  </div>

                  <div class="row">
                    <label class="col-2" id="EstimateDate">Date </label>
                    <span class="col-1">:</span>
                    <p class="form-control-static col-9" id="EstimateDate">
                      {{ routeParams.date }}
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div class="EstimateBill">
              <table class="tableOfEstimate table">
                <caption class="d-none">
                  Table Of Estimate
                </caption>

                <thead class="">
                  <tr class="">
                    <th>SL.No</th>
                    <th>Description</th>
                    <th class="text-nowrap">
                      Width (
                      <span v-if="routeParams.widthUnitType" style="font-size: 8px">
                        {{ routeParams.widthUnitType }}
                      </span>
                      )
                    </th>

                    <th class="text-nowrap">
                      Height (
                      <span v-if="routeParams.widthUnitType" style="font-size: 8px">
                        {{ routeParams.widthUnitType }}
                      </span>
                      )
                    </th>
                    <th>Qty</th>
                    <th>Unit</th>
                    <th>Rate</th>
                    <th>Discount(%)</th>
                    <th>GST(%)</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <div v-for="(item, index) in items" :key="index" class="itemBlock">
                  <span class="ItemHeaderOfThings">
                    <input
                      type="text"
                      v-model="item.header"
                      placeholder="Header Name"
                      id="HeaderOfBillItem"
                      readOnly
                    />
                    <p class="m-0 p-0">
                      {{ $formatToIndianCurrency(calculateTotalAmountofItem(item)) }}
                    </p>
                  </span>
                  <tbody>
                    <tr v-for="(row, rowIndex) in item.rows" :key="rowIndex">
                      <td>
                        {{ rowIndex + 1 }}
                      </td>
                      <td>
                        <pre class="EstimateDescription">
                          {{ row.description }}
                        </pre>
                      </td>
                      <td>
                        <input
                          type="number"
                          inputmode="numeric"
                          v-model="row.width"
                          @input="row.qty = calculateQuantity(row)"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          inputmode="numeric"
                          v-model="row.height"
                          @input="row.qty = calculateQuantity(row)"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          @input="row.quantity = calculateQuantity(row)"
                          v-model="row.quantity"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="row.unitType"
                          class="text-center"
                          readOnly
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          inputmode="numeric"
                          v-model="row.rate"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          inputmode="numeric"
                          v-model="row.discount"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          inputmode="numeric"
                          v-model="row.GSTpercentage"
                          readOnly
                        />
                      </td>
                      <td class="text-end">
                        {{ $formatToIndianCurrency(row.amount) }}
                      </td>
                    </tr>
                  </tbody>
                </div>
              </table>
            </div>

            <div class="col-md-12 mt-2">
              <div
                class="container p-2 text-nowrap"
                style="border-radius: 5px; font-weight: bold; width: 99%; margin: 0.5%"
              >
                <div class="row">
                  <div class="col-6">
                    <p style="font-weight: bold; margin-bottom: 0px">
                      Total Amount
                      <span style="font-size: 14px">(Excluding GST)</span>
                    </p>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <p class="text-right" style="font-weight: bold; margin-bottom: 0px">
                      {{ $formatToIndianCurrency(fetchedEstimateData.total) }}
                    </p>
                  </div>
                </div>
                <div v-for="(detail, index) in GSTgrouping" :key="index">
                  <div class="row">
                    <div class="col-6 d-flex flex-column justify-content-end">
                      <span>CGST ({{ detail.gst / 2 }}%):</span>
                      <span>SGST ({{ detail.gst / 2 }}%):</span>
                      <!-- CGST amounts aligned at the start -->
                    </div>
                    <div
                      class="col-6"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        text-align: right;
                      "
                    >
                      <span class="text-right">{{
                        $formatToIndianCurrency((detail.amount / 2).toFixed(2))
                      }}</span>
                      <span class="text-right">{{
                        $formatToIndianCurrency((detail.amount / 2).toFixed(2))
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex">
                    <p style="font-weight: bolder; border-top: 2px solid #000">
                      Grand Total
                      <span style="font-size: 14px">(Including GST)</span>
                    </p>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <p
                      class="text-right"
                      style="font-weight: bolder; border-top: 2px solid #000"
                    >
                      {{ $formatToIndianCurrency(this.fetchedEstimateData.grandTotal) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-2" v-if="routeParams.customerNote">
              <div class="container">
                <h4
                  class="d-flex justify-content-center blackBgColor WhitetableHeaderHeading p-1"
                >
                  Customer Note
                </h4>
                <section class="p-1">
                  <pre
                    style="
                      min-height: 100px;
                      word-break: break-all;
                      white-space: pre-wrap;
                    "
                    >{{ routeParams.customerNote }}</pre
                  >
                </section>
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <div class="container text-nowrap">
                <h4
                  class="d-flex justify-content-center blackBgColor WhitetableHeaderHeading p-1"
                >
                  Bank Details
                </h4>
                <div class="row mt-1">
                  <div class="col-md-2 d-flex align-items-center justify-content-between">
                    <label for="accountName" class="font-weight-bold">
                      Account Name
                    </label>
                    <div class="col-md-1 d-flex align-items-center">:</div>
                  </div>

                  <div class="col-md-6 text-start">
                    <span
                      class="value"
                      v-if="fetchedEstimateData['bankDetails.accountName']"
                    >
                      <input
                        type="text"
                        name="accountName"
                        id=""
                        class="border-0"
                        v-model="fetchedEstimateData['bankDetails.accountName']"
                        readonly
                        style="font-weight: bold"
                      />
                    </span>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-2 d-flex align-items-center justify-content-between">
                    <label for="accountNumber" class="font-weight-bold">
                      Account Number
                    </label>
                    <div class="col-md-1 d-flex align-items-center">:</div>
                  </div>
                  <div class="col-md-6">
                    <span
                      class="value"
                      v-if="fetchedEstimateData['bankDetails.accountNumber']"
                    >
                      <input
                        type="text"
                        name="accountNumber"
                        id=""
                        class="border-0"
                        v-model="fetchedEstimateData['bankDetails.accountNumber']"
                        readonly
                        style="font-weight: bold"
                      />
                    </span>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-2 d-flex align-items-center justify-content-between">
                    <label for="bankName" class="font-weight-bold"> Bank Name </label>
                    <div class="col-md-1 d-flex align-items-center">:</div>
                  </div>
                  <div class="col-md-6">
                    <span
                      class="value"
                      v-if="fetchedEstimateData['bankDetails.bankName']"
                    >
                      <input
                        type="text"
                        name="bankName"
                        id=""
                        class="border-0"
                        v-model="fetchedEstimateData['bankDetails.bankName']"
                        readonly
                        style="font-weight: bold"
                      />
                    </span>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-2 d-flex align-items-center justify-content-between">
                    <label for="ifscCode" class="font-weight-bold"> IFSC Code </label>
                    <div class="col-md-1 d-flex align-items-center">:</div>
                  </div>
                  <div class="col-md-6">
                    <span class="value" v-if="fetchedEstimateData['bankDetails.IFSC']">
                      <input
                        type="text"
                        name="ifscCode"
                        id=""
                        class="border-0"
                        v-model="fetchedEstimateData['bankDetails.IFSC']"
                        readonly
                        style="font-weight: bold"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <div
                class="container"
                v-for="section in EstimateSectionData"
                :key="section.id"
              >
                <h4
                  class="d-flex justify-content-center blackBgColor WhitetableHeaderHeading p-1"
                >
                  {{ section.estimateSectionHeader }}
                </h4>
                <section class="p-1">
                  <pre style="word-break: break-all; white-space: pre-wrap">{{
                    section.estimateSectionContent
                  }}</pre>
                  <!--  <textarea class="form-control" rows="10" placeholder="Write here......"
                    v-model="fetchedEstimateData.termsAndConditions" readonly></textarea> -->
                </section>
              </div>
            </div>
            <div class="row mt-4 b m-2 order-black" style="font-weight: 600">
              <!-- <div class="col-2"></div> -->
              <div class="col-4">
                <h6 style="font-weight: 600">Thanking You,</h6>
              </div>
              <!-- <div class="col-3"></div> -->

              <h6 style="font-weight: 600" class="mt-1 mb-1">Regards,</h6>

              <span
                >{{ fetchedEstimateData["employees.firstName"] }}
                {{ fetchedEstimateData["employees.lastName"] }}</span
              >
              <!-- Display last name as 'empname' -->
              <span>{{ fetchedEstimateData["employees.employeeEmailId"] }}</span>
              <!-- Display email as 'username' -->
              <span>{{ fetchedEstimateData["employees.mobileNumber"] }}</span>
              <!-- Display contact number -->
            </div>
          </div>
          <div class="EstimateContent mb-2 overflow-y-auto" v-else>
            <!-- <img :src="EstimatePage" alt="" srcset="" /> -->
            <iframe
              :src="EstimatePage"
              width="100%"
              height="550px"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MailSentModal
    :estimateIndex="routeParams.estimateIndex"
    :leadEmail="routeParams.email"
    @fetchData="fetchEstimateData"
  />
</template>

<script>
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import MailSentModal from "@/components/Modal/MailSentModal.vue";
import axios from "axios";
// import html2pdf from 'html2pdf.js';

export default {
  name: "FinalEstimate",
  components: {
    Header,
    Nav,
    MailSentModal,
  },

  props: {
    leadIds: Array, // Define the expected type of passed data
  },

  data() {
    return {
      EstimatePage: null,
      CompanyLogo: null,
      showMailSentModal: false,
      GSTOptions: [5, 12, 18, 28],
      toggleWidththings: false,
      toggleHeightthings: false,
      estimateId: "",
      routeParams: {
        clientName: "",
        propertyName: "",
        contactNo: "",
        salesPerson: "",
        email: "",
        estimateId: this.$route.params.EstimateId || "",
        estimateIndex: this.$route.params.estimateIndex || "",
        date: "",
        address: "",
        customerNote: "",
        status: "",
        widthUnitType: "",
        heightUnitType: "",
      },
      items: [],
      bankDetails: {},
      fetchedEstimateData: {},
      EstimateSectionData: [],
      GSTgrouping: [],
      companyDetails: {},
      unitTypes: ["mm", "cm", "meter", "inch", "feet"],
    };
  },
  mounted() {
    this.routeParams.estimateId = this.$route.params.EstimateId;
    this.routeParams.estimateIndex = this.$route.params.estimateIndex;

    if (this.routeParams.estimateId || this.routeParams.estimateIndex) {
      this.fetchEstimateData();
    }
  },
  computed: {
    isApproved() {
      if (this.fetchedEstimateData && this.fetchedEstimateData.status) {
        return this.fetchedEstimateData.status === "Approved";
      }
      this.fetchEstimateData();
      // If no approval status is found, default to false
      return false;
    },
  },

  methods: {
    featchEstimateImage() {
      const PayLoad = {
        awsFileName: this.fetchedEstimateData["awsFiles.awsFileName"],
        fileCategory: "estimate",
        moduleName: "sales",
        fileName: this.fetchedEstimateData["awsFiles.fileName"],
      };

      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.EstimatePage = Object.values(response.data)[0];
        
        })
        .catch(() => {});
    },
    async changeunitConverter(unitType) {

      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/estimates/convertUnitType/${this.routeParams.estimateIndex}`,
          {
            unitType: unitType,
          }
        );

        if (response.data) {
    this.fetchEstimateData();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    openMailSentModal() {
      this.showMailSentModal = true;
    },

    // Method to close the mail sent modal
    closeMailSentModal() {
      this.showMailSentModal = false;
    },
    printEstimateDetailsWithoutHeader() {
      window.print();
    },

    generatePDF() {
      this.$router.push({
        name: "PrintEstimate",
        params: { estimateIndex: this.$route.params.estimateIndex },
      });
    },

    async toggleApprovalStatus() {
      try {
        if (this.isApproved) {
          await axios.put(
            `${this.$apiEndPoint}/estimates/${this.routeParams.estimateIndex}`,
            {
              status: "Sent",
            }
          );
        } else {
          // Show a confirmation dialog before approving
          const isConfirmed = confirm("Are you sure you want to approve?");

          if (isConfirmed) {
            this.fetchedEstimateData.status = "Approved";
            // If the user confirms, make the API call
            await axios.put(
              `${this.$apiEndPoint}/estimates/${this.routeParams.estimateIndex}`,
              {
                status: "Approved",
              }
            );
          } else {
            // If the user cancels, exit the function without making an API call
            return;
          }
        }
        // Fetch updated estimate data
        await this.fetchEstimateData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },

    async updateEstimateStatus(status) {
      try {
        // Make API call to update estimate status
        await axios.put(
          `${this.$apiEndPoint}/estimates/${this.routeParams.estimateIndex}`,
          {
            status: status,
          }
        );
    
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchEstimateData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/${this.routeParams.estimateIndex}`
        );
        this.fetchedEstimateData = response.data;
   
        this.featchEstimateImage();
        this.GSTgrouping = response.data.GSTgrouping;
        this.companyDetails = response.data.companyDetails;
        this.companyLogo = response.data.companyLogo;
        this.EstimateSectionData = this.fetchedEstimateData.estimateSectionData;

        this.routeParams.widthUnitType = this.fetchedEstimateData.unitType;
        this.routeParams.estimateId = response.data.estimateId;
        this.routeParams.customerNote = this.fetchedEstimateData.customerNote;

        this.routeParams.propertyName = this.fetchedEstimateData["property.name"];
        this.routeParams.propertyId = this.fetchedEstimateData["property.id"];

        this.routeParams.salesPerson = this.fetchedEstimateData.salesPersonId || "";

        this.routeParams.date = this.fetchedEstimateData.date || "";

        if (this.fetchedEstimateData["customers.name"]) {
          this.routeParams.clientName = this.fetchedEstimateData["customers.name"];
          this.routeParams.contactNo =
            this.fetchedEstimateData["customers.contactNo"] || "";
          this.routeParams.email = this.fetchedEstimateData["customers.email"];
        } else {
          this.routeParams.clientName = this.fetchedEstimateData["leads.name"];
          this.routeParams.contactNo = this.fetchedEstimateData["leads.contactNo"] || "";
          this.routeParams.email = this.fetchedEstimateData["leads.email"];
        }
        if (this.fetchedEstimateData["addresses.address"]) {
          this.routeParams.address = `${
            this.fetchedEstimateData["addresses.address"] ?? ""
          } ${this.fetchedEstimateData["addresses.city"] ?? ""} ${
            this.fetchedEstimateData["addresses.state"] ?? ""
          }`;
        } else {
          this.routeParams.address = `${
            this.fetchedEstimateData["leadAddresses.address"] ?? ""
          } ${this.fetchedEstimateData["leadAddresses.city"] ?? ""} ${
            this.fetchedEstimateData["leadAddresses.state"] ?? ""
          }`;
        }
        this.routeParams.leadId = this.fetchedEstimateData.leadId;
        if (this.fetchedEstimateData?.lineItems) {
          this.items = this.fetchedEstimateData.lineItems.map((item) => ({
            header: item.header,
            rows: item.Rows.map((row) => ({
              ...row,
              GSTpercentage: row.GSTpercentage, // Assign GSTpercentage directly from response
            })),
          }));
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    calculateTotalAmountofItem(item) {
      let totalAmount = 0;
      item.rows.forEach((row) => {
        totalAmount += parseFloat(row.amount || 0);
      });
      return totalAmount;
      /*    return totalAmount.toFixed(2); */
    },
  },
};
</script>

<style scoped>
pre {
  font-size: 14px;
  color: #000000;
  font-family: "Inter", sans-serif;
  word-break: break-all;
  white-space: wrap;
  text-align: left;
}
.EstimateDescription {
  font-size: 12px;
  color: #000000;
  font-family: "Inter", sans-serif;
  word-break: break-all;
  white-space: wrap;
}
.FinalEstimateHeader label,
.FinalEstimateHeader p {
  font-size: 14px;
  font-weight: bold;
}

.active {
  text-decoration: underline;
}

.FinalEstimateHeader {
  padding: 0 5%;
  border-radius: 5px;
  background-color: #bcbfc6;
  position: sticky;
  top: 0%;
  z-index: 999;
  width: 98%;
}

.FinalEstimateHeader img {
  width: 5%;
  margin: 0 2%;
  /* Adjust margin as needed */
  aspect-ratio: 1/1;
  /* Maintain aspect ratio */
  object-fit: contain;
  cursor: pointer;
}

#companylogoInput {
  height: 80px;
  width: fit-content;
  border: 1px solid #ccc;

  border-radius: 5px;
  outline: none;
  max-width: 300px;
}
.EstimateContent {
  margin: 0 10%;
  background-color: #ffffff;
  padding: 2%;
  font-size: 14px;
}

.EstimateBill::-webkit-scrollbar {
  display: none;
}

.EstimateBill {
  max-width: 99%;
  font-size: 14px;
}
.EstimateHeader div p {
  padding: 0px 2px !important;
  margin-bottom: 6px;
}

.tableOfEstimate thead tr th {
  color: #000000;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f8f8;
  text-align: center;
}

.tableOfEstimate tbody {
  max-width: 98%;
}

.tableOfEstimate tbody::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate tbody tr,
.tableOfEstimate thead tr {
  display: flex;
}

.tableOfEstimate thead th {
  min-width: 9.25%;
  max-width: 9.4%;
  text-align: center;
}
.tableOfEstimate tbody tr td {
  min-width: 9.4%;
  max-width: 9.4%;
  text-align: center;
}

.tableOfEstimate thead th:nth-child(1),
.tableOfEstimate tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
}

.tableOfEstimate tbody tr td input[type="number"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.tableOfEstimate tbody tr td input[type="text"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
}

.tableOfEstimate tbody td:nth-child(2),
.tableOfEstimate thead th:nth-child(2) {
  min-width: 20.4%;
  max-width: 20.4%;
  text-align: left;
  justify-content: left;
}

.itemBlock {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.ItemHeaderOfThings {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: #ccc;
  height: 30px;
  width: 100%;
  padding: 0 5px;
  font-size: 16px;
  font-weight: bolder;
}

.ItemHeaderOfThings p {
  position: relative;
  margin-top: 5px;
  height: 20px;
  color: #000000;
  font-size: 16px;
  background-color: #ccc;
  font-weight: bolder;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.itemBlock span button {
  background: transparent;
  display: flex;
  padding: 0.5%;
  border-radius: 10px;
  color: #000000;
  outline: none;
  border: none;
}

#itemRowEstimate {
  border: 1px solid #edeae9;
}

.itemBlock #HeaderOfBillItem {
  background-color: transparent;

  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bolder;
  text-align: left;
}

#itemAdding {
  display: flex;
  background-color: #084f5b;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid #ffaa23;
}

#itemrowAdding {
  display: flex;
  color: #000000;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}

.toggleWidththings,
.toggleHeightthings {
  position: fixed;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  letter-spacing: 2px;
}

.toggleWidththings p,
.toggleHeightthings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
  font-weight: 100;
}

/* Responsive table styles */
.tableOfEstimate {
  width: 100%;
  border-collapse: collapse;
}

.subHeaderHeadingBgColor {
  background-color: #f0f0f0;
}

.ItemHeaderOfThings input {
  flex: 1;
  text-align: center;
}

.dropdownToggle {
  cursor: pointer;
}

.EstimateBill {
  margin-top: 20px;
}

button {
  margin-top: 10px;
}

.container {
  font-size: 14px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.ellipsis {
  max-width: 200px; /* Set your desired width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.tooltip {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the text */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap; /* Prevent tooltip text from wrapping */
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tableOfEstimate td:nth-child(2) pre {
  text-align: left !important;
  justify-content: left;
}
.tableOfEstimate tbody tr {
  padding: 0px;
  border-bottom: 1px solid #000000 !important;
}
/* Styles for responsiveness */
@media (max-width: 768px) {
  .FinalEstimateHeader img {
    width: 20%;
    /* To make images responsive */
    margin: 0 5%;
    /* Adjust margin as needed */
    aspect-ratio: 1/1;
    /* Maintain aspect ratio */
    object-fit: contain;
  }

  .tableOfEstimate tbody td,
  .tableOfEstimate thead th {
    white-space: nowrap;
    min-width: 120px;
    position: relative;
  }

  .tableOfEstimate tbody tr,
  .tableOfEstimate thead tr {
    display: flex;
    overflow: scroll;
  }

  .footerOfEstimate {
    margin-bottom: 50px;
  }
}

/* pdf content css file ends */
</style>

<!-- <style media="print">
/* pdf content css file starts */
.pdf {
  overflow-y: scroll;
  width: 21cm;
  height: 29.7cm;
  margin: 0 auto; /* Center horizontally */
  padding: 20px;
  background-color: #ffffff;
  font-size: 13px;

}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
}

/* Flex container for header sections */
.headerName {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  background-color: #ccc;
  height: 30px;
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  margin-left: 45%;
}

.PrintItemHeaderOfThings {

  background-color: #ccc;
  width: 100%;
  height: 30px;
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  margin-left: 200% !important;
}
/* Styling header text */
.PrintItemHeaderOfThings td:last-child{
text-align: right;
margin-left: 220% !important;
display: flex;
justify-content: right;
}

tbody  tr:nth-child(2) td:first{
 width:10px;
  }
  
/* Header of bill item */
.HeaderOfBillItem {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: 10px auto;
}

/* Styling the customer note and bank details */
.pre {
  white-space: pre-wrap; /* Preserve line breaks */
  font-family: inherit;
}

/* Thanking you and regards section */
.footer {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

.sign {
  width: 30%;
  border: 0px;
}

/* End of PDF content CSS */


.info {
  width: 70%;
  border: 0px;
  padding: 10px;
}

.amount {
  width: 30%;
  border: 0px;
  text-align: end;
}

.glass-effect {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5)
}


.PrintItemHeaderOfThings {
  display: flex;
  white-space: nowrap;
  background-color: #ccc;
  height: 30px;
  width: 99%;
  margin: .5%;
  font-size: 16px;
  font-weight: bolder;
}



.HeaderOfBillItem {
  background-color: transparent;
  width: 100%;
  margin-left: 45%;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
}

.headerName {
  display: flex;
  white-space: nowrap;
  background-color: #000000;
  height: 30px;
  width: 99%;
  margin: .5%;
  font-size: 16px;
  font-weight: bolder;
}



.HeaderAmount {
  background-color: transparent;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bolder;
  text-align: right;
  display: flex;
  justify-content: right;
}

.data {
  border: none;
}

</style> -->
