<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="CreateScopeOfWorkModal"
      tabindex="-1"
      aria-labelledby="CreateScopeOfWorkModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="CreateScopeOfWorkModalLabel">
              Add Activity
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm"> <!-- Prevent form default behavior -->
              <div class="mb-3 row">
                <label for="header" class="col-sm-3 col-form-label">Header</label>
                <div class="col-sm-9">
                  <input
                    v-model="form.header"
                    type="text"
                    class="form-control"
                    id="header"
                    placeholder="Enter header"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="activity" class="col-sm-3 col-form-label">Activity</label>
                <div class="col-sm-9">
                  <input
                    v-model="form.activity"
                    type="text"
                    class="form-control"
                    id="activity"
                    placeholder="Enter activity name"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="plannedStartDate" class="col-sm-3 col-form-label"
                  >Planned Start Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.plannedStartDate"
                    type="date"
                    class="form-control"
                    id="plannedStartDate"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="plannedEndDate" class="col-sm-3 col-form-label"
                  >Planned End Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.plannedEndDate"
                    type="date"
                    class="form-control"
                    id="plannedEndDate"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="plannedDuration" class="col-sm-3 col-form-label"
                  >Duration (days)</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.plannedDuration"
                    type="number"
                    class="form-control"
                    id="plannedDuration"
                    placeholder="Enter plannedDuration"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="assignee" class="col-sm-3 col-form-label">Assignee</label>
                <div class="col-sm-9">
                  <select
                    v-model="form.assignTo"
                    id="assignTo"
                    class="form-control form-select"
                  >
                    <option value="" disabled>Select assignee</option>
                    <option
                      v-for="employee in employees"
                      :key="employee.employeeId"
                      :value="employee.employeeId"
                    >
                      {{ employee["employees.firstName"] }}
                      {{ employee["employees.lastName"] }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="modal-footer d-flex justify-content-center">
                <button
                  v-if="!form.id"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="UpdateActivity"
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="DeleteTimeLine"
                  v-if="form.id"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateScopeOfWorkModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
    onSiteProjectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        parentId: null,
        projectId: null,
        activity: "",
        plannedStartDate: null,
        plannedEndDate:null,
        plannedDuration: null,
        assignTo:null,
        header: "",
      },
      employees: [],
    };
  },

  mounted() {
    this.fetchEmployees();
  },
  watch: {
    projectId(newVal) {
      console.log(newVal);
      this.fetchEmployees();
    },
  },

  methods: {
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/onSiteSupervisors/${this.onSiteProjectId}`)
        .then((response) => {
          this.employees = response.data.filter((item) => item !== null);
          this.mapAssignedEmployee();
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    submitForm() {
      this.form.projectId = this.projectId;
    

      axios
        .post(`${this.$apiEndPoint}/onSiteScopeOfWork/`, {
          header: this.form.header,
          Rows: [
            {
              parentId: this.form.parentId,
              projectId: this.form.projectId,
              activity: this.form.activity,
              plannedStartDate: this.form.plannedStartDate,
              plannedEndDate: this.form.plannedEndDate,
              plannedDuration: this.form.plannedDuration,
              assignTo: this.form.assignTo,
            },
          ],
        })
        .then(() => {
          this.$toast("Scope of Work created successfully.", "success");
          this.$emit("fetchscopeOfWork");
          this.resetForm(); // Reset the form after submission
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    UpdateActivity() {
      // Your update logic here
      // After updating, reset the form
      this.resetForm();
    },

    resetForm() {
      this.form = {
        parentId: null,
        projectId: null,
        activity: "",
        plannedStartDate:null,
        plannedEndDate: null,
        plannedDuration: null,
        assignTo: null,
        header: "",
      };
    },

    mapAssignedEmployee() {
      if (this.form.assignTo) {
        const assignedEmployee = this.employees.find(
          (emp) => emp.employeeId === this.form.assignTo
        );
        if (assignedEmployee) {
          this.form.assignTo = assignedEmployee.employeeId;
        }
      }
    },
  },
};
</script>


<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
