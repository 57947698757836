<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="FormatTable" v-if="showUnclaimedList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <div class="col-md-3 text-nowrap active-SubHeading">
                <h5>Projects in {{ stageName }}</h5>
              </div>
              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row">
                  <div class="col-md-4 tableTextEvenRow">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1"></div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchLeads"
                  />
                </div>
                <span>
                  <img
                    src="../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <table class="tableFormat">
            <caption class="d-none">
              Table By Design stage wise
            </caption>
            <thead>
              <tr>
                <th class="tableHeaderHeading">Project Name</th>
                <th class="tableHeaderHeading">Start Date</th>
                <th class="tableHeaderHeading">Customer Name</th>
                <th class="tableHeaderHeading">Designer</th>
                <th class="tableHeaderHeading">Property Address</th>
                <th class="tableHeaderHeading" v-if="stageName === 'Sent For Execution'">
                  Documents
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index">
                <td @click="navigateToProjectProfile(data)">{{ data.projectName }}</td>
                <td>{{ data.designStartDate }}</td>
                <td>{{ data["customers.name"] }}</td>
                <td>{{ data["employees.firstName"] }}{{ data["employees.lastName"] }}</td>
                <td>{{ data["addresses.address"] }}</td>
                <td
                  class="tableHeaderHeading d-flex align-content-center"
                  v-if="stageName === 'Sent For Execution'"
                >
                  <img
                    v-if="data.signOffDocuments.length > 0"
                    class="ms-3"
                    src="../../assets/icons/threeDot.jpg"
                    alt=""
                    style="width: 17px; height: 17px"
                    id="dropdownSignOffDocumentButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownSignOffDocumentButton"
                  >
                    <ol
                      v-for="(folder, index) in data.signOffDocuments"
                      :key="index"
                      style="padding: 0px 10px"
                    >
                      <li
                        class="d-flex justify-content-between align-items-center"
                        style="font-size: 14px"
                      >
                        <span class="text-start mt-1">
                          {{ folder["files.awsFiles.fileName"] }}
                        </span>
                        <span class="text-end ms-3">
                          <img
                            src="../../assets/icons/pinkDownold.png"
                            alt=""
                            style="width: 17px; height: 17px"
                            @click="
                              $downloadFile(
                                folder['files.awsFiles.awsFileName'],
                                folder['files.awsFiles.fileName'],
                                'signOffDocument',
                                'design'
                              )
                            "
                          />

                          <img
                            @click="
                              $viewFile(
                                folder['files.awsFiles.awsFileName'],
                                folder['files.awsFiles.fileName'],
                                'signOffDocument',
                                'design'
                              )
                            "
                            class="ms-1"
                            src="../../assets/icons/view.png"
                            alt=""
                            style="width: 17px; height: 17px"
                          />
                        </span>
                      </li>
                    </ol>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../assets/icons/blueLeftarrow.png"
              @click="changePage(-1)"
              :disabled="currentPage === 1"
              alt="Prev"
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in pagination"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../assets/icons/blueRightarrow.png"
              @click="changePage(1)"
              :disabled="currentPage === pagination.length"
              alt="Next"
            />
          </div>

          <div class="entries-info">
            <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/globalTable.css";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
export default {
  name: "DesignProjectTable",
  components: {
    Header,
    Nav,
  },

  data() {
    return {
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedLeadId: null,
      stageName: "",
      designStage: "",
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.TableData.length / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.ArchiveData.length / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.TableData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.ArchiveData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      // Use search results for displayed data
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.TableData.slice(startIndex, endIndex);
    },
    displayedArchiveData() {
      // Use search results for displayed archive data
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.ArchiveData.slice(startIndex, endIndex);
    },
    // pagination() {
    //   const pageCount = Math.ceil(this.TableData.length / this.pageSize);
    //   return Array.from({ length: pageCount }, (_, index) => index + 1);
    // },
  },
  mounted() {
    this.stageName = this.$route.params.stageName;
    this.selectedEmployeeId = this.$route.params.selectedEmployeeId;
    this.fetchData();
    this.fetchArchiveData();
  },
  methods: {
    navigateToProjectProfile(data) {
      // Navigate to ProjectProfile page with project ID as parameter
      this.$router.push({
        name: "DesignProjectProfile",
        params: {
          Id: data.id,
          projectName: data.projectName,
        },
      });
    },

    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },
    openModal(leadId) {
      this.selectedLeadId = leadId;
  
    },
    handleArchiveClick() {
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },
    async fetchData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/designStage/${this.stageName}/${this.selectedEmployeeId}`
        );
        this.TableData = response.data;
        this.designStage = this.TableData[0].designStage;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/designStage/${this.stageName}/${this.selectedEmployeeId}/archived`
        );
        this.ArchiveData = response.data;
        this.designStage = this.ArchiveData[0].designStage;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },

    // Method to delete the selected lead
    DeleteLead(data) {
      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveLead(data) {
      axios
        .put(`${this.$apiEndPoint}/customer/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
       
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(
          `${this.$apiEndPoint}/designProjects/searchStage/${this.designStage}/archived`,
          searchData
        )

        .then((response) => {
          this.ArchiveData = response.data; // Ensure it's an array or provide default
          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.ArchiveData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    //for Serch api for Unclaimleads
    searchLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(
          `${this.$apiEndPoint}/designProjects/searchStage/${this.designStage}/${this.selectedEmployeeId}`,
          searchData
        )

        .then((response) => {
          this.TableData = response.data; // Ensure it's an array or provide default

          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.TableData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //for all selected lead Unclaim and delete api

    UnclaimLeads() {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      const payload = {
        id: this.selectedCustomers,
        // Assuming salesPersonId needs to be provided
      };

      axios
        .put(`${this.$apiEndPoint}/leads/unClaimLead`, payload)
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false; // Close the dropdown after action
          this.selectedCustomers = []; // Clear selected customers
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    ArchiveLeads() {
      if (this.selectedCustomers.length === 0) {
        return;
      }

      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/customers/archive`, payload)
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    deleteLead() {
      if (this.selectedCustomers.length === 0) {
        return;
      }

      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, { data: payload })
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    changePage(delta) {
      this.currentPage += delta;
    },
    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    createEstimate(selectedLead) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedLead.name,
          PropertyId: selectedLead.propertyId,
          ContactNo: selectedLead.contactNo,
          Email: selectedLead.email,
          leadId: selectedLead.id,
          address: selectedLead.address,
          propertyName: selectedLead["property.name"],
        },
      });
    },

    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    changeStatus(status) {
      this.selectedStatus = status;
      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },
  },
};
</script>
