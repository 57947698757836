<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="p-1 mainHeading" style="border-bottom: 2px solid #ccc">
          <p class="ms-3 mt-1">Documents</p>
        </div>
        <div class="container">
          <div class="row">
            <!-- Right Side - Upload Section -->
            <div class="col-md-5">
              <div class="div mt-5">
                <div class="row col-md-12 align-content-center">
                  <div class="col-md-5">
                    <p style="font-size: 15px">Select Folder Type:</p>
                  </div>
                  <div class="col-md-7">
                    <select v-model="selectedFolderIndex" class="form-select">
                      <option
                        v-for="(folder, index) in folders"
                        :value="index"
                        :key="index"
                      >
                        {{ folder.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="logoContainer">
                  <div
                    class="dropArea"
                    id="dropArea"
                    :class="{ withImage: imageDataUrl }"
                  >
                    <img src="../../assets/icons/cloud.png" alt="" v-if="!imageDataUrl" />
                    <p v-if="!imageDataUrl">Drag and Drop your File here</p>
                    <p v-else>
                      <img :src="imageDataUrl" alt="Selected File" />
                    </p>
                    <p>OR</p>
                    <p class="mb-0">
                      <input
                        type="file"
                        id="fileInput"
                        @change="handleFileUpload"
                        class="form-control-file"
                        accept="image/*"
                      />
                      <label for="fileInput" class="btn btn-primary"> Browse </label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Left Side - Folders Section -->
            <div class="col-md-5 p-3">
              <div class="d-flex align-content-center justify-content-between mb-2">
                <h4 class="SubheadingUnderLine" style="font-size: 18px">Folders</h4>
              </div>
              <div class="list-group">
                <a
                  href="#"
                  style="text-decoration: none"
                  v-for="(folder, index) in folders"
                  :key="index"
                >
                  <div class="d-flex align-content-center justify-content-between">
                    <span class="d-flex align-content-center">
                      <img
                        :src="
                          folder.isExpanded
                            ? '../../assets/icons/blueDownarrow.png'
                            : '../../assets/icons/blueRightarrow-1.png'
                        "
                        alt=""
                        style="
                          width: 15px;
                          height: 10px;
                          margin-top: 5px;
                          margin-right: 10px;
                        "
                        @click="toggleFiles(index)"
                      />
                      <h6 @click="selectFolder(index)" class="subHeadingHeader">
                        {{ folder.name }}
                      </h6>
                    </span>
                  </div>
                  <ol v-if="folder.isExpanded">
                    <li v-for="file in folder.files" :key="file">
                      <span class="d-flex align-content-center justify-content-between">
                        <div class="file-link">
                          <a :href="getDownloadUrl(file)" target="_blank">{{
                            getDownloadFilename(file, folder.name)
                          }}</a>
                        </div>

                        <img
                          src="../../assets/icons/delete.png"
                          alt=""
                          style="
                            width: 15px;
                            height: 15px;
                            margin-top: 5px;
                            margin-right: 10px;
                          "
                          @click="deleteFile(folder, file)"
                        />
                      </span>
                    </li>
                  </ol>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  name: "SalesDocument",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      folders: [
        { name: "bookingType", files: [], isExpanded: true },
        { name: "serviceType", files: [], isExpanded: true },
      ],
      bookingFileNames: null, // Array to store bookingType file names
      serviceFileNames: null, // Array to store serviceType file names
      selectedFolderIndex: null,
      imageDataUrl: null,
      companyId: null,
    };
  },
  mounted() {
    this.CompanyId = this.$LocalUser.user.companyId;
    if (this.companyId) {
      // Create an array of promises
      const promises = [this.fetchFiles("bookingType"), this.fetchFiles("serviceType")];

      // Use Promise.all() to wait for all promises to resolve
      Promise.all(promises)
        .then(() => {
    
        })
        .catch((error) => {
          this.$handleError(error);
        });
    }
  },
  methods: {
    fetchFiles(folderName) {
      const payload = {
        entityId: this.companyId,
        fileCategory: folderName,
        moduleName: "sales",
      };

      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, payload)
        .then((response) => {
          const folder = this.folders.find((f) => f.name === folderName);
          if (folder) {
            folder.files = response.data;
            // Extract filenames for the current folder
            folder.fileNames = Object.keys(folder.files);
  

            if (folderName === "bookingType") {
              this.bookingFileNames = folder.fileNames[0]; // Store bookingType file names
            } else if (folderName === "serviceType") {
              this.serviceFileNames = folder.fileNames[0]; // Store serviceType file names
            }
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    getDownloadFilename(fileName, folderName) {


      if (folderName === "bookingType") {
        return this.bookingFileNames;
      } else if (folderName === "serviceType") {
        return this.serviceFileNames;
      }
      return ""; // Default case (shouldn't happen if used correctly)
    },

    getDownloadUrl(file) {

      return `${file}`;
    },
    toggleFiles(index) {
      if (this.folders[index]) {
        this.folders[index].isExpanded = !this.folders[index].isExpanded;
      }
    },
    selectFolder(index) {
      this.selectedFolderIndex = index;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const folderName = this.folders[this.selectedFolderIndex]?.name;

      if (file && folderName) {
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          fileCategory: folderName,
          moduleName: "sales",
          entityId: this.companyId,
          entityName: folderName,
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.fetchFiles(folderName); // Refresh files after upload
                } else {
                  this.$handleError(xhr.responseText); 
                }
              }
            };

            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    deleteFile(folder, fileName) {
      // Implement file deletion logic here
      console.log(`Deleting file "${fileName}" from folder "${folder.name}"`); 
    },
  },
};
</script>

<style scoped>
.file-link {
  margin-top: 10px;
}

.file-link a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f8f8f8; /* Blue color, change as needed */
  color: #000000; /* White text color */
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.file-link a:hover {
  background-color: #ccc; /* Darker blue on hover */
  border-color: #ccc;
  color: #ffffff; /* White text color on hover */
}
.logoContainer {
  border: 2px dotted #333;
  padding: 10%;
  text-align: center;
  margin: 5%;
  max-width: 86%;
  max-height: 86%;
  min-width: 86%;
  min-height: 86%;
  border-radius: 15px;
}

.dropArea {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dropArea p {
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

#fileInput {
  display: none;
  margin-top: 5%;
}

label[for="fileInput"] {
  cursor: pointer;
  padding: 2% 20%;
  border-radius: 5px;
  margin-top: 2%;
}

.withImage {
  padding: 5%; /* Adjust the padding when an image is present */
  max-width: 100%; /* Ensure the image fits within the container */
  max-height: 100px; /* Limit the height of the image */
}

.withImage img {
  max-width: 100%; /* Ensure the image fits within the container */
  max-height: 100px; /* Limit the height of the image */
}
</style>
