<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request">
          <div class="row header mb-0">
            <div class="d-flex justify-content-between">
              <h5 class="ms-3 text-decoration-underline">Purchase Request</h5>
              <div class="close">
                <img
                  src="../../../assets/icons/cancel.png"
                  alt="Close"
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                />
              </div>
            </div>
          </div>

          <div class="row headerOfthePR">
            <div class="col-md-7 mb-3 d-flex align-items-center">
              <label for="projectName" class="col-md-4 col-sm-4 col-form-label"
                >Project Name:</label
              >
              <div class="col-md-6 col-sm-6">
                <select
                  class="form-control form-select border-black"
                  id="category"
                  v-model="form.projectId"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.projectId || Project.id"
                    :value="Project.projectId || Project.id"
                  >
                    {{ Project.projectName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4 mb-3 d-flex align-items-center">
              <label for="date" class="col-md-4 col-sm-4 col-form-label">Date:</label>
              <div class="col-md-8 col-sm-8">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control border-black w-100"
                  id="date"
                />
              </div>
            </div>
          </div>

          <div class="table-container small-wrapper">
            <table class="small-table table table-bordered text-center">
              <thead>
                <tr class="table-header-heading">
                  <th>S/N</th>
                  <th class="text-start">Product Name</th>
                  <th class="text-start">Description</th>
                  <th>Unit Type</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                  <td>
                    <div class="cell-content">
                      <span class="delete-icon" @click="removeRow(index)">
                        <img src="../../../assets/icons/redDelete.png" alt="Delete" />
                      </span>
                      <span class="row-index">{{ index + 1 }}</span>
                    </div>
                  </td>
                  <td>
                    <span class="d-flex justify-content-between align-items-center">
                      <img
                        @click="openImageModal(row, index)"
                        v-if="row.awsFileId"
                        :src="
                          row.signedUrl
                            ? row.signedUrl
                            : '../../../assets/icons/profile.png'
                        "
                        class="m-3 uploadedimage"
                        alt=""
                      />
                      <img
                        v-if="!row.awsFileId"
                        src="../../../assets/icons/imageUpload.png"
                        @click="triggerFileInput(index)"
                        alt="Add Image"
                        class="AddImage"
                      />
                      <input
                        type="file"
                        ref="fileInputs"
                        @change="handleFileSelect($event, index)"
                        style="display: none"
                        accept="image/*"
                      />
                      <input
                        ref="productNameInputs"
                        class="w-100"
                        v-model="row.productName"
                        @focus="activeRow = index"
                        @input="searchProduct(row.productName, index)"
                        @change="fillProductDetails(index)"
                      />
                    </span>
                    <ul
                      v-if="row.filteredProducts.length > 0 && activeRow === index"
                      class="dropdown-menu show productDropDown"
                    >
                      <li
                        v-for="product in row.filteredProducts"
                        :key="product.id"
                        @click="selectProduct(product, index)"
                      >
                        {{ product.productName }}
                      </li>
                    </ul>
                  </td>
                  <td class="description">
                    <input
                      type="text"
                      v-model="row.description"
                      class="form-control text-start align-items-center"
                    />
                  </td>

                  <td>
                    <input
                      class="form-control border-black"
                      v-model="row.unitType"
                      @focus="activeRow = index"
                      @input="filterUnitTypes(row.unitType, index)"
                    />
                    <!-- Show dropdown only for the current active row -->
                    <ul
                      v-if="row.filteredUnitTypes.length > 0 && activeRow === index"
                      class="dropdown-menu unitDropDown show"
                    >
                      <li
                        v-for="unit in row.filteredUnitTypes"
                        :key="unit.value"
                        @click="selectUnitType(unit, index)"
                      >
                        {{ unit.label }}
                      </li>
                    </ul>
                  </td>
                  <td class="d-flex justify-content-between align-items-center">
                    <input
                      type="text"
                      v-model="row.quantity"
                      class="form-control quantity-input"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="add-item btn btn-sm btn-primary" @click="addNewTask">
              Add Item +
            </button>
          </div>

          <div class="row">
            <div class="footerPuchaseRequest">
              <button
                class="btn btn-sm btn-primary"
                :disabled="!form.projectId"
                @click="saveAsDraft('Draft')"
              >
                Save as Draft
              </button>
              <!-- <button v-if="this.$route.params.moduleName === 'Onsite'" class="btn btn-sm btn-primary"
                :disabled="!form.projectId" @click="saveAsDraft('Pending')">
                Save & Submit
              </button> -->
              <button
                v-if="['Onsite', 'Inventory'].includes(this.$route.params.moduleName)"
                class="btn btn-sm btn-primary"
                :disabled="!form.projectId"
                @click="saveAsDraft('Pending')"
              >
                {{
                  this.$route.params.moduleName === "Onsite"
                    ? "Save & Submit"
                    : "Save Inventory"
                }}
              </button>

              <button
                v-else
                class="btn btn-sm btn-primary"
                :disabled="!form.projectId"
                @click="saveAsDraft('Approved')"
              >
                Save & Submit
              </button>
              <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showImageModal"
      class="modal fade show d-block"
      style="background-color: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content position-relative p-2">
          <!-- Title at the Top Center -->
          <h5 class="modal-title text-center mb-2">{{ selectedImageName }}</h5>

          <!-- Close Button in the Top Right -->
          <button
            type="button"
            class="btn-close position-absolute"
            style="top: 10px; right: 10px; z-index: 2"
            @click="closeImageModal"
          ></button>

          <div class="modal-body text-center">
            <!-- Displayed Image -->
            <img
              :src="selectedImage.signedUrl"
              class="img-fluid rounded"
              alt="Selected Image"
              style="
                max-height: 300px;
                object-fit: contain;
                border: 1px solid #ddd;
                padding: 5px;
              "
            />

            <!-- Action Buttons Below Image -->
            <div class="mt-4 d-flex justify-content-center gap-3">
              <button
                class="btn btn-sm btn-danger"
                @click="deleteImage(selectedImageIndex)"
              >
                Delete
              </button>
              <button
                class="btn btn-sm btn-success"
                @click="triggerFileInput(selectedImageIndex)"
              >
                Re-Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";

export default {
  name: "PurchaseRequestDetails",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      selectedImageName: "",
      showImageModal: false,
      selectedImage: null,
      selectedImageIndex: null,
      activeRow: null, // To track the current row being interacted with
      Projects: [],
      form: {
        projectId: "",
        priority: "Low",
        comments: " ",
        date: new Date().toISOString().slice(0, 10),
        prStatus: "",
      },
      rows: [
        {
          id: null,
          signedUrl: null,
          awsFileId: null,
          productName: "",
          description: "",
          unitType: "",
          quantity: 0,
          filteredProducts: [], // Track filtered products per row
          filteredUnitTypes: [], // Track filtered unit types per row
        },
      ],
      unitTypes: [
        { label: "Millimeter - mm", value: "mm" },
        { label: "Centimeter - cm", value: "cm" },
        { label: "Meter - m", value: "m" },
        { label: "Inch - In", value: "In" },
        { label: "Foot - ft", value: "ft" },
        { label: "Square Millimeter - sq.mm", value: "sq.mm" },
        { label: "Square Centimeter - sq.cm", value: "sq.cm" },
        { label: "Square Meter - sq.m", value: "sq.m" },
        { label: "Square Inch - sq.In", value: "sq.In" },
        { label: "Square Foot - sq.ft", value: "sq.ft" },
        { label: "Numbers - No's", value: "No's" },
        { label: "Pieces - pcs", value: "pcs" },
        { label: "Box - box", value: "box" },
        { label: "Pair - Pr", value: "Pr" },
        { label: "Set - Set", value: "Set" },
        { label: "Unit - unit", value: "unit" },
        { label: "Running Feet - RFT", value: "RFT" },
        { label: "Running Meter - RMT", value: "RMT" },
        { label: "Kilogram - Kg", value: "Kg" },
        { label: "Gram - gm", value: "gm" },
      ],
    };
  },
  mounted() {
    if (this.$route.params.moduleName === "Inventory") {
      this.fetchAllProjects();
    } else {
      this.fetchProjects();
    }

    if (this.$route.params.ProjectId) {
      this.form.projectId = this.$route.params.ProjectId;
    }
    if (this.$route.params.POId) {
      this.featchPRbyId(this.$route.params.POId);
    }
  },

  methods: {
    openImageModal(row, index) {
      this.selectedImageName = row.productName;
      this.selectedImage = row;
      this.selectedImageIndex = index;
      this.showImageModal = true;
    },
    closeImageModal() {
      this.showImageModal = false;
      this.selectedImage = null;
      this.selectedImageIndex = null;
    },
    deleteImage(index) {
      this.rows[index].awsFileId = null;
      this.rows[index].signedUrl = null;
      this.closeImageModal();
    },
    removeRow(index) {
      this.rows.splice(index, 1);
    },
    async featchPRbyId(Id) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/prById/${Id}`
        );
        const prData = response.data;

        // Set basic form data
        this.form = {
          prStatus: prData.prStatus,
          date: prData.date,
          projectId: prData.projectId,
        };

        // Populate line items
        this.rows = prData.lineitems.map((item) => ({
          id: item.id,
          awsFileId: item.awsFileId,
          productName: item.productName,
          description: item.description,
          unitType: item.unitType,
          quantity: item.quantity, // Placeholder for signed URL
          filteredProducts: [],
          filteredUnitTypes: [],
          awsFileName: item["awsFiles.awsFileName"],
          fileName: item["awsFiles.fileName"],
        }));

        for (let row of this.rows) {
          this.fetchInfoImage(row.awsFileName, row.fileName).then((signedUrl) => {
            if (signedUrl) {
              row.signedUrl = signedUrl;
            }
          });
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    triggerFileInput(index) {
      this.$refs.fileInputs[index].click();
    },
    handleFileSelect(event, index) {
      const files = event.target.files;
      if (files.length === 0) {
  
        return;
      }

      const file = files[0];
      const payload = {
        fileName: file.name,
        fileSize: file.size,
        contentType: file.type,
        moduleName: "procurement",
        fileCategory: "procurementProduct",
      };

      axios
        .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
        .then((response) => {
          const signedUrl = response.data.s3URL;
          this.rows[index].awsFileId = response.data.id;
      
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              if (response.data.awsFileName && response.data.fileName) {
                this.fetchInfoImage(
                  response.data.awsFileName,
                  response.data.fileName
                ).then((signedUrl) => {
                  if (signedUrl) {
                    this.rows[index].signedUrl = signedUrl;
                  }
                });
              }

            } else if (xhr.readyState === 4) {
              this.$handleError(xhr.responseText);
            }
          };
          xhr.send(file);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    selectProduct(product, index) {
      // Set the selected product details in the row
      this.rows[index].productName = product.productName;
      this.rows[index].description = product.description;
      this.rows[index].unitType = product.unitType;

      if (!this.rows[index].awsFileId) {
        this.rows[index].awsFileId = product.awsFileId;

        if (product["awsFiles.awsFileName"] && product["awsFiles.fileName"]) {
          this.fetchInfoImage(
            product["awsFiles.awsFileName"],
            product["awsFiles.fileName"]
          ).then((signedUrl) => {
            if (signedUrl) {
              this.rows[index].signedUrl = signedUrl;
            }
          });
        }
      }
      // Clear dropdowns and focus on the next input
      this.rows[index].filteredProducts = [];
      this.rows[index].filteredUnitTypes = [];
      this.$nextTick(() => {
        this.$refs.productNameInputs[index + 1]?.focus();
      });
    },

    async fetchInfoImage(AwsFileName, FileName) {
      const PayLoad = {
        awsFileName: AwsFileName,
        fileCategory: "procurementProduct",
        moduleName: "procurement",
        fileName: FileName,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          PayLoad
        );

        return Object.values(response.data)[0];
      } catch (error) {
        return null;
      }
    },
    goBack() {
      let activetab = "purchaseRequest";
      const department =
        this.$route.params.moduleName === "All"
          ? "Procurement"
          : this.$route.params.moduleName;
      if (this.$route.params.ProjectId && department && this.$route.params.Id) {
        this.$router.push({
          name: "ProcurementProjectProfile",
          params: { activeTab: activetab, Id: this.$route.params.Id },
        });
      } else {
        this.$router.go(-1);
      }
    },
    fetchProjects() {
      const department =
        this.$route.params.moduleName === "All"
          ? "Procurement"
          : this.$route.params.moduleName;
      axios
        .get(`${this.$apiEndPoint}/purchaseRequest/getProjectToCreatePr/${department}`)
        .then((response) => {
          this.Projects = response.data;

        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    // Function to fetch all projects
    fetchAllProjects() {
      axios
        .post(`${this.$apiEndPoint}/projects/In Progress/All/All`, {
          searchString: "",
        })
        .then((response) => {
          this.Projects = response.data.rows;
  
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    searchProduct(searchString, index) {
      if (searchString.length > 0) {
        axios
          .post(`${this.$apiEndPoint}/productList/searchProducts`, { searchString })
          .then((response) => {
            this.rows[index].filteredProducts = response.data; // Update the filtered products for the specific row
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        this.rows[index].filteredProducts = []; // Clear the product list for that row if no search string
      }
    },
    fillProductDetails(index) {
      const product = this.rows[index].filteredProducts.find(
        (p) => p.productName === this.rows[index].productName
      );
      if (product) {
        this.rows[index].description = product.description;
        this.rows[index].unitType = product.unitType;
        this.rows[index].awsFileId = product.awsFileId;
      }
    },
    saveAsDraft(prStatus) {
      // Determine the department based on the moduleName
      const department =
        this.$route.params.moduleName === "All"
          ? "Procurement"
          : this.$route.params.moduleName;

      // Construct the payload
      const payload = {
        prStatus: prStatus,
        projectId: this.form.projectId,
        department: department, // Use the determined department
        comments: this.form.comments,
        date: this.form.date,
        lineitems: this.rows.map((row) => ({
          id: row.id,
          awsFileId: row.awsFileId,
          productName: row.productName,
          description: row.description,
          unitType: row.unitType,
          quantity: row.quantity,
          category: row.category,
        })),
      };


      // Handle different PR statuses with confirmation prompts
      if (prStatus === "Pending") {
        // Confirmation prompt for "Pending" status
        if (
          window.confirm(
            "This PR cannot be updated again and will be sent for approval. Do you wish to proceed?"
          )
        ) {
          this.submitPurchaseOrder(payload);
        } else {
          this.$toast("User chose not to send the PR for approval.", "error");
        }
      } else if (prStatus === "Approved") {
        // Confirmation prompt for "Approved" status
        if (window.confirm("This PR cannot be updated again. Do you wish to proceed?")) {
          this.submitPurchaseOrder(payload);
        } else {
          this.$toast("User chose not to approve the PR.", "error");
        }
      } else {
        // Default case for other statuses without a prompt
        this.submitPurchaseOrder(payload);
      }
    },

    submitPurchaseOrder(payload) {
      const url = `${this.$apiEndPoint}/purchaseRequest${
        this.$route.params.POId ? `/${this.$route.params.POId}` : ""
      }`;
      const requestMethod = this.$route.params.POId ? axios.put : axios.post;

      requestMethod(url, payload)
        .then(() => {
          this.$toast(
            this.$route.params.POId
              ? "Purchase Order updated successfully."
              : "Purchase Order created successfully.",
            "success"
          );
          this.goBack();
        
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    addNewTask() {
      if (this.rows.length > 0 && !this.rows[this.rows.length - 1].productName) {
        this.$refs.productNameInputs[this.rows.length - 1].focus();
        return;
      }
      this.rows.push({
        awsFileId: null,
        productName: "",
        description: "",
        unitType: "",
        quantity: 0,
        filteredProducts: [], // Initialize filtered products for the new row
        filteredUnitTypes: [], // Initialize filtered unit types for the new row
      });
      this.$nextTick(() => {
        this.$refs.productNameInputs[this.rows.length - 1].focus();
      });
    },
    filterUnitTypes(searchString, index) {
      if (searchString.length > 0) {
        this.rows[index].filteredUnitTypes = this.unitTypes.filter((unit) =>
          unit.value.toLowerCase().includes(searchString.toLowerCase())
        );
      } else {
        this.rows[index].filteredUnitTypes = []; // Clear the unit type list for that row
      }
    },
    selectUnitType(unit, index) {
      this.rows[index].unitType = unit.value;
      this.rows[index].filteredUnitTypes = []; // Clear the dropdown after selection
    },
  },
};
</script>

<style scoped>
.purchase-request {
  padding: 10px 10px;
  height: 100%;
  width: 100%;
}

.purchase-request .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  width: 100%;
  margin-top: 20px;
  padding: 0px 0px;
  overflow-x: scroll;
  position: relative;
}

.small-wrapper {
  width: 100%;
  height: 70vh;
  /* Set a fixed height to make the table vertically scrollable */
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 100px;
  padding-bottom: 200px;
}

.small-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  border-collapse: collapse;
  /* Ensure the table layout is fixed and borders collapse */
}

.table-header-heading th {
  background-color: #ccc;
  text-align: center;
  position: sticky;
  top: 0;
}

.table-header-heading th:nth-child(1) {
  width: 5%;
  text-align: center;
}

.table-header-heading th:nth-child(2) {
  width: 20%;
  text-align: center;
}

.table-header-heading th:nth-child(4),
.table-header-heading th:nth-child(5),
.table-header-heading th:nth-child(6) {
  width: 10%;
  text-align: center;
}

.table-header-heading th:nth-child(3) {
  width: 25%;
  text-align: center;
}

tbody td {
  padding: 0px 5px;
  margin: 0;
  text-align: left;
  text-align: start !important;
}

table td:nth-child(1) {
  text-align: center;
}

input {
  border: none;
  padding: 5px;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest {
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100%);
}

.footerPuchaseRequest .btn {
  margin: 5px 20px;
}

.input-bg {
  background-color: #fff;
}

.description {
  width: 25%;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: pre;
}

.quantity-input {
  width: 100%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

#date {
  border: 1px solid #000000;
}

/* Dropdown Menu Container */
.dropdown-menu {
  position: absolute;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  margin-top: 5px;
  width: max-content;
  max-height: 200px;
  /* Max height with scroll */
  overflow-y: auto;
}

/* Dropdown Menu Items */
.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
  list-style-type: none;
  /* Removes bullet points */
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.2s ease-in-out;
}

/* Hover Effect for Menu Items */
.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

/* Selected Item Style */
.dropdown-menu li.selected {
  background-color: #007bff;
  color: white;
}

/* Last item doesn't need border */
.dropdown-menu li:last-child {
  border-bottom: none;
}

/* Empty state when no results are found */
.no-results {
  padding: 8px 12px;
  color: #999;
  text-align: center;
}

.headerOfthePR {
  margin: 8px;
}

.cell-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%; /* Ensures the div takes the full width of the td */
  margin-top: 7px;
}

.delete-icon img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.row-index {
  /* Optional: Adjust margin or padding as needed */

  font-size: 14px; /* Adjust font size if necessary */
  color: #000; /* Adjust text color if necessary */
}

.AddImage {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.uploadedimage {
  width: 150px;
  height: 150px;
  object-fit: cover;
  image-rendering: auto;
}
.table-container td {
  text-align: center; /* Aligns text and content to the center */
  vertical-align: middle; /* Ensures vertical centering */
  padding: 8px; /* Optional: Adds some space inside the cell */
}

.table-container th {
  text-align: center; /* Align headers to the center as well */
  vertical-align: middle;
}

@media (max-width: 768px) {
  .table-header-heading th:nth-child(1) {
    width: 10%;
    text-align: center;
  }
  .uploadedimage {
    width: 20px;
    height: 20px;
    object-fit: cover;
    image-rendering: auto;
  }
  .table-container {
    overflow-x: auto;
  }
  .footerPuchaseRequest {
    margin-top: 20px;
    position: absolute;
    bottom: 0%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .footerPuchaseRequest button {
    white-space: nowrap;
  }

  .purchase-request {
    overflow-y: scroll;
  }

  .headerOfthePR {
    margin: 0px;
  }

  .d-flex.align-items-center {
    display: block !important;
  }

  .d-flex.align-items-center label,
  .d-flex.align-items-center .form-control {
    width: 100% !important;
  }

  .small-table {
    display: block;
    overflow-x: auto;
  }

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  label {
    flex: 0.5;
    white-space: nowrap;
  }

  input {
    flex: 0.5;
  }
  .AddImage {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
}
</style>
