<template>
  <ProcurementProductList @fetchData="fetchData" :selectedTargetId="SelectedTargetId" />
  <div>
    <Header />

    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex justify-content-between align-items-center">
              <!-- Left Content -->
              <div class="col-md-8 d-flex align-items-center">
                <!-- <h5 class="mainHeading" v-if="this.TableData[0]">
                  {{ this.TableData[0]["stores.storeName"] }}
                </h5> -->
                <h5 style="padding-top: 0%; padding-left: 1%">
                  {{ storeName }} Stock Report
                </h5>
              </div>

              <!-- Right Content -->
              <div class="col-md-4 d-flex justify-content-end align-items-center">
                <!-- Download Icon -->
                <div class="ImportExport mx-2">
                  <!-- Download Icon -->
                  <img
                    src="../../assets/icons/download.png"
                    alt="Download"
                    id="ImportIcon"
                    @click="toggleDropdown"
                  />

                  <!-- Dropdown Options -->
                  <ul v-show="showDropdown" class="dropdown-options">
                    <li @click="handleDownload('pdf')">PDF</li>
                    <li @click="exportToCSV()">Excel</li>
                  </ul>
                </div>

                <!-- Search Box -->
                <div class="searchboxOfTable mx-2 d-flex align-items-center">
                  <div class="search-box d-flex align-items-center">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SearchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      placeholder="Search"
                      v-model="searchString"
                    />
                  </div>
                  <img
                    src="../../assets/icons/cancel.png"
                    alt="Filter Icon"
                    id="FilterIconOfTable"
                    class="ms-2"
                    style="width: 20px; height: 20px; cursor: pointer; margin-top: 10px"
                    @click="ToStoreLedger"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat mb-3 mt-3">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="text-center">Sl No.</th>
                  <th class="tableHeaderHeading text-left">Product Name</th>
                  <th class="tableHeaderHeading text-right">Closing Stock</th>
                  <th class="tableHeaderHeading text-right">Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(stock, index) in displayedData"
                  :key="index"
                  @click="
                    goToStoreDetails(
                      stock.storeId,
                      stock.productId,
                      this.storeName,
                      stock['productLists.productName']
                    )
                  "
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-left">
                    {{ stock["productLists.productName"] }}
                  </td>
                  <td class="text-left">{{ stock.closingStock }}</td>
                  <td class="text-center">{{ stock["productLists.unitType"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="goToPreviousPage"
                :disabled="!hasPreviousPage"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="goToNextPage"
                :disabled="!hasNextPage"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showBulkUploadModal">
    <div class="modal mt-4" role="dialog" style="display: block">
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="min-width: 80%; overflow: auto"
      >
        <div
          class="modal-content"
          style="
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          "
        >
          <div class="modal-header">
            <h5 class="modal-title">Bulk Upload Leads</h5>
            <img
              src="../../assets/icons/cancel.png"
              alt=""
              style="
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 2.5%;
                cursor: pointer;
              "
              @click="closeModal()"
            />
          </div>
          <div class="modal-body p-0" style="max-height: 320px; overflow: auto">
            <table
              style="max-height: 320px; overflow: auto"
              class="table table-responsive"
              v-if="data.productList.length"
            >
              <caption class="d-none">
                Table
              </caption>
              <thead style="margin-top: 0 !important">
                <tr>
                  <th v-for="(value, key) in data.productList[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lead, index) in data.productList" :key="index">
                  <td v-for="(value, key) in lead" :key="key">
                    <span v-if="!value" style="color: red">Please fill this field</span>
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-sm btn-primary confirmationButton"
              :disabled="loading"
              @click="approveBulkLeads"
            >
              Continue Upload
            </button>
            <button type="button" class="btn btn-sm- btn-secondary" @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcurementProductList from "../Modal/ProcurementProductList.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
import * as XLSX from "xlsx";
let pageFlag = true;
export default {
  name: "ProductMaster",
  components: {
    Header,
    Nav,
    ProcurementProductList,
  },
  data() {
    return {
      allData: [],
      showDropdown: false, // State for dropdown visibility
      storeStock: [],
      totalCount: null,
      totalArchiveCount: null,
      showBulkUploadModal: false,
      SelectedTargetId: null,
      EmployeeProfile: null,
      searchString: "",
      pageSize: 50,
      storeName: this.$route.params.storeName,
      currentPage: 1,
      showUnclaimedList: true,
      error: null,
      // storeName: [],
      TableData: [],
      ArchiveData: [],
      selectedTeamId: null,
      employeeToDelete: null,
      selectedEmployeeId: null,
      data: {
        productList: [],
      },
    };
  },

  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.storeName = this.$route.params.storeName;
    this.fetchData();
  },

  methods: {
    ToStoreLedger() {
      this.$router.push({
        name: "StoreLedger",
      });
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown; // Toggle dropdown visibility
    },

    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        Product_Name: data["productLists.productName"] || "N/A",
        Closing_Stock: data.closingStock || "N/A",
        Unit: data["productLists.unitType"] || "N/A",
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    formatAddress(address) {
      // Replace newline characters with commas
      return address ? address.replace(/\n/g, ", ") : "N/A";
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    goToStoreDetails(storeId, productId, storeName, productName) {
      this.$router.push({
        name: "Report",
        params: {
          storeId: storeId,
          productId: productId,
          storeName: storeName,
          productName: productName,
        },
      });
    },

    async fetchStoreStock() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/storeStock/${this.id}/${this.currentPage}`,
          { searchString: "" }
        );
        this.storeStock = response.data.rows;
        console.log("Store Details:", this.storeStock);
      } catch (error) {
        console.error("Error fetching store details:", error);
      }
    },

    async changeStatus(sourceId, index, status) {
      this.TableData[index].status = status;
      try {
        await axios.put(`${this.$apiEndPoint}/productList/${sourceId}`, {
          status: status,
        });
        this.fetchData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleFileUpload(event) {
      // Get the first file from the file input
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader(); // Create a new FileReader instance
        reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer

        // Set up the onload event to handle the file once it's read
        reader.onload = async (e) => {
          try {
            const data = e.target.result; // Get the raw binary data
            const formattedData = this.processWorkbook(data); // Process the workbook data
            this.showUnclaimedList = false; // Hide the unclaimed list in the UI
            this.showBulkUploadModal = true; // Show the bulk upload modal
            this.data = { productList: formattedData }; // Store the processed data
            // Optionally send the processed data to the server
            // axios.post('/your-endpoint', this.data);
          } catch (error) {
            this.$handleError(error);
          }
        };
      }
    },

    processWorkbook(data) {
      // Read the binary data into a workbook object
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet name
      const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Convert sheet to JSON
      return this.formatData(jsonData); // Format the JSON data
    },

    formatData(jsonData) {
      const dateColumnHeader = "date"; // Define the date column header
      const headers = jsonData[0]; // Get the headers from the first row

      // Map through each row to create an array of objects
      return jsonData.slice(1).map((row) => {
        const lead = {}; // Initialize an object for the lead data
        headers.forEach((key, index) => {
          const cell = row[index]; // Get the cell data
          lead[key] = this.formatCellValue(key, cell, dateColumnHeader); // Format the cell value
        });
        return lead; // Return the formatted lead object
      });
    },

    formatCellValue(key, cell, dateColumnHeader) {
      // Check if the cell corresponds to the date column
      if (key === dateColumnHeader && cell !== undefined) {
        const excelDate = Number(cell); // Convert the cell to a number
        const date = new Date((excelDate - 25569) * 86400 * 1000); // Convert to a Date object
        return this.convertToDDMMYYYY(date); // Format the date to DD/MM/YYYY
      }
      return cell !== undefined ? cell : ""; // Return cell value or empty string if undefined
    },

    async approveBulkLeads() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      try {
        this.loading = true;

        // Format the data to match backend expectations
        const backendData = {
          productList: this.data.productList.map((lead) => ({
            productName: lead?.ProductName || null,
            description: lead?.Description || null,
            HSNCode: lead?.HSNCode || null,
            articleNo: lead?.ArticleNo || null,
            category: lead?.Category || null,
            unitType: lead?.UnitType || null,
            unitPrice: lead?.UnitPrice || null,
            discount: lead?.Discount || null,
            GST: lead?.GST || null,
          })),
        };

        console.log(backendData);
        const response = await axios.post(
          `${this.$apiEndPoint}/productList/bulkCreate/`,
          backendData
        );

        btn.classList.remove("confirmationButton--loading");
        if (response.status === 201) {
          this.showBulkUploadModal = false;
          this.showUnclaimedList = true;
          this.fetchData();
          this.data.productList = [];
          this.loading = false;
        } else {
          console.error("Failed to create bulk leads");
          this.loading = false;
        }
      } catch (error) {
        this.$handleError(error);
        this.loading = false;
        this.data.productList = [];
        this.showBulkUploadModal = false;
        this.showUnclaimedList = true;
        btn.classList.remove("confirmationButton--loading");
      }
    },

    closeModal() {
      // Close modal logic
      this.showBulkUploadModal = false;
      this.showUnclaimedList = true;
    },
    openModal(TargetId) {
      this.SelectedTargetId = TargetId;
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.href = "/Product_Master_Import_Template.csv"; // Path to the file in the public folder
      link.setAttribute("download", "Product_Master_Import_Template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async ReInviteEmployee(employeeEmailId) {
      try {
        // Make a POST request to the mailInvitation endpoint
        const response = await axios.post(
          `${this.$apiEndPoint}/employee/mailInvitation`,
          {
            employeeEmailId: employeeEmailId, // Pass the employee's email as part of the request body
          }
        );
        this.$toast("Invitation sent successfully:", "success");
        console.log("Invitation sent successfully:", response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },
    goToEmployeeProfile(employee) {
      const encodedId = btoa(employee.id.toString());
      this.$router.push({
        name: "EmployeeProfile",
        params: {
          employeeId: encodedId,
        },
      });
    },

    openCreateEmployeeModal(employeeId) {
      // Set the selected employee ID
      this.selectedEmployeeId = employeeId;
      console.log(this.selectedEmployeeId);
      // Show the modal or perform other actions
      // Example: this.showModal = true;
    },

    /*  findEmployeeRole(employeeRoles, type) {
      const role = employeeRoles.find(role => role.team && type === 'team');
      const team = employeeRoles.find(role => role.role && type === 'role');
      return type === 'team' ? (role ? role.team : '') : (team ? team.role : '');
    }, */
    getAllTeams(employeeRoles) {
      if (!employeeRoles || employeeRoles.length === 0) return "";
      return employeeRoles.map((role) => role.team).join(", ");
    },
    getAllRoles(employeeRoles) {
      if (!employeeRoles || employeeRoles.length === 0) return "";
      return employeeRoles.map((role) => role.role).join(", ");
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },

    async fetchData() {
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
        console.log(pageFlag);
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/storeStock/${this.id}/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
        console.log(`the fetched stock of store ID ${this.id}`, this.TableData);

        for (let product of this.TableData) {
          if (product["awsFiles.awsFileName"] && product["awsFiles.fileName"]) {
            const signedUrl = await this.fetchInfoImage(
              product["awsFiles.awsFileName"],
              product["awsFiles.fileName"]
            );
            if (signedUrl) {
              product.signedUrl = signedUrl;
            }
          }
        }

        console.log(this.TableData);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchInfoImage(AwsFileName, FileName) {
      const PayLoad = {
        awsFileName: AwsFileName,
        fileCategory: "procurementProduct",
        moduleName: "procurement",
        fileName: FileName,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          PayLoad
        );
        return Object.values(response.data)[0];
      } catch (error) {
        return null;
      }
    },

    DeleteLead(data) {
      axios
        .delete(`${this.$apiEndPoint}/estimates/deleteEstimates`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();

          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async searchLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/productList/searchProducts`,
          searchData
        );
        this.TableData = response.data;

        // Reset current page to 1 after search
        this.currentPage = 1;
      } catch (error) {
        this.$handleError(error);
      }
    },

    confirmDelete(employeeToDelete, index) {
      const employeeId = employeeToDelete.id;
      // Remove the employee from the displayedData array
      this.displayedData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/productList/${employeeId}`)
        .then((response) => {
          this.$toast("Product deleted successfully", "success");
          console.log("Product deleted successfully:", response.data);
          this.fetchData(); // Refresh data after deletion
          this.showModal = false; // Hide the modal
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      console.log(this.currentPage);
      this.fetchData();

      this.selectedCustomers = [];
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();

      this.selectedCustomers = [];
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const isYMDFormat = dateString.includes("-");
      if (isYMDFormat) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
      } else {
        // Handle other formats (e.g., dd/mm/yyyy, mm/dd/yyyy)
        // Try to parse the date string to a Date object
        const parsedDate = new Date(dateString);
        if (isNaN(parsedDate.getTime())) {
          return dateString;
        } else {
          // Format the parsed date as dd-mm-yyyy
          const day = parsedDate.getDate().toString().padStart(2, "0");
          const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
          const year = parsedDate.getFullYear();
          return `${day}-${month}-${year}`;
        }
      }
    },
  },
};
</script>

<style scoped>
.mainHeading {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.ImportExport {
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  display: flex;
  align-items: left;
}

.search-box {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-bottom: 15px;
  border: none;
}

.search-box input {
  border: none;
  border-bottom: 1px solid #ccc; /* Bottom border only */
  outline: none; /* Removes blue focus outline */
  font-size: 12px;
  margin-left: 5px;
  background-color: #ffffff; /* Removes background */
  width: 100px;
}

/* Additional styles for focus state */
.search-box input:focus {
  outline: none; /* Ensures no focus outline is visible */
}

.cursor-pointer {
  cursor: pointer;
}

#SearchIconOfTable,
#FilterIconOfTable {
  cursor: pointer;
  width: 16px;
  height: 14px;
}

.tableHeaderHeading {
  font-size: 14px;
}
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

.status:hover {
  outline: 1px solid #ccc;
  padding: 1px;
  cursor: pointer;
}

.FormatTable {
  width: 100%;
  height: 100%;
}

.headerOfTable {
  width: 100%;
  padding: 1.5% 1%;
}

#addicon {
  width: 50px;
  height: 40px;
  margin: 5px 0 0 15px;
  outline: none;
}

#ImportIcon {
  width: 60%;
  margin-left: 9%;
  margin-top: 10px;
  background-color: inherit;
}

.dropdown-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 12% 0 0;
  width: 150px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 12px;
}

.dropdown-options li:hover {
  background-color: #4ea1fa;
  color: #fff;
}

#ArchiveIcon {
  width: 30%;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 60%;
  display: flex;
  align-items: end;
}

#FilterIconOfTable {
  height: 8%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.table-wrapper {
  max-height: 71vh;
  overflow-y: auto;
  margin: -2% 1%;
}

/* Table styling */
.tableFormat {
  white-space: nowrap;
  max-height: 100%;
  width: 100%;
  overflow: scroll;
}

.tableFormat thead,
.tableFormat tbody {
  white-space: nowrap;
}

/* Sticky header */

.tableFormat thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Scrollable body */
.tableFormat tbody {
  overflow: scroll;
}
/*
  .tableFormat tbody  tr td{
   border: 1px solid #ccc;
  }*/

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(odd) {
  background-color: #fff;
}

.dropdown-menu {
  z-index: 9999;
}

.tableFormat tbody tr td {
  padding: 0px 8px;
  height: auto;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
  color: #212529;
  padding: 5px 8px;
  height: auto;
}

.tableFormat tbody tr {
  height: 40px;
}

.tableFormat tbody td:nth-child(4) {
  width: 8%;
}

#blackThreeDots {
  width: 20px;
  height: 20px;
  margin-left: 30%;
  padding-bottom: 10%;
  position: relative;
  cursor: pointer;
}

#checkBox2 {
  width: 16px;
  height: 17px;
}

.page-numbers span {
  margin: 5px;
  cursor: pointer;

  /* Adjust color for page numbers */
}

.BoardHeader {
  display: flex;
  justify-content: space-between;
  /* Ensure items are spaced apart */
  align-items: center;
  /* Center items vertically */
  white-space: nowrap;
  /* Prevent wrapping */
}

.bolderHeadings {
  font-size: 16px;

  color: #000000;
  font-weight: bolder;
}

.tabledropdownEdits {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 9000;
  top: 60%;
}

.tabledropdownEdits p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

.SigleLeadThings {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 1000;
  top: 60%;
}

.SigleLeadThings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

@media (max-width: 768px) {
  #addicon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }
  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
