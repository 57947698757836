<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="section-wrapper">
          <div class="section blackHeadSection">
            <div class="blackHeadSectionHeader row justify-content-between p-2">
              <div class="col-auto p-4 twentyPxFont WEB">Home</div>
              <div class="col-auto text-center mt-5">
                <p class="welcome m-0">Hi {{ fullName }}, {{ greeting }}</p>
              </div>
              <div class="col-auto p-4 text-left WEB">
                <p class="m-0">{{ currentDay }}</p>
                <p class="m-0">{{ formattedDate }}</p>
              </div>
              <div class="col-auto p-4 text-center MOBILE">
                <p class="m-0 text-center" style="font-size: 12px">
                  {{ currentDay }} {{ formattedDate }}
                </p>
              </div>
            </div>
            <div
              class="d-flex justify-content-between"
              style="display: flex; margin-left: 3%"
            >
              <div
                v-if="
                  $LocalUser &&
                  $LocalUser.employeeRoles &&
                  $LocalUser.employeeRoles.length > 1
                "
                class="roles-container"
              >
                <div v-for="department in departments" :key="department.name">
                  <div
                    v-if="hasRole(department.name)"
                    :class="[
                      'role-tab',
                      {
                        activeDashboard:
                          currentDashboard === getDashboardComponentName(department.name),
                      },
                    ]"
                  >
                    <span
                      class="role-name"
                      @click="showDashboard(department.name)"
                      :title="department.name"
                    >
                      <img class="mt-1" :src="teamIcons[department.name]" alt="Team" />
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="d-flex viewSection"
                style="margin-right: 3.4%"
                v-if="showExecative"
              >
                <p class="mt-1">View :</p>
                <select
                  style="
                    margin-left: 3px;
                    font-size: 12px;
                    height: 30px;
                    width: 150px;
                    cursor: pointer;
                    :focus {
                      outline: none;
                      box-shadow: none;
                    }
                  "
                  class="form-select"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                >
                  <option value="All" selected>Team</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- <div class="section middleHeadSection"></div> -->
          <div v-if="!isSalesTeam() && !isDesignTeam() && !isProcurementTeam()"></div>

          <component
            :is="currentDashboard"
            :SelectedEmployeeId="selectedEmployeeId"
            @fetchTasks="fetchTasks()"
            @task-selected="onTaskSelected"
            @PR-selected="onPrSelected"
            @PI-selected="onPISelected"
          />
        </div>
      </div>
    </div>
    <taskSidePanel
      :selectedTaskId="selectedTaskId"
      @updateTaskById="fetchTasks()"
      @task-deleted="fetchTasks()"
    />
    <PRDetailsModal :PurchaseRequestId="selectedPRId" ref="PRDetailsModal" />
    <PODetailsModal :PurchaseOrderId="selectedPIId" ref="PODetailsModal" />
  </div>
</template>

<script>
import axios from "axios";
import PODetailsModal from "./Modal/PODetailsModal.vue";
import PRDetailsModal from "./Modal/PRDetailsModal.vue";
import taskSidePanel from "./Modal/taskSidePanel.vue";
import DesignDashboard from "./Design/DesignDashboard.vue";
import SalesDashboard from "./Sales/SalesDashboard.vue";
import OnsiteDashboard from "./Onsite/OnsiteDashboard.vue";
import ProcurementDashboard from "./Procurement/ProcurementDashboard.vue";
import AccountsDashboard from "./Accounts/AccountsDashboard.vue";
import HRDashboard from "./HR/HRDashboard.vue";
import InventoryDashboard from "./Inventory/InventoryDashboard.vue";
import AdminDashboard from "./Admin/AdminDashboard.vue";

import Header from "./Header.vue";
import Nav from "./Nav.vue";

export default {
  name: "Home",
  components: {
    Header,
    Nav,
    SalesDashboard,
    DesignDashboard,
    OnsiteDashboard,
    ProcurementDashboard,
    taskSidePanel,
    PRDetailsModal,
    PODetailsModal,
    AccountsDashboard,
    HRDashboard,
    InventoryDashboard,
    AdminDashboard,
  },
  data() {
    return {
      selectedPIId: null,
      selectedPRId: null,
      selectedTaskId: null,
      showExecative: false,
      selectedEmployeeId: null,
      employees: [],
      currentDashboard: null,
      currentDay: "",
      formattedDate: "",
      fullName: "",
      greeting: "",
      employeeRoles: [],
      teamIcons: {
        Marketing: require("../assets/icons/black_Marketing.png"),
        Sales: require("../assets/icons/black_Sales.png"),
        Designing: require("../assets/icons/black_Designing.png"),
        Onsite: require("../assets/icons/black_OnSite.png"),
        Procurement: require("../assets/icons/black_Procurement.png"),
        Inventory: require("../assets/icons/black_Stock Inventory.png"),
        Accounts: require("../assets/icons/black_Accounts.png"),
        HR: require("../assets/icons/black_HumanResource.png"),
        Admin: require("../assets/icons/adminIcon.png"),
      },
      departments: [
        { name: "Marketing" },
        { name: "Sales" },
        { name: "Designing" },
        { name: "Procurement" },
        { name: "Onsite" },
        { name: "Execution" },
        { name: "Inventory" },
        { name: "Accounts" },
        { name: "HR" },
        { name: "Admin" },
      ],
    };
  },
  mounted() {
    this.setCurrentDate();
    let user = localStorage.getItem("user-info");

    if (user) {
      try {
        const userData = JSON.parse(user);
        const userObject = userData?.user;
        this.fullName = userObject?.fullname;
        this.greeting = this.getGreeting();
        this.employeeRoles = userData?.employeeRoles;
        this.selectedEmployeeId = userObject?.employeeId;
      } catch (error) {
        localStorage.clear();
        this.$router.push({ name: "Login" });
        this.$handleError(error);
      }
    } 
    if (this.$LocalUser?.employeeRoles?.length > 0) {
      this.showDashboardInOrder();
    }
  },

  methods: {
    fetchTasks() {
      // Trigger the fetch task event to the active dashboard component
      this.$emit("fetchTasks");
    },
    onPISelected(selectedPIId) {
      this.selectedPIId = selectedPIId;
    },
    onTaskSelected(taskId) {
      // Update the selected task ID
      this.selectedTaskId = taskId;
    },
    onPrSelected(SelectedPRId) {
      this.selectedPRId = SelectedPRId;
    },
    showDashboardInOrder() {
      for (let department of this.departments) {
        let role = this.$LocalUser.employeeRoles.find(
          (role) => role.team === department.name
        );
        if (role) {
          this.showDashboard(role.team);
          break;
        }
      }
    },
    checkExecutiveView() {
      const currentRole = this.employeeRoles.find(
        (role) => this.getDashboardComponentName(role.team) === this.currentDashboard
      );
      this.showExecative = currentRole && currentRole.role === "Manager";
    },
    setSelectedEmployee() {},
    async fetchEmployees(Team) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/${Team}`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    showDashboard(team) {
      this.currentDashboard = this.getDashboardComponentName(team);
      this.checkExecutiveView();
      this.fetchEmployees(team.toLowerCase());
    },
    getDashboardComponentName(team) {
      if (team === "Sales") {
        return "SalesDashboard";
      } else if (team === "Designing") {
        return "DesignDashboard";
      } else if (team === "Onsite") {
        return "OnsiteDashboard";
      } else if (team === "Procurement") {
        return "ProcurementDashboard";
      } else if (team === "Accounts") {
        return "AccountsDashboard";
      } else if (team === "Admin") {
        return "AdminDashboard";
      } else if (team === "HR") {
        return "HRDashboard";
      } else if (team === "Inventory") {
        return "InventoryDashboard";
      }

      return null;
    },
    setCurrentDate() {
      const date = new Date();
      this.currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        date
      );
      const options = { day: "numeric", month: "long", year: "numeric" };
      this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    },
    getCurrentDateTime() {
      const options = {
        day: "numeric",
        weekday: "long",
        month: "long",
        year: "numeric",
      };
      const currentDate = new Date();
      return currentDate.toLocaleDateString("en-US", options).toUpperCase();
    },
    getGreeting() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      if (currentHour < 12) {
        return "Good Morning";
      } else if (currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    isProcurementTeam() {
      return (
        this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Procurement"
      );
    },
    isSalesTeam() {
      return this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Sales";
    },
    isDesignTeam() {
      return this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Designing";
    },
    isOnsiteTeam() {
      return this.employeeRoles.length > 0 && this.employeeRoles[0].team === "Onsite";
    },
    hasRole(team) {
      return this.employeeRoles.some((role) => role.team === team);
    },
  },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/inter");

.HomePageDetails {
  height: 100%;
  width: 100%;
}

.HomePageDetails header {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  text-align: center;
}

.welcomeDateTime {
  font-size: 15.4px;
  font-weight: bold;
  padding-top: 30px;
}

.welcome {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  background-color: #f4f4f4;
}

.roles-container {
  display: flex;
  flex-wrap: wrap;
  color: black;
  margin-bottom: 10px;
  font-size: 12px;
}

.role-tab {
  height: 35px;
  width: 35px;
  background-color: #000000;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.role-tab:hover {
  background-color: #e0e0e0;
}

.role-tab.activeDashboard {
  background-color: #e0e0e0;
}

.role-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.role-name img {
  height: 25px;
  width: 25px;
}

.dashboardcard-container {
  position: absolute;
  top: calc(35% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* Set to 100% to fully occupy available space */
}

.dashboardcard {
  width: 21%;
  /* Set width as a percentage of the container */
  height: 100px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashboardcard img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.blackHeadSection {
  background-color: #292929;
  height: 32%;
  color: #ffffff;
}

.middleHeadSection {
  height: 14%;
  background-color: #000000;
}

.WhiteHeadSection {
  background-color: #000000;
  height: 54%;
  margin-left: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 6%;
  /* Hide the horizontal scrollbar */
}

.blackHeadSectionHeader {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .blackHeadSectionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .welcome {
    font-size: 18px;
  }

  .HomePageDetails {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  .section-wrapper {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .dashboardcard-container {
    position: relative;
    /* Change position to relative */
    flex-direction: column;
    /* Arrange cards in a column */
    top: 0;
    /* Reset top position */
    left: 0;
    /* Reset left position */
    transform: none; /* Remove transform */
    width: auto; /* Adjust width to fit content */
 
  }

  /* Cards take up full width on mobile */
  .dashboardcard {
    width: 90%;
    height: auto;
    margin: 10px 0;
    font-size: 12px;
    white-space: nowrap;
  }
  .role-tab {
    margin: 10px;
  }

  .DashboardHeaderfont {
    display: none;
  }
  .WhiteHeadSection {
    display: none;
  }
  .welcome {
    padding-left: 14px;
    text-align: center;
  }
  .viewSection {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}
</style>
