import router from '../routers';
import axios from 'axios';
import UserObject from './UserObject';

export function setAuthHeader(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export function logout() {
  localStorage.clear();
  router.push({ name: 'Login' });
}

export function isExecutive(team) {
  const roles = UserObject.employeeRoles;
  return roles.some((role) => role.team === team && role.role === 'Executive');
}

export function isManager(team) {
  const roles = UserObject.employeeRoles;
  return roles.some((role) => role.team === team && role.role === 'Manager');
}
