<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="d-flex align-items-center justify-content-between px-3 pt-2 mt-0">
          <div class="d-flex align-items-center gap-3">
            <h5 style="font-weight: bold; padding: 10px">Stores</h5>
            <!-- <img
              style="width: 20px; height: 20px; cursor: pointer; margin-bottom: 9px"
              data-bs-toggle="modal"
              data-bs-target="#addAddressesModal"
              @click="openModal(null)"
              src="../../assets/icons/blueAddicon.png"
              alt="add"
            /> -->
          </div>

          <!-- <div class="search-container">
            <button
              type="button"
              class="browse"
              data-bs-toggle="modal"
              data-bs-target="#addAddressesModal"
              @click="openModal(null)"
            >
              Add Address +
            </button>
          </div> -->
        </div>

        <div class="table-structure">
          <!-- Table Structure -->
          <table class="custom-table">
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Store Name</th>
                <th>Store Number</th>
                <th>Location</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(store, index) in stores"
                :key="store.id"
                @click="goToStoreDetails(store.id)"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ store.storeName }}</td>
                <td>{{ store.storeNumber }}</td>
                <td>
                  {{ store["addresses.name"] }} {{ store["addresses.address"] }},
                  {{ store["addresses.city"] }} {{ store["addresses.state"] }},
                  {{ store["addresses.country"] }}
                  {{ store["addresses.pincode"] }}
                </td>
                <!-- <td class="action-icons">
                  <img
                    src="../../assets/icons/yellowEdit.png"
                    data-bs-toggle="modal"
                    data-bs-target="#addAddressesModal"
                    @click="openModal(store.id)"
                    alt="Edit"
                  />
                  <img src="../../assets/icons/redDelete.png" alt="Delete" />
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <AddAddressesModal :selectedStoreId="selectedStoreId" />
  </div>
</template>

<script>
import AddAddressesModal from "../Modal/AddAddressesModal.vue";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  components: {
    Header,
    Nav,
    AddAddressesModal,
  },
  data() {
    return {
      selectedStoreId: "",
      selectedStore: {}, // Holds details of the selected store
      stores: [],
    };
  },

  mounted() {
    this.fetchStores();
  },

  methods: {
    openModal(id) {
      this.selectedStoreId = id;
    },

    goToStoreDetails(storeId) {
      this.$router.push({
        name: "StoresInwardOutwardEntry",
        params: { Id: storeId },
      });
    },

    async fetchStores() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/store/getStores`);
        this.stores = response.data;
        console.log("fetched stores", this.stores);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    },
  },
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  padding: 1% 1%;
  overflow-x: auto;
  height: calc(100% - 74px);
  padding-bottom: 20px;
  border-collapse: collapse; /* Ensures no gaps between cells */
  table-layout: fixed; /* Ensures cells respect width and height */
}

.custom-table th,
.custom-table td {
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 15px;
  color: #141414;
  padding: 10px;
  height: 50px; /* Sets a consistent height for all cells */
  vertical-align: middle; /* Centers content vertically */
  line-height: 15px; /* Ensures text aligns with the height */
  overflow: hidden; /* Ensures content does not exceed cell boundaries */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  white-space: nowrap; /* Prevents text from wrapping */
}

.custom-table th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #bcbfc6;
  font-weight: 600;
  color: #212529;
  padding: 8px;
  font-size: medium;
}

.custom-table tr:hover {
  background-color: #f5f5f5; /* Adds a hover effect for rows */
}

.table-structure {
  padding: 0% 1% 1% 1%;
  max-height: 560px;
  overflow-y: auto;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #ece9e9;
}

.custom-table th:nth-child(1),
.custom-table td:nth-child(1) {
  width: 4%;
}

.custom-table th:nth-child(2),
.custom-table td:nth-child(2) {
  width: 15%;
}

.custom-table th:nth-child(3),
.custom-table td:nth-child(3) {
  width: 10%;
}

.custom-table th:nth-child(4),
.custom-table td:nth-child(4) {
  width: 40%;
}

.custom-table th:nth-child(5),
.custom-table td:nth-child(5) {
  width: 10%;
  text-align: center;
}

.container-bg {
  background-color: #fcfcfc;
}

.add-icon {
  width: 6%;
  height: 6%;
  margin-top: 2%;
}

.search-img {
  width: 15px;
  height: 15px;
}

.search:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid #141414;
  font-size: small;
}

.search-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
</style>
