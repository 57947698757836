<template>
  <CustomerCreateModal
    :defaultSourceId="sourceIdFromParentPage"
    :selectedLeadId="selectedLeadId"
    @fetchData="fetchData()"
  />
  <div>
  
    <div
      class="modal my-4"
      id="exampleModal3"
   
      aria-labelledby="exampleModalLabel"

    >
      <div class="LeadDetaisForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <div class="row col-md-12">
                <div class="col-md-6 d-flex align-content-center">
                  <img
                    src="../../assets/icons/profile.png"
                    alt=""
                    style="width: 45px; height: 45px"
                  />
                  <div class="ms-3" v-if="leadData">
                    <h5 class="card-title">{{ leadData.name }}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{ leadData.email }}</h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      {{ leadData.contactNo }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      {{ leadData["propertyAddress.address"] }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted">
                      {{ leadData["propertyAddress.city"] }},{{
                        leadData["propertyAddress.state"]
                      }}
                    </h6>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div
                  class="col-md-5 d-flex justify-content-end align-items-start text-nowrap"
                >
                  <div class="position-relative">
                    <button
                    v-if="!projectId"
                      class="btn btn-outline-dark btn-sm me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-body">
              <section class="subHeadingsOfLeads">
                <div
                  class="d-flex align-content-center p-1 mt-2 mb-1 subHeaderHeadingBgColor tableHeaderHeading text-nowrap"
                >
                  <h5
                    :class="{
                      'active-SubHeading': showOverview,
                      'inactive-SubHeading': !showOverview,
                    }"
                    @click="showDetails('Overview')"
                  >
                    Details
                  </h5>
                  <h5
                    :class="{
                      'active-SubHeading': showAccounts,
                      'inactive-SubHeading': !showAccounts,
                    }"
                    @click="showDetails('Accounts')"
                  >
                    Accounts
                  </h5>
                  <h5
                  v-if="projectId"
                  :class="{
                    'active-SubHeading': showProgress,
                    'inactive-SubHeading': !showProgress,
                  }"
                  @click="showDetails('Progress')"
                >
                  Progress
                </h5>
                  <h5
                    :class="{
                      'active-SubHeading': showAllComments,
                      'inactive-SubHeading': !showAllComments,
                    }"
                    @click="showDetails('Comments')"
                  >
                    Comments
                  </h5>
                  <h5
                    :class="{
                      'active-SubHeading': showAllActivity,
                      'inactive-SubHeading': !showAllActivity,
                    }"
                    @click="showDetails('AllActivity')"
                  >
                    Activities
                  </h5>
                </div>
                <div class="subSectionOfLeads col-md-12">
                  <div v-show="showOverview" class="sectionOther col-md-12">
                    <div class="d-flex flex-column">
                      <div>
                        <h5
                          :class="{
                            'active-SubHeading': showAddressDetails,
                            'inactive-SubHeading': !showAddressDetails,
                          }"
                          @click="showOverviewDetails('address')"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline">Address</span>
                        </section>
                        </h5>

                        <div v-show="showAddressDetails" class="row">
                          <div class="col-md-6">
                            <h5 class="purpleText">Billing Address</h5>
                            <div class="col-md-12">
                              <div class="card AddressCard">
                                <div class="card-body">
                                  <p class="card-title m-0 p-0">
                                    {{ billingAddressData.name }}
                                  </p>
                                  <p class="m-0 p-0">{{ billingAddressData.email }}</p>
                                  <p class="m-0 p-0">{{ billingAddressData.address }}</p>
                                  <p class="m-0 p-0">
                                    {{ billingAddressData.city }} &nbsp;
                                    {{ billingAddressData.state }}-
                                    {{ billingAddressData.pincode }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <h5 class="purpleText">Shipping address</h5>
                            <div class="col-md-12">
                              <div class="card AddressCard">
                                <div class="card-body">
                                  <p class="card-title m-0 p-0">
                                    {{ shippingAddressData.name }}
                                  </p>
                                  <p class="m-0 p-0">{{ shippingAddressData.email }}</p>
                                  <p class="m-0 p-0">{{ shippingAddressData.address }}</p>
                                  <p class="m-0 p-0">
                                    {{ shippingAddressData.city }} &nbsp;
                                    {{ shippingAddressData.state }}-
                                    {{ shippingAddressData.pincode }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3 mb-3">
                        <p
                          :class="{
                            'active-SubHeading': showAddressDetails,
                            'inactive-SubHeading': !showAddressDetails,
                          }"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline">Floor Plan</span>
                        </section>
                        </p>
                        <span
                        v-if="leadData['awsFiles.awsFileName']"
                        @click="
                          $viewFile(
                            leadData['awsFiles.awsFileName'],
                            leadData['awsFiles.fileName'],
                            'floorPlan',
                            'sales'
                          )
                        "
                        style="
                          min-width: 300px;
                          max-width: fit-content;
                          background-color: #f8f8f8;
                          font-weight: 500;
                          padding: 10px;
                          margin: 5px 5px;
                          display: flex;
                          border-radius: 5px;
                          border: 1px solid #2381f3;
                          cursor: pointer;
                        "
                      >
                        <!-- Display PDF image if the file type is PDF -->
                        <div>
                          <img
                            src="../../assets/icons/pdfImage.png"
                            alt=""
                            style="width: 50px; height: 50px"
                          />
                        </div>
                        <a
                          class="ms-2 mt-1"
                          target="_blank"
                          style="
                            color: inherit; /* Inherit the color from the parent */
                            text-decoration: none;
                          "
                          >{{ leadData["awsFiles.fileName"] }}</a
                        >
                        <!-- Download button aligned to the right -->
                        <div style="margin-left: auto" class="d-flex flex-column">
                        </div>
                      </span>
                      </div>
                      <div>
                        <h5
                          :class="{
                            'active-SubHeading': showRecordInfo,
                            'inactive-SubHeading': !showRecordInfo,
                          }"
                          @click="showOverviewDetails('showRecordInfo')"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline">     Record-Info</span>
                        </section>
                        </h5>
                        <div v-show="showRecordInfo" class="sectionComments">
                          <section class="d-flex flex-column">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label for="customerId">Customer ID</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="customerId"
                                  v-model="leadData.customerNumber"
                                  readOnly
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label for="projectStartDate">Project Start Date</label>
                              
                                <span class="form-control">
                                 {{ this.$formatDateDMY(leadData['projects.projectStartDate']) }} 
                                </span>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="showAllComments" class="sectionComments">
                    <div>
                      <div v-show="showComments" class="sectionComments">
                        <section class="d-flex flex-column mt-2">
                          <textarea
                            name="leadComments"
                            id="leadComments"
                            class="form-control mb-2"
                            cols="30"
                            rows="5"
                            placeholder="Add Text....."
                            v-model="this.leadComment"
                          ></textarea>
                          <button
                            class="browse align-self-end"
                            @click="sendLeadComment()"
                          >
                            Done
                          </button>
                        </section>
                      </div>
                    </div>
                    <div v-show="showOtherDetails" class="sectionOther col-md-12">
                      <div class="row">
                        <div
                          class="col-md-12"
                          style="word-break: break-all; white-space: pre-wrap"
                        >
                          <ol>
                            <li
                              v-for="(comment, index) in leadComments"
                              :key="index"
                              class="mt-3"
                            >
                              <span
                                style="
                                  font-size: 14px;
                                  word-break: break-word;
                                  white-space: pre-wrap;
                                "
                              >

                                {{ comment.comments }}
                              </span>
                              <div
                                v-if="comment.comments"
                                class="text-muted d-flex align-items-center col-md-6"
                                style="font-size: 12px; letter-spacing: 2px"
                              >
                                <span>{{ comment.created_at }}</span>

                                <!-- Add margin between elements -->
                                <span
                                  v-if="comment['employees.firstName']"
                                  style="
                                    width: 5px;
                                    height: 5px;
                                    background-color: #d3d3d3;
                                    border-radius: 50%;
                                    display: inline-block;
                                    margin: 0 8px; /* Add margin for spacing */
                                  "
                                ></span>

                                <span style="font-size: 12px; letter-spacing: 2px">
                                  {{ comment["employees.firstName"] }}
                                  {{ comment["employees.lastName"] }}
                                </span>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div v-show="showAccounts" class="sectionOther col-md-12">
                    <div class="d-flex flex-column">
                      <div>
                        <h5
                          :class="{
                            'active-SubHeading': showEstimate,
                            'inactive-SubHeading': !showEstimate,
                          }"
                          @click="showAccountDetails('showEstimate')"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline">Estimates</span>
                          <img
                          src="../../assets/icons/downArrow.png"
                          alt=""
                          v-if="showEstimate"
                            class="ms-2"
                        />
                        <img
                          src="../../assets/icons/upArrow.png"
                          alt=""
                          v-if="!showEstimate"
                            class="ms-2"
                        />
                        </section>

                          
                         
                        </h5>

                        <div v-show="showEstimate" class="sectionAddress row col-md-12">
                          <div class="table-responsive text-nowrap">
                            <table class="table tableFormat">
                              <caption class="d-none">Estimate List</caption>
                              <thead>
                                <tr>
                                  <th style="background-color: #f4f4f4">SL. No.</th>
                                  <th style="background-color: #f4f4f4">Client name</th>
                                  <th style="background-color: #f4f4f4">Date</th>
                                  <th style="background-color: #f4f4f4">Estimate No</th>
                                  <th style="background-color: #f4f4f4">Status</th>
                                  <th style="background-color: #f4f4f4">Amount</th>
                                </tr>
                              </thead>
                              <tbody v-for="(item, index) in estimateData" :key="index">
                                <tr  @click="OpenEstimate(item)">
                                  <td>{{ index + 1 }}</td>
                                  <td
                                    
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    {{ item.clientName }}
                                  </td>
                                  <td>{{ item.date }}</td>
                                  <td>{{ item.estimateId }}</td>
                                  <td>{{ item.status }}</td>
                                  <td class="text-right">{{ item.grandTotal }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h5
                          :class="{
                            'active-SubHeading': showSalesOrder,
                            'inactive-SubHeading': !showSalesOrder,
                          }"
                          @click="showAccountDetails('showSalesOrder')"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline">SalesOrders</span>
                          <img
                          src="../../assets/icons/downArrow.png"
                          alt=""
                          v-if="showSalesOrder"
                          class="ms-2"
                        />
                        <img
                          src="../../assets/icons/upArrow.png"
                          alt=""
                          v-if="!showSalesOrder"
                            class="ms-2"
                        />
                        </section>
                          
                         
                        </h5>

                        <div v-show="showSalesOrder" class="sectionAddress row col-md-12">
                          <div class="table-responsive text-nowrap">
                            <table class="table tableFormat">
                              <caption class=" d-none">SalesOrder List</caption>
                              <thead>
                                <tr>
                                  <th style="background-color: #f4f4f4">SL.No</th>
                                  <th style="background-color: #f4f4f4">Client Name</th>
                                  <th style="background-color: #f4f4f4">Date</th>
                                  <th style="background-color: #f4f4f4">SalesOrder No</th>
                                  <th style="background-color: #f4f4f4">Estimate No</th>
                                  <th style="background-color: #f4f4f4">Status</th>
                                  <th style="background-color: #f4f4f4">Amount</th>
                                </tr>
                              </thead>
                              <tbody v-for="(item, index) in SalesOrdersData" :key="index">
                                <tr  @click="OpensalesOrder(item)">
                                  <td>{{ index + 1 }}</td>
                                  <td
                                   
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    {{ item.clientName }}
                                  </td>
                                  <td>{{ item.salesOrderDate }}</td>
                                  <td>{{ item.salesOrderNumber }}</td>
                                  <td>{{ item['estimates.estimateId'] }}</td>

                                  <td>{{ item.status }}</td>
                                  <td class="text-right">{{ item.grandTotal }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="showAllActivity" class="sectionComments">
                    <ol>
                      <!-- Iterate over lead activities -->
                      <li
                        v-for="(activity, index) in leadActivities"
                        :key="index"
                        class="d-flex align-content-center position-relative"
                      >
                        <img
                          :src="activity['employees.signedUrl']"
                          alt=""
                          v-if="activity['employees.signedUrl']"
                          style="
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                            z-index: 2;
                            border: 2px solid #ccc;
                            border-radius: 50%;
                          "
                          class="m-2 mt-2"
                        />

                        <img
                          src="../../assets/icons/profile.png"
                          alt=""
                          v-else
                          style="width: 20px; height: 20px; margin-right: 5px; z-index: 2"
                          class="m-2 mt-2"
                        />

                        <div class="d-flex align-content-center flex-column m-2">
                          <span style="font-size: 12px; font-weight: 500"
                            >{{ activity["employees.firstName"] }}
                            {{ activity["employees.lastName"] }} {{ activity.text }}</span
                          >

                          <!-- Display formatted date -->
                          <span
                            style="font-size: 10px; word-spacing: 20px"
                            class="text-muted"
                            >{{ activity.created_at }}</span
                          >
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div v-show="showProgress" class="sectionComments">
                    <div class="d-flex flex-column">
                      <div class="mt-2" >
                        <h5
                          :class="{
                            'active-SubHeading': showDesign,
                            'inactive-SubHeading': !showDesign,
                          }"
                          @click="showProgressDetails('showDesign')"
                        
                        >
                          <section class="floor-plan-container">
                            <span class="blue-dot"></span>
                            <span class="text-decoration-underline">Design Team</span>
                            <img
                              src="../../assets/icons/downArrow.png"
                              alt="Down Arrow"
                              v-if="showDesign"
                              class="ms-2"
                            />
                            <img
                              src="../../assets/icons/upArrow.png"
                              alt="Up Arrow"
                              v-if="!showDesign"
                              class="ms-2"
                            />
                          </section>
                        </h5>
                      
                        <div v-show="showDesign" class="card-content card-container">
                          <p>{{ projectData.projectName }}</p>
                          <div class="grid-container">
                            <div class="grid-item">
                              <strong>Designer</strong>
                              <span class="employee-info">
                                <img
                                  :src="projectData['employees.signedUrl']"
                                  alt="Designer"
                                  class="avatar"
                                  style="
                                  width: 20px;
                                  height: 20px;
                                  border-radius: 50%;
                                  cursor: pointer;
                                  background-color: aliceblue;
                                "
                                />
                                <span>{{ projectData['employees.firstName'] }} {{ projectData['employees.lastName'] }}</span>
                              </span>
                            </div>
                        
                    
                            <div class="grid-item">
                              <strong>Start Date</strong>
                              <span>{{ $formatDateDMY(projectData.designStartDate) }}</span>
                            </div>
                            <div class="grid-item">
                              <strong>End Date</strong>
                              <span>{{ $formatDateDMY(projectData.designEndDate) }}</span>
                            </div>
                            <div class="grid-item">
                              <strong>Collaborator</strong>
                              <div class="d-flex justify-content-start align-items-center">
                                <div class="collaborators-container">
                                  <div
                                    v-for="(collaborator, index) in projectData.collaborators.slice(0, 3)"
                                    :key="index"
                                    class="collaborator-avatar"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      v-if="collaborator['employees.signedUrl']"
                                      :src="collaborator['employees.signedUrl']"
                                      class="avatar"
                                      style="
                                      width: 20px;
                                      height: 20px;
                                      border-radius: 50%;
                                      cursor: pointer;
                                      background-color: aliceblue;
                                    "
                                      :alt="collaborator['employees.initials']"
                                    />
                                    <span v-else class="initials"    style="
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    background-color: aliceblue;
                                  ">{{
                                      collaborator['employees.initials']
                                    }}</span>
                                  </div>
                        
                                  <div v-if="projectData.collaborators.length > 3" class="additional-count">
                                    + {{ projectData.collaborators.length - 3 }}
                                  </div>
                        
                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <div
                                      v-for="(employee, employeeIndex) in projectData.collaborators"
                                      :key="employeeIndex"
                                      class="dropdown-item"
                                    >
                                      <span class="d-flex text-nowrap">
                                        {{ employee['employees.firstName'] }}
                                        {{ employee['employees.lastName'] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="grid-item">
                              <strong>Current Stage</strong>
                              <button type="button"  
                              class="btn btn-sm"
                              style="cursor: pointer; background-color: purple; color: white;font-size:14px;">{{ projectData.designStage }}</button>

                            </div>
                          
                            <div class="grid-item">
                              <strong>Sign Off Document</strong>
                             <span>
                              <img
                              v-if="projectData.signOffDoc.length > 0"
                              class="ms-3"
                              src="../../assets/icons/signOffDocumentFolder.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownSignOffDocumentButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownSignOffDocumentButton"
                          >
                            <ol
                              v-for="(folder, index) in projectData.signOffDoc"
                              :key="index"
                              style="padding: 0px 10px"
                            >
                              <li
                                class="d-flex justify-content-between align-items-center"
                                style="font-size: 14px"
                              >
                                <span class="text-start mt-1">
                                  {{ folder["files.awsFiles.fileName"] }}
                                </span>
                                <span class="text-end ms-3">
                                  <img
                                    src="../../assets/icons/pinkDownold.png"
                                    alt=""
                                    style="width: 17px; height: 17px"
                                    @click="
                                      $downloadFile(
                                        folder['files.awsFiles.awsFileName'],
                                        folder['files.awsFiles.fileName'],
                                        'signOffDocument',
                                        'design'
                                      )
                                    "
                                  />
      
                                  <img
                                    @click="
                                      $viewFile(
                                        folder['files.awsFiles.awsFileName'],
                                        folder['files.awsFiles.fileName'],
                                        'signOffDocument',
                                        'design'
                                      )
                                    "
                                    class="ms-1"
                                    src="../../assets/icons/view.png"
                                    alt=""
                                    style="width: 17px; height: 17px"
                                  />
                                </span>
                              </li>
                            </ol>
                          </div>
                             </span>
                            </div>
                          </div>
                        </div>
                        
                        
                      </div>
                      
                      <div class="mt-2" >
                        <h5
                          :class="{
                            'active-SubHeading': showProcurement,
                            'inactive-SubHeading': !showProcurement,
                          }"
                          @click="showProgressDetails('showProcurement')"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline"> Procurement </span>
                          <img
                          src="../../assets/icons/downArrow.png"
                          alt=""
                          v-if="showProcurement"
                          class="ms-2"
                        />
                        <img
                          src="../../assets/icons/upArrow.png"
                          alt=""
                          v-if="!showProcurement"
                            class="ms-2"
                        />
                        </section>
                          
                         
                        </h5>

                        <div v-show="showProcurement" class="sectionAddress row col-md-12">
                          <div class="table-responsive text-nowrap">
                            <table class="table tableFormat">
                              <caption class=" d-none">Table List</caption>
                              <thead>
                                <tr>
                                  <th>S/N</th>
                                  <th>PO Number</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th class="text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody v-for="(row, index) in PurchaseOrders" :key="index" >
                                <tr >
                                  <td>{{ index + 1 }}</td>
                                  <td class="text-nowrap text-left">
                                    {{ row.poNumber }}
                                  </td>
                                 
                                  <td>
                                    {{ $formatDateDMY(row.date) }}
                                  </td>
                               
                                  <td :class="statusClass(row.poStatus)">  
                                   
        
                                  <span   :class="statusClass(row.poStatus)"> {{ row.poStatus }}</span></td>
          
                                  <td class="text-center">
                                    <div class="ActionImage">
                                      <!-- <span
                                        @click="openPOModal(row.id)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#PODetailsModal"
                                      >
                                        <img
                                          src="../../assets/icons/view.png"
                                          data-toggle="tooltip"
                                          data-placement="top-start"
                                          title="Edit"
                                          alt=""
                                        />
                                      </span> -->
                                      <span>
                                        <img
                                          @click="handleDownloadPO(row.id)"
                                          src="../../assets/icons/pinkDownold.png"
                                          data-toggle="tooltip"
                                          data-placement="top-start"
                                          title="downold"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2" >
                        <h5
                          :class="{
                            'active-SubHeading': showOnsite,
                            'inactive-SubHeading': !showOnsite,
                          }"
                          @click="showProgressDetails('showOnsite')"
                        >
                        <section class="floor-plan-container">
                          <span class="blue-dot"></span>
                          <span class="text-decoration-underline">Onsite Team</span>
                          <img
                          src="../../assets/icons/downArrow.png"
                          alt=""
                          v-if="showOnsite"
                          class="ms-2"
                        />
                        <img
                          src="../../assets/icons/upArrow.png"
                          alt=""
                          v-if="!showOnsite"
                            class="ms-2"
                        />
                        </section>
                          
                         
                        </h5>

                        <div v-show="showOnsite" class="card-content card-container">
                          <p>{{ projectData.projectName }}</p>
                          <div class="progress-container">
                            <!-- Left Part: Progress Bar and Button -->
                            <div class="left-part">
                              <div class="progress-bar-container">
                                <label class=" d-flex justify-content-end" for="progressBar">
                                <strong>{{ onsiteProjectData.percentage }}%</strong></label>
                                <div class="progress-bar">
                                  
                                  <div
                                    class="progress-bar-fill"
                                    :style="{ width: onsiteProjectData.percentage + '%' }"
                                  ></div>
                                </div>
                               <div class=" d-flex justify-content-between mt-2">
                                 <span> {{onsiteProjectData.completedDuration / onsiteProjectData.totalPlannedDuration }}</span>
                                 <button type="button"  
                                 class="btn btn-sm"
                                 style="cursor: pointer; background-color: purple; color: white;font-size:14px;">{{ onsiteProjectData.projectStatus}}</button>
                               
                                </div>
                              </div>
                              <button class="progress-button btn btn-sm"  @click="showTimeline()" >
                                Project Milestone Timeline
                                <span>
                                <img
                                src="../../assets/icons/downArrow.png"
                                alt=""
                                v-if="showTimeLine"
                                class="ms-2"
                              />
                              <img
                                src="../../assets/icons/upArrow.png"
                                alt=""
                                v-if="!showTimeLine"
                                  class="ms-2"
                              />
                            </span>
                              </button>
                            </div>
                          
                            <!-- Right Part: Labels and Inputs -->
                            <div class="right-part">
                              <div class="form-group">
                                <label for="plannedStartDate"><strong>Planned Start Date</strong></label>
                                <span>{{ this.$formatDateDMY(onsiteProjectData?.dates?.plannedStartDate) || 'N/A' }}</span>
                              </div>
                              <div class="form-group">
                                <label for="plannedEndDate"><strong>Planned End Date</strong></label>
                                <span>{{ this.$formatDateDMY(onsiteProjectData?.dates?.plannedEndDate) || 'N/A' }}</span>
                              </div>
                              <div class="form-group">
                                <label for="actualStartDate"><strong>Actual Start Date</strong></label>
                                <span>{{ this.$formatDateDMY(onsiteProjectData?.dates?.actualStartDate) || 'N/A' }}</span>
                              </div>
                              <div class="form-group">
                                <label for="actualEndDate"><strong>Actual End Date</strong></label>
                                <span>{{ this.$formatDateDMY(onsiteProjectData?.dates?.actualEndDate) || 'N/A' }}</span>
                              </div>
                            </div>
                            
                                           
                   
                          </div>
           
                          <div v-show="showTimeLine" class="sectionAddress row col-md-12">
                            <div class="table-responsive text-nowrap">
                              <table class="table tableFormat">
                                <caption class=" d-none">Table List</caption>
                                <thead>
                                  <tr>
                                    <th>S/N</th>
                                    <th>Activity Name</th>
                                    <th>Assignee</th>
                                    <th>Planned Start date</th>
                                    <th>Planned End Date</th>
                                    <th>Actual Start Date</th>
                                    <th>Actual End Date</th>
                                  </tr>
                                </thead>
                                <tbody  >
                                  <tr v-for="(row, index) in timelineRows" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td class="text-nowrap text-left">
                                      <span class="mb-1">
                                        <img
                                          src="../../assets/icons/taskCompleted.png"
                                          alt="task pending"
                                          v-if="row.actualEndDate"
                                          style="width: 17px; height: 17px"
                                        />
                                        <img
                                          v-else
                                          src="../../assets/icons/taskPending.png"
                                          alt="task completed"
                                          style="width: 17px; height: 17px"
                                        />
                                      </span>
                                      <span
                                        v-if="row.actualEndDate > row.plannedEndDate"
                                        class="ms-1 text-danger"
                                        @click="openAddActivityModal(row.id)"
                                      >
                                        {{ row.activity }}
                                      </span>
          
                                      <span
                                        v-else
                                        class="ms-1"
                                        @click="openAddActivityModal(row.id)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#AddDesignActivity"
                                        data-bs-whatever="@mdo"
                                      >
                                        {{ row.activity }}
                                      </span>
                                    </td>
                                    <td>
                                      {{ row["employees.firstName"] }}
                                      {{ row["employees.lastName"] }}
                                    </td>
                                    <td>
                                      {{ $formatDateDMY(row.plannedStartDate) }}
                                    </td>
                                    <td>
                                      {{ $formatDateDMY(row.plannedEndDate) }}
                                    </td>
          
                                    <td>
                                      {{ $formatDateDMY(row.actualStartDate) }}
                                    </td>
                                    <td>
                                      {{ $formatDateDMY(row.actualEndDate) }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Modal body content goes here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PODetailsModal :PurchaseOrderId="selectedPoId" ref="PODetailsModal" />
</template>

<script>
import CustomerCreateModal from "./CustomerCreateModal.vue";
import PODetailsModal from "../Modal/PODetailsModal.vue";
import axios from "axios";
export default {
  name: "CustomerDetailsModal",
  props: {
    selectedLeadId: {
      type: String, // Change the type according to your ID data type
      required: true,
    },
    projectId:
   {
    type: String, // Change the type according to your ID data type
    required: true,
   }
  },
  components: {
    CustomerCreateModal,
    PODetailsModal,
  },
  data() {
    return {
      actions: [
        "Claimed",
        "ContactMode",
        "Meeting",
        "Estimate",
        "Negotiation",
        "Sold",
        "Lost",
        "Trash",
      ],
      
      leadActivities: [], // Store lead activities
      showOverview: true,
      showAccounts: false,
      showProgress:false,
      showAllComments: false,
      showAllActivity: false,
      showOtherDetails: true,
      showAddressDetails: true,
      showRecordInfo: true,
      showTimeLine:false,
      showComments: true,
      showEstimate: true,
      showDesign:true,
      showProcurement:false,
      showOnsite:false,
      showSalesOrder: true,
      leadComment: "",
      leadComments: [],
      estimateData: [],
      LeadId: null,
      SelectedLeadId: this.selectedLeadId,
      leadData: {}, // To store lead details
      SalesOrdersData: [],
      billingAddressData: {}, // To store billing address
      shippingAddressData: {}, // To store shipping address
      projectData:{
        collaborators: [],
        signOffDoc:[],
      },
      PurchaseOrders: [],
      selectedPoId:null,
      onsiteProjectData :{
        percentage: 0, 
      plannedStartDate: "",
      plannedEndDate: "",
      actualStartDate: "",
      actualEndDate: "",
      projectStatus:"",
      completedDuration:"",
      totalPlannedDuration:"",

      },
      timelineRows:[],
    };
  },
  mounted() {
    if (this.selectedLeadId) {
      this.fetchLeadDetails();
    }
  },
  watch: {
   
    projectId(newValue)
    {
      if(newValue)
      this.featchDesignProjectData();
    this.fetchpurchaseOrder();
    this.featchOnsiteProjectData();
    this.fetchTimeLineData();
  
    },
    selectedLeadId(newValue) {
      if (newValue) {
        this.fetchLeadDetails();
      }
    }
  },

  methods: {
    statusClass(status) {
      switch (status) {
        case "Approved":
          return "approvedText";
        case "Semi Ordered":
          return "semi-deliveredText";
        case "Ordered":
          return "orderedText";
        case "Delivered":
          return "deliveredText";
        case "Rejected":
          return "rejectedText";
        case "Pending":
          return "pendingText";
        case "Payment Pending":
          return "pendingText";
        case "Payment Done":
          return "approvedText";
        default:
          return "";
      }
    },
    optionStyle(status) {
      switch (status) {
        case "Payment Pending":
          return "color: #ffd159;"; // Yellow color
        case "Payment Done":
          return "color: #38b525;"; // Green color
        case "Delivered":
          return "color: #32587a;"; // Dark blue color
        case "Approved":
          return "color: #38b525;"; // Green color
        case "Semi Ordered":
          return "color: #6d756b;"; // Gray color
        case "Ordered":
          return "color: #2381f3;"; // Blue color
        case "Semi Delivered":
          return "color: #6d756b;"; // Gray color

        default:
          return "";
      }
    },
    async fetchTimeLineData() {
  try {
    const response = await axios.get(
      `${this.$apiEndPoint}/onSiteScopeOfWork/timelineByProjectId/${this.projectId}`
    );
    this.timelineRows = response.data;

    if (this.timelineRows.length !== 0) {
      const plannedData = [];
      const actualData = [];
      const delayData = [];

      this.timelineRows.forEach((row) => {
        const plannedStart = new Date(row.plannedStartDate).getTime();
        const plannedEnd = new Date(row.plannedEndDate).getTime();

        const actualStart = row.actualStartDate
          ? new Date(row.actualStartDate).getTime()
          : null;
        const actualEnd = row.actualEndDate
          ? new Date(row.actualEndDate).getTime()
          : null;

        plannedData.push({
          x: row.activity,
          y: [plannedStart, plannedEnd],
        });

        // Only push to actualData if both actualStartDate and actualEndDate are provided
        if (actualStart !== null && actualEnd !== null) {
          actualData.push({
            x: row.activity,
            y: [actualStart, actualEnd],
          });

          // Check for delay only if plannedEnd is less than actualEnd
          if (plannedEnd < actualEnd) {
            delayData.push({
              x: row.activity,
              y: [plannedEnd, actualEnd],
            });
          }
        }
      });

      this.series = [
        {
          name: "Planned",
          data: plannedData,
          color: "#0000FF", // Blue
        },
        {
          name: "Actual",
          data: actualData,
          color: "#008000", // Green
        },
        {
          name: "Delay",
          data: delayData,
          color: "#FF0000", // Red
        },
      ];
    }
  } catch (error) {
    this.$handleError(error);
  }
},
    showTimeline()
    {
  
this.showTimeLine =!this.showTimeLine;
    },
    updateProgress() {
      this.percentage = (this.percentage + 10) % 110; // Example logic to update progress
    },
    openPOModal(Id) {
      this.selectedPoId = Id;
    },

    handleDownloadPO(id) {
      this.selectedPoId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PODetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    async fetchpurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/getByProjectId/${this.projectId}`
        );
        this.PurchaseOrders = response.data;
     
      } catch (error) {
        this.$handleError(error);
      }
    },
    getDownloadFilename(url) {
      return url;
    },
    featchLeadfloorPlan(LeadId) {
      const PayLoad = {
        entityId: LeadId,
        fileCategory: "floorPlan",
        moduleName: "sales",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.floorPlan = Object.values(response.data)[0];
          this.floorPlanfileName = Object.keys(response.data)[0]; // Extract the key (filename) from the object
          this.getDownloadFilename(this.floorPlanfileName); // Pass the filename to your method
        
        })
        .catch(() => {
         
        });
    },
    showDetails(section) {
      this.showOverview = false;
      this.showAccounts = false;
      this.showAllActivity = false;
      this.showAllComments = false;
      this.showProgress=false;

      // Set the specific section to true based on the button clicked
      if (section === "Overview") {
        this.showOverview = true;
      } else if (section === "Accounts") {
        this.showAccounts = true;
      } else if (section === "AllActivity") {
        this.showAllActivity = true;
      } else if (section === "Comments") {
        this.showAllComments = true;
      }else if (section === "Progress") {
        this.showProgress = true;
      }
    },
    showOverviewDetails(section) {

      if (section === "address") {
        this.showAddressDetails = !this.showAddressDetails;
      } else if (section === "other") {
        this.showOtherDetails = !this.showOtherDetails;
      } else if (section === "showRecordInfo") {
        this.showRecordInfo = !this.showRecordInfo;
      } else if (section === "comments") {
        this.showComments = !this.showComments;
      }
    },
    showAccountDetails(section) {
      if (section === "showEstimate") {
        this.showEstimate = !this.showEstimate;
      } else if (section === "showSalesOrder") {
        this.showSalesOrder = !this.showSalesOrder;
      } else if (section === "showRecordInfo") {
        this.showRecordInfo = !this.showRecordInfo;
      } else if (section === "comments") {
        this.showComments = !this.showComments;
      }
    },
    showProgressDetails(section) {
      if (section === "showDesign") {
        this.showDesign = !this.showDesign;
      } else if (section === "showProcurement") {
        this.showProcurement = !this.showProcurement;
      } else if (section === "showOnsite") {
        this.showOnsite = !this.showOnsite;
      } 
    },
    OpenEstimate(estimate) {
  const estimateId = estimate?.estimateId;
  const status = estimate?.status;

  if (estimateId) {
    if (["Draft", "NULL", ""].includes(status)) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          EstimateId: estimateId,
          estimateIndex: estimate.id,
        },
      });
    } else if (["Sent", "Approved", "Finalized"].includes(status)) {
      this.$router.push({
        name: "FinalEstimate",
        params: {
          EstimateId: estimateId,
          estimateIndex: estimate.id,
        },
      });
    } else {
      // Handle the scenario for other status values
    }
  } else {
    // Handle the scenario where estimate or estimateId is undefined
  }
},

    OpensalesOrder(salesOrder) {
      this.$router.push({
        name: "CreateSalesOrder",
        params: {
          SalesOrderNumber: salesOrder.salesOrderNumber,
          SalesOrderId: salesOrder.id,
        },
      });
    },
    async fetchLeadComments(leadId) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/leadsComments/${leadId}`);
        this.leadComments = response.data ; // Assuming comments are returned as an array under 'comments' key
      } catch (error) {
        this.$handleError(error);
        this.leadComments = []; // Set to empty array or handle error state
      }
    },


    async sendLeadComment() {
      const currentDateTime = new Date();
      const formattedDateTime =
        currentDateTime
          .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(/\s/g, "") +
        " " +
        currentDateTime.toLocaleDateString("en-GB").replace(/\//g, "-");

      const payload = {
        leadId: this.leadData.leadId,
        comments: this.leadComment,
        created_at: formattedDateTime,
        "employees.firstName": this.$LocalUser.user.firstName,
        "employees.lastName": this.$LocalUser.user.lastName,
      };

      this.tempComment = payload; // Define this.tempComment before the try block

      try {
        this.leadComment = "";
        // Store comment temporarily in case the API call fails
        this.leadComments.unshift(this.tempComment); // Optimistically add comment as the first item

        await axios.post(`${this.$apiEndPoint}/leadsComments`, payload);

        // Clear the input field if API call is successful
        this.fetchLeadComments(this.leadData.leadId);
        this.fetchLeadActivity(this.leadData.leadId);
      } catch (error) {
        this.$handleError(error);

        this.leadComments.shift(this.tempComment);

        if (this.tempComment) {
          this.leadComment = this.tempComment.comments;
        }
      }
    },
    async  featchDesignProjectData()
    {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/designProgress/${this.projectId}`
        );
        this.projectData = response.data;
      

      
      } catch (error) {
        this.$handleError(error); 
        // Show an error message to the user
      }
    },
    async  featchOnsiteProjectData()
    {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteScopeOfWork/timelineProgress/${this.projectId}`
        );
        this.onsiteProjectData = response.data;
      

      
      } catch (error) {
        this.$handleError(error); 
        // Show an error message to the user
      }
    },
    
 
    // Fetch lead details including addresses, activities, comments, etc.
    async fetchLeadDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/customer/getCustomer/${this.selectedLeadId}`
        );
        this.leadData = response.data;
        this.LeadId = this.leadData.leadId;

        // Fetch additional lead data in parallel
        await Promise.all([
          this.fetchLeadAddresses(this.leadData.id),
          this.fetchLeadActivity(this.leadData.leadId),
          this.fetchSalesOrder(),
          this.fetchEstimate(),
          this.fetchLeadComments(this.leadData.leadId),
          this.featchLeadfloorPlan(this.leadData.leadId)  // assuming this method exists
        ]);
      } catch (error) {
        this.$handleError(error); 
        // Show an error message to the user
      }
    },

    async fetchLeadActivity(leadId) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/leadActivity/${leadId}`);
        this.leadActivities = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEstimate() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/estimates/byCustomer/${this.selectedLeadId}`);
        this.estimateData = response.data.map((estimate) => ({
          ...estimate,
          clientName: this.leadData.name,
        }));
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchSalesOrder() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/salesOrder/getByCustomerId/${this.selectedLeadId}`);
        this.SalesOrdersData = response.data.map((order) => ({
          ...order,
          clientName: this.leadData.name,
        }));
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchData() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/customer/`);
        this.TableData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchLeadAddresses(leadId) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/address/Customer/${leadId}`);
        const addresses = response.data;

        const billingAddress = addresses.find((addr) => addr.addressType === "Billing");
        const shippingAddress = addresses.find((addr) => addr.addressType === "Shipping");

        if (billingAddress) {
          this.billingAddressData = {
            name: billingAddress.name,
            address: billingAddress.address,
            city: billingAddress.city,
            state: billingAddress.state,
            country: billingAddress.country,
            pincode: billingAddress.pincode,
            contactNo: billingAddress.contactNo,
            email: billingAddress.email,
          };
        }

        if (shippingAddress) {
          this.shippingAddressData = {
            name: shippingAddress.name,
            address: shippingAddress.address,
            city: shippingAddress.city,
            state: shippingAddress.state,
            country: shippingAddress.country,
            pincode: shippingAddress.pincode,
            contactNo: shippingAddress.contactNo,
            email: shippingAddress.email,
          };
        }
      } catch (error) {
        this.$handleError(error);
      }
    }
  }
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

select {
  width: auto;
  outline: none;
  background-color: transparent;
  text-align: left;
  padding: 6px 8px;

  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 8px 8px 0px;
  /* Add padding to the select */
}

/* Style the options within the dropdown */
select option {
  text-align: left;
  padding: 8px;
  /* Add padding to each option */
  margin-bottom: 5px;
  /* Add margin between options */
  background-color: #ffffff;
  /* Optional: Background color for options */
  color: #333333;
  /* Optional: Text color for options */
}

#transactionDetails {
  border: 2px solid #ccc;
  outline: none;
  margin-top: 10px;
}

.transaction-details {
  position: fixed;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  height: auto;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.comment-marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: blue;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

.LeadDetaisForm {
  min-width: 100%;
  margin-left: 24%;
  font-size: 16px;

  color: #000000;

  height: 100%;
  overflow-y: scroll;
}

.modal-content {
  height: 100%;
  /* Ensure modal content fills the height */
}

.AddressCard {
  height: 150px;
  background-color: #f4f4f4;
  border: none;
}

.subHeadingsOfLeads h5 {
  cursor: pointer;
}

.subHeadingsOfLeads h5:not(:first-child) {
  margin: 0 5%;
}

.subHeadingsOfLeads h5:first-child {
  margin-right: 5%;
}

.subHeadingsOfLead {
  display: flex;
  align-content: center;
}

.subHeadingsOfLead h5 {
  margin-bottom: 0 !important;
}

.subSectionOfLeads img {
  width: 10px;
}

/* .dotted-vertical-line {
  border-right: 2px dotted #000; 
  margin-right: 8px; /
  height: 100%; /* 

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
} */

.text-decoration-underline
{
  letter-spacing: 1px;
  font-size: 14px;
}
.card-container {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  margin: 16px 0;
  font-size: 14px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

strong {
  color: #333;
  font-weight: bold;
}

.employee-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.profile-imgg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: aliceblue;
  object-fit: cover;
  cursor: pointer;
}

/* Container to hold all collaborator avatars */
.collaborators-container {
  display: flex;
  align-items: center;
  position: relative;
}

/* Style for collaborator avatars */
.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -8px; /* Adjust for overlap */
  z-index: 2;
  cursor: pointer;
}

/* Style for additional count circle */
.additional-count {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #b1c5df;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px; /* Adjusted for readability */
  margin-left: 8px;
  cursor: pointer;
  z-index: 1;
}


.tableFormat th ,
.tableFormat td{
  font-size: 12px !important;
}

.ActionImage img {
  padding: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  white-space: nowrap;
}

.ActionImage img:hover {
  background-color: #868686;
  border-radius: 50%;
}
.progress-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.left-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.progress-bar-container {
  width: 100%;
  margin-bottom: 10px;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 5px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px 0 0 10px;
  transition: width 0.3s ease;
}

.progress-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 14px;
}

.progress-button:hover {
  background-color: #0056b3;
}

.right-part {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}


button
{
  font-size: 14px;
}

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .transaction-details {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 300px;
    height: auto;
  }

  .headerbuttons {
    overflow-x: scroll;
  }

  .LeadDetaisForm {
    max-width: 100%;
    /* Full width on mobile */
    margin-left: 0;

    /* Reset margin for mobile */
  }

  .subHeadingsOfLeads h5:nth-child(2) {
    margin: 0 3%;
  }
}
</style>

<!-- showOverviewDetails(section) {
  /*   this.showOtherDetails = false;
    this.showAddressDetails = false;
    this.showComments = false;  */
    if (section === "address") {
      this.showAddressDetails = !this.showAddressDetails;
    } else if (section === "other") {
      this.showOtherDetails = true;
    } else if (section === "comments") {
      this.showComments = true;
    }
  }, -->

<!-- showOverviewDetails(section) {
    /*   this.showOtherDetails = false;
      this.showAddressDetails = false;
      this.showComments = false;  */
      if (section === "address") {
        this.showAddressDetails = !this.showAddressDetails;
      } else if (section === "other") {
        this.showOtherDetails = true;
      } else if (section === "comments") {
        this.showComments = true;
      }
    }, -->
