<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="container-child">
        <div class="stock-report">
          <header class="report-header">
            <div class="report-title">
              <h3>{{ storeName }}</h3>
              <p class="stock-name">
                Stock Name - <strong>{{ productName }}</strong>
              </p>
            </div>
            <div>
              <div class="report-date">
                <label for="fromYearSelect" class="report-label">Report:</label>
                <select
                  id="fromYearSelect"
                  v-model="fromYear"
                  @change="updateToYear"
                  class="custom-select"
                >
                  <option v-for="year in availableYears" :key="year" :value="year">
                    {{ `1, Apr ${year}` }}
                  </option>
                </select>
                <span class="report-label">to</span>
                <span class="date-display">{{ `31, Mar ${toYear}` }}</span>
              </div>
            </div>

            <div class="export-buttons">
              <!-- Download Icon -->
              <div class="download-wrapper">
                <img
                  src="../../assets/icons/pinkDownold.png"
                  class="download-icon"
                  alt="Download"
                  @click="toggleDownloadOptions"
                />
                <ul v-show="showDownloadOptions" class="download-options">
                  <li @click="exportToCSV()">Excel</li>
                  <!-- <li @click="exportToCSV()">PDF</li> -->
                </ul>
              </div>

              <!-- Close Icon -->
              <img
                src="../../assets/icons/cancelIcon.png"
                class="close-icon"
                alt="Close"
                onclick="window.history.back()"
              />
            </div>
          </header>

          <table class="report-table">
            <thead>
              <tr>
                <th>Particular</th>
                <th>Inward Qty</th>
                <th>Outward Qty</th>
                <th>Stock Consumption</th>
                <th>Closing Stock</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Opening Stock</td>
                <td style="display: flex; justify-content: center; margin-right: -28%">
                  {{ productStock.openingStock }}
                </td>
              </tr>
              <tr v-for="(row, index) in productStock.rows" :key="index">
                <td>{{ row.monthName }}</td>
                <td>{{ row.inwardQuantity }}</td>
                <td>{{ row.outwardQuantity }}</td>
                <td>{{ row.stockConsumption }}</td>
                <td>{{ row.closingStock }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="1">Grand Total</td>
                <td>{{ productStock.totalInwardQuantity }}</td>
                <td>{{ productStock.totalOutwardQuantity }}</td>
                <td></td>
                <td>{{ productStock.totalClosingStock }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <ReportDateForm @applyDateRange="handleDateRange" />
    </div>
  </div>
</template>

<script>
import ReportDateForm from "../Modal/ReportDateForm.vue";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  data() {
    return {
      allData: [],
      storeName: this.$route.params.storeName,
      productName: this.$route.params.productName,
      showDownloadOptions: false, // Toggles the visibility of the dropdown
      productStock: [],
      fromMonth: 4,
      toMonth: 3,
      fromYear: "",
      toYear: "",
      availableYears: this.generateAvailableYears(),
    };
  },

  components: {
    Header,
    Nav,
    ReportDateForm,
  },

  mounted() {
    this.storeId = this.$route.params.storeId;
    this.productId = this.$route.params.productId;
    this.fetchProductStock(this.storeId, this.productId);
  },

  methods: {
    exportToCSV() {
      // Ensure productStock is an array
      if (!Array.isArray(this.productStock.rows) || this.productStock.rows.length === 0) {
        console.error("productStock is not a valid array.");
        return;
      }

      // Combine data
      const allData = this.productStock.rows;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        Month: data.monthName || "-",
        Inward_Qty: data.inwardQuantity || "-",
        Outward_Qty: data.outwardQuantity || "-",
        Stock_Consumption: data.stockConsumption || "-",
        Closing_Stock: data.closingStock || "-",
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    formatAddress(address) {
      // Replace newline characters with commas
      return address ? address.replace(/\n/g, ", ") : "N/A";
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    toggleDownloadOptions() {
      this.showDownloadOptions = !this.showDownloadOptions;
    },

    generateAvailableYears() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 10; // Adjust the range of years as needed
      return Array.from({ length: 11 }, (_, i) => startYear + i);
    },
    updateToYear() {
      this.toYear = this.fromYear + 1;
      console.log(this.toYear);
      console.log(this.fromYear);
      // Fetch the updated product stock data
      this.fetchProductStock(this.storeId, this.productId);
    },

    async fetchProductStock(storeId, productId) {
      try {
        const requestBody = {
          fromMonth: this.fromMonth,
          toMonth: this.toMonth,
          fromYear: this.fromYear,
          toYear: this.toYear,
        };
        console.log(requestBody);
        const response = await axios.post(
          `${this.$apiEndPoint}/productStock/${storeId}/${productId}`,
          requestBody
        );

        console.log("Product Stock Data:", response.data);

        const updatedRows = response.data.rows.map((row) => ({
          ...row,
          monthName: new Date(0, row.month - 1).toLocaleString("en-US", {
            month: "long",
          }), // Convert numeric month to name
        }));

        console.log("Product Stock Data with Month Names:", updatedRows);

        this.productStock = {
          ...response.data,
          rows: updatedRows,
        };
        console.log("Product Stock Data is coming:", this.productStock);
      } catch (error) {
        console.error("Error fetching product stock:", error);
      }
    },
    updateFY() {
      // Call API whenever FY changes
      this.fetchProductStock(this.storeId, this.productId);
    },

    // async fetchProductStockWithFilters(fromMonth, toMonth, fromYear, toYear) {
    //   try {
    //     const response = await axios.post(
    //       `${this.$apiEndPoint}/productStock/${this.storeId}/${this.productId}`,
    //       {
    //         fromMonth,
    //         toMonth,
    //         fromYear,
    //         toYear,
    //       }
    //     );

    //     this.processProductStockData(response.data);
    //   } catch (error) {
    //     console.error("Error fetching product stock with filters:", error);
    //   }
    // },

    // processProductStockData(data) {
    //   const monthNames = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    //   ];

    //   // Convert month numbers to month names in rows
    //   const updatedRows = data.rows.map((row) => ({
    //     ...row,
    //     monthName: monthNames[row.month - 1], // Adjust index (month is 1-based)
    //   }));

    //   // Update productStock with processed data
    //   this.productStock = {
    //     ...data,
    //     rows: updatedRows,
    //   };
    // },

    // // Handle the date range and fetch filtered data
    // handleDateRange(dateRange) {
    //   const { fromMonth, toMonth, fromYear, toYear } = dateRange;
    //   console.log(fromMonth, toMonth, fromYear, toYear);

    //   // Call function to fetch data with filters
    //   this.fetchProductStockWithFilters(fromMonth, toMonth, fromYear, toYear);
    // },
  },
};
</script>

<style scoped>
.report-date {
  display: flex;
  align-items: center;
  gap: 8px; /* Add spacing between elements */
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.report-label {
  font-weight: bold;
}

.custom-select {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.date-display {
  font-weight: bold;
  color: #333;
}

.content-container {
  display: flex;
  background-color: #fcfcfc;
  min-height: 100vh;
}

.container-child {
  flex: 1;
  padding: 10px;
  background-color: #bcbfc6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.stock-report {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  min-height: 90vh;
  margin-left: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent horizontal overflow */
}

/* Header and export button styling */
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-title h3 {
  font-size: 18px;
  margin: 0;
}

.stock-name {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.report-date input {
  margin-bottom: 20px;
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: small;
}

.export-buttons {
  display: flex;
  align-items: center;
  gap: 16px; /* Space between buttons */
}

.download-wrapper {
  position: relative; /* To position the dropdown relative to this container */
}

.download-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.download-icon:hover {
  transform: scale(1.1);
}

.download-options {
  position: absolute;
  top: 36px; /* Adjust based on the icon size */
  left: -10px; /* Adjust to the left (negative values shift it left) */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin-right: 10%;
  padding: 0;
  z-index: 1000;
  width: 100px;
}

.download-options li {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.download-options li:hover {
  background-color: #017afc;
  color: #fff;
  font-size: small;
}

.close-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.close-icon:hover {
  transform: rotate(90deg);
}
/* Table styling */
/* General Table Styling */
.report-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 0.5%;
  overflow: hidden; /* Prevents unwanted horizontal scroll */
}

/* Sticky Header Styling */
.report-table thead th {
  position: sticky;
  top: 0;
  background-color: #bcbfc6;
  color: #212529;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for separation */
}

/* Table Header, Data Cell Styling */
.report-table th,
.report-table td {
  text-align: left;
  padding: 8px;
  padding-left: 2%;
  font-size: 14px;
}

/* Sticky Footer Styling */
.report-table tfoot td {
  position: sticky;
  bottom: 0;
  background-color: #bcbfc6; /* Background color for footer */
  border-top: 3px solid #ababab;
  font-weight: 700;
}

/* Scrollable Table Body */
.report-table tbody {
  display: block;
  overflow-y: auto;
  height: 450px; /* Adjust the height to suit your needs */
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Row Styling */
.report-table tbody tr:nth-child(even) {
  background-color: #ededed;
}

.report-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
/* Ensure correct display for all sections */
.report-table thead,
.report-table tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.report-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
