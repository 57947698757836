<template>
  <desktop-header />
</template>

<script>
import DesktopHeader from "./DesktopHeader.vue";

export default {
  components: { DesktopHeader }, // Import MobileHeader if needed
  name: "Header",
  data() {
    return {
      initials: "",
    };
  },
  methods: {
   
  },
  mounted() {

  },
};
</script>

<!-- <style>

#mobileHeader {
    display: none;
}

@media screen and (max-width: 1096px) {
    #desktopHeader {
        display: flex;
    }

    #mobileHeader {
        display: flex;
    }

}
</style> -->
