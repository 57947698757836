<template>
  <div class="dashboardcard-container">
    <div class="card dashboardcard" @click="goToProcurementTableByProjectStatus('New')">
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold" v-if="projectStatusCounts[0]"
          >{{ projectStatusCounts[0].count }}
        </span>
        <span>
          <img src="../../assets/icons/newProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">New Projects</span>
      </div>
    </div>
    <div
      class="card dashboardcard"
      @click="goToProcurementTableByProjectStatus('In Progress')"
    >
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold" v-if="projectStatusCounts[1]">
          {{ projectStatusCounts[1].count }}
        </span>
        <span>
          <img src="../../assets/icons/activeProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">Active Projects</span>
      </div>
    </div>

    <div
      class="card dashboardcard"
      @click="goToProcurementTableByProjectStatus('Completed')"
    >
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold" v-if="projectStatusCounts[2]">
          {{ projectStatusCounts[2].count }}
        </span>
        <span>
          <img src="../../assets/icons/completedProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">Completed Projects </span>
      </div>
    </div>
    <div class="card dashboardcard">
      <a @click="goToPurchaseReport()" style="text-decoration: none">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span
            style="font-size: 24px; font-weight: bold; color: #000000"
            v-if="projectStatusCounts[3]"
          >
            {{ formatAmount(projectStatusCounts[3].amount) }}
          </span>
          <span>
            <img src="../../assets/icons/totalCustomers.png" alt="" />
          </span>
        </div>
        <div class="ms-2 p-1">
          <span id="textColorOfCard">Total Purchase</span>
        </div>
      </a>
    </div>
  </div>
  <div class="section WhiteHeadSection">
    <div class="container-fluid contentOfWhiteHeadSection">
      <div
        class="container-fluid d-flex p-0 contentOfWhiteHeadSectiondiv"
        style="width: 96%; position: relative; gap: 3%; padding: 0 4%"
      >
        <div class="LeadChart p-2" style="flex: 1">
          <div class="col-md-12">
            <h6 class="ms-2">
              Recent PR’s(<span class="text-danger">{{ this.ProcurementPrs.length }}</span
              >)
            </h6>
          </div>

          <div class="col-md-12 p-1" style="border-radius: 20px">
            <div
              class="table-responsive"
              style="height: 290px; overflow-y: auto; padding: 0 5px"
            >
              <table class="table">
                <caption class="d-none">
                  Table
                </caption>
                <thead class="position-sticky z-1 top-0">
                  <tr>
                    <th>Project Name</th>
                    <th>PR Number</th>
                    <th>Date</th>
                    <th>Department</th>
                    <th v-if="$isManager('Procurement')">Requested by</th>
                    <th class="ms-4">Action</th>
                  </tr>
                </thead>
                <tbody class="text-nowrap bodyText">
                  <!-- Use v-for to iterate over your data and create rows -->
                  <tr v-for="PurchaseRequest in ProcurementPrs" :key="PurchaseRequest.id">
                    <td>
                      {{ PurchaseRequest["projects.projectName"] }}
                    </td>
                    <td>
                      {{ PurchaseRequest["purchaseRequests.prNumber"] }}
                    </td>
                    <td>
                      {{ PurchaseRequest["purchaseRequests.date"] }}
                    </td>
                    <td>
                      {{ PurchaseRequest["purchaseRequests.department"] }}
                    </td>
                    <td v-if="$isManager('Procurement')">
                      {{ PurchaseRequest["purchaseRequests.employees.firstName"] }}
                    </td>
                    <td>
                      <span v-if="$isExecutive('Procurement')">
                        {{ PurchaseRequest["purchaseRequests.prStatus"] }}
                      </span>
                      <span v-else>
                        <img
                          src="../../assets/icons/view.png"
                          alt=""
                          id="approve"
                          data-bs-toggle="modal"
                          data-bs-target="#PRDetailsModal"
                          @click="openPRModal(PurchaseRequest['purchaseRequests.id'])"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="LeadChart p-2" style="flex: 1">
          <div class="col-md-12">
            <h6 class="ms-2">
              PO Approval (<span class="text-danger">{{
                this.PurchaseOrders.length
              }}</span
              >)
            </h6>
          </div>

          <div class="col-md-12 p-1" style="border-radius: 20px">
            <div
              class="table-responsive"
              style="height: 290px; overflow-y: auto; padding: 0 5px"
            >
              <table class="table">
                <caption class="d-none">
                  Table
                </caption>
                <thead class="position-sticky z-1 top-0">
                  <tr>
                    <th>Project Name</th>
                    <th>PO Number</th>
                    <th>Date</th>
                    <th>Requested by</th>
                    <th class="ms-4">Action</th>
                  </tr>
                </thead>
                <tbody class="text-nowrap bodyText">
                  <tr v-for="PurchaseRequest in PurchaseOrders" :key="PurchaseRequest.id">
                    <td>
                      {{ PurchaseRequest["projects.projectName"] }}
                    </td>
                    <td>
                      {{ PurchaseRequest.poNumber }}
                    </td>
                    <td>
                      {{ PurchaseRequest.date }}
                    </td>
                    <td>
                      {{ PurchaseRequest["employees.firstName"] }}
                    </td>
                    <td>
                      <span v-if="$isExecutive('Procurement')">
                        {{ PurchaseRequest.poStatus }}
                      </span>
                      <span v-else>
                        <img
                          src="../../assets/icons/view.png"
                          alt=""
                          id="approve"
                          data-bs-toggle="modal"
                          data-bs-target="#PODetailsModal"
                          @click="openPOModal(PurchaseRequest.id)"
                        />

                        <img
                          src="../../assets/icons/approve.png"
                          alt=""
                          id="approve"
                          @click="openApprovalModal(PurchaseRequest.id)"
                        />
                        <img
                          src="../../assets/icons/reject.png"
                          alt=""
                          id="approve"
                          @click="openRejectModal(PurchaseRequest.id)"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid contentOfWhiteHeadSection2 mt-3 mb-2">
      <div
        class="container-fluid d-flex p-0 contentOfWhiteHeadSection2div"
        style="width: 96%; position: relative; gap: 3%; padding: 0 2%"
      >
        <div class="LeadChart p-2" style="flex: 1.5">
          <div class="d-flex justify-content-between" style="padding: 2px 6px">
            <div>
              <span class="subHeadingHeader m-2">Purchase Summery</span>
            </div>
          </div>

          <div v-if="PurchaseReport" class="ms-2" style="font-size: 12px">
            <div id="chart">
              <apexchart
                type="bar"
                height="280"
                :options="chartOptions4"
                :series="series4"
              ></apexchart>
            </div>
          </div>
        </div>
        <div class="otherChart" style="flex: 0.4">
          <section class="card border-0" style="padding: 2%">
            <div class="d-flex flex-column">
              <p class="p-1 m-0" style="font-size: 14px; border-bottom: 1px solid #000">
                Recent Purchase Orders
              </p>
              <div
                class="d-flex align-items-center"
                style="padding: 12px 0"
                v-for="(row, index) in recentPurchaseOrders"
                :key="index"
              >
                <div class="d-flex flex-column align-items-start ms-3">
                  <p
                    class="m-0 p-0 text-nowrap"
                    style="font-size: 14px; text-overflow: ellipsis"
                  >
                    {{ row["vendors.companyName"] }}
                    <span v-if="row['vendors.primaryContactNo']" style="font-size: 12px">
                      ({{ row["vendors.primaryContactNo"] }})</span
                    >
                  </p>
                  <p
                    class="m-0 mt-2 p-0 d-flex justify-content-between text-muted"
                    style="font-size: 10px; width: 250px"
                  >
                    <span style="font-size: 10px">
                      <span v-if="row['vendors.primaryContactName']">
                        {{ row["vendors.primaryContactName"] }}
                      </span>
                      &nbsp; &nbsp; &nbsp; {{ row.date }}</span
                    >

                    <span style="font-size: 10px">
                      {{ $formatToIndianCurrency(row.totalAmount) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="container-fluid contentOfWhiteHeadSection2 mt-3 mb-2">
      <div
        class="container-fluid d-flex p-0 contentOfWhiteHeadSection2div"
        style="width: 96%; position: relative; gap: 3%; padding: 0 2%"
      >
        <div style="flex: 1">
          <div class="d-flex flex-column LeadChart2 p-2">
            <div class="row w-100 ms-1">
              <p class="subHeadingHeader p-1 m-0">Today’s Task</p>
              <hr />
            </div>
            <div
              class="card card2 p-2 m-2"
              v-for="task in todayTasks"
              :key="task.id"
              data-bs-toggle="modal"
              data-bs-target="#taskModal"
              @click="openTaskModal(task.id)"
            >
              <div class="d-flex justify-content-between align-items-center">
                <section class="d-flex align-items-center">
                  <img
                    src="../../assets/icons/taskCompleted.png"
                    alt="Task Completed"
                    style="width: 17px; height: 17px"
                  />
                  <span class="ms-2" style="font-size: 12px">{{ task.title }}</span>
                </section>
                <section class="d-flex align-items-center">
                  <span class="d-flex align-items-center" style="padding: 0 10px">
                    <img
                      src="../../assets/icons/subtask.png"
                      alt="Subtask"
                      style="width: 15px; height: 15px"
                    />
                    <sup style="font-size: 10px; font-weight: 600" class="ms-1">
                      {{ task.subtaskCnt }}
                    </sup>
                  </span>
                  <span style="padding: 0 5px">
                    <button
                      style="
                        width: 70px;
                        height: 25px;
                        font-size: 10px;
                        border: none;
                        border-radius: 5px;
                      "
                    >
                      {{ task.priority }}
                    </button>
                  </span>
                  <span style="padding: 0 5px">
                    <img
                      src="../../assets/icons/threeDot.jpg"
                      alt="More options"
                      style="width: 15px; height: 13px"
                    />
                  </span>
                </section>
              </div>

              <div class="mt-3 d-flex justify-content-between">
                <section class="d-flex align-items-center">
                  <img
                    src="../../assets/icons/black_Calendar.png"
                    alt="Calendar"
                    style="width: 15px; height: 15px"
                  />
                  <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                    {{ task.date }}
                  </span>
                </section>
                <section>
                  <span>
                    <img
                      src="../../assets/icons/profileBlack.png"
                      alt="Profile"
                      style="width: 20px; height: 20px"
                    />
                    <img
                      src="../../assets/icons/profileBlack.png"
                      alt="Profile"
                      style="width: 20px; height: 20px"
                    />
                    <img
                      src="../../assets/icons/profileBlack.png"
                      alt="Profile"
                      style="width: 20px; height: 20px"
                    />
                  </span>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div style="flex: 1">
          <div class="d-flex flex-column LeadChart2 p-2">
            <div class="row w-100 ms-1">
              <p class="subHeadingHeader p-1 m-0">Today’s Schedules</p>
              <hr />
            </div>

            <div
              class="card card3 p-2 m-2 border-0 blueactive-SubHeading2 justify-content-between"
              v-for="event in todayEvents"
              :key="event.id"
            >
              <div class="m-1">
                <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                  {{ event.title }}
                </p>
                <p style="font-size: 10px" v-if="event.description !== null">
                  {{ event.description }}
                </p>
              </div>
              <div
                class="mb-1 m-1"
                style="font-size: 12px"
                v-if="event.meetingLink !== null"
              >
                <span>
                  <a :href="'//' + event.meetingLink" target="_blank">{{
                    event.meetingLink
                  }}</a>
                </span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <section style="font-size: 12px">
                  <p class="p-0 m-1 d-flex align-items-center">
                    <img
                      src="../../assets/icons/black_Calendar.png"
                      alt="Calendar"
                      class="mt-1"
                      style="width: 15px; height: 15px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ convertTo12HourFormat(event.fromTime) }} To
                      {{ convertTo12HourFormat(event.toTime) }}
                    </span>
                  </p>
                  <p class="p-0 m-1 d-flex align-items-center">
                    <img
                      src="../../assets/icons/black_Calendar.png"
                      alt="Calendar"
                      class="mt-1"
                      style="width: 15px; height: 15px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ "2024-05-16" }}
                    </span>
                  </p>
                </section>
                <section class="d-flex align-items-center">
                  <img
                    src="../../assets/icons/profileBlack.png"
                    alt="Profile"
                    style="width: 20px; height: 20px"
                  />
                  <img
                    src="../../assets/icons/profileBlack.png"
                    alt="Profile"
                    style="width: 20px; height: 20px"
                  />
                  <img
                    src="../../assets/icons/profileBlack.png"
                    alt="Profile"
                    style="width: 20px; height: 20px"
                  />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content for section 2 -->
  </div>

  <!-- <PRDetailsModal :PurchaseRequestId="selectedPRId" ref="PRDetailsModal" /> -->
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
// import PRDetailsModal from "../Modal/PRDetailsModal.vue";
import axios from "axios";
export default {
  name: "ProcurementDashboard",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    SelectedEmployeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      PurchaseReport: {
        debitNote: {},
        purchaseOrder: {},

        // other fields
      },
      projectStatusCounts: [],
      selectedPRId: null,
      todayTasks: [],
      todayEvents: [],
      currentDay: "",
      formattedDate: "",
      fullName: "",
      greeting: "",
      employees: [],
      finalPrStatusCounts: {},
      ProcurementPrs: [],
      PurchaseOrders: [],
      recentPurchaseOrders: [],
      selectedEmployeeId: this.SelectedEmployeeId,
      series4: [],
      chartOptions4: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false, // Change to false to make the bars vertical
            columnWidth: "50%", // Adjust width based on selectedTerm
            borderRadius: 5, // Adjust the border radius
            dataLabels: {
              position: "",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Values",
          },
        },
      },
    };
  },

  mounted() {
    this.selectedEmployeeId = this.SelectedEmployeeId;
    this.setCurrentDate();
    this.fetchTodayTasks();
    this.fetchTodayEvents();

    this.fetchCountsData();
    this.fetchEmployees();
    this.fetchPurchaseOrder();
    this.fetchProcurementPrs();
    this.fetchrecentpurchaseorders();
    this.fetchLastThreeProject();
    this.fetchPurchaseReport();
  },

  watch: {
    SelectedEmployeeId(newVal) {
      this.selectedEmployeeId = newVal;
      this.fetchPurchaseOrder();
      this.fetchCountsData();
      this.fetchProcurementPrs();
      this.fetchrecentpurchaseorders();
      this.fetchLastThreeProject();
      this.fetchPurchaseReport();
    },
  },
  computed: {
    isProcurementExecutive() {
      const roles = this.$LocalUser.employeeRoles;
      return roles.some(
        (role) => role.team === "Procurement" && role.role === "Executive"
      );
    },
  },

  methods: {
    formatAmount(amount) {
      if (amount > 1000) {
        return this.$formatToIndianCurrency((amount / 1000).toFixed(1)) + " K";
      } else {
        return this.$formatToIndianCurrency(amount);
      }
    },
    openTaskModal(taskId) {
      this.$emit("task-selected", taskId);
    },
    openPOModal(selectedPIId) {
      this.$emit("PI-selected", selectedPIId);
    },
    openPRModal(SelectedPRId) {
      this.$emit("PR-selected", SelectedPRId);
    },
    async openApprovalModal(Id) {
      try {
        const confirmApproval = window.confirm(
          "Are you sure you want to approve this PO?"
        );
        if (confirmApproval) {
          const response = await axios.put(
            `${this.$apiEndPoint}/purchaseOrder/PoStatus/${Id}`,
            {
              poStatus: "Approved",
            }
          );

          if (response.status === 200) {
            this.fetchPurchaseOrder();

            this.$toast("PO Approved successfully", "success");
          }
        }
      } catch (error) {
        // Handle error response
        this.$handleError(error);
      }
    },

    goToPurchaseReport() {
      this.$router.push({
        name: "PurchaseReport",
        params: {
          selectedEmployeeId: this.selectedEmployeeId,
        },
      });
    },
    async openRejectModal(Id) {
      try {
        const confirmRejection = window.confirm(
          "Are you sure you want to reject this PO?"
        );
        if (confirmRejection) {
          const response = await axios.put(
            `${this.$apiEndPoint}/purchaseOrder/PoStatus/${Id}`,
            {
              poStatus: "Rejected",
            }
          );

          if (response.status === 200) {
            this.fetchPurchaseOrder();

            this.$toast("PO Rejected successfully", "success");
          }
        }
      } catch (error) {
        // Handle error response
        this.$handleError(error);
      }
    },

    async fetchPurchaseReport() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/debitNote/debitAndPoReport/${this.selectedEmployeeId}`
        );

        this.PurchaseReport = response.data;

        const purchaseOrder = [];
        const debitNote = [];
        const monthsOrQuarters = [];

        for (const month in response.data.debitNote) {
          const [year, monthNumber] = month.split("-");
          const date = new Date(year, monthNumber - 1);
          const monthName = date.toLocaleString("default", { month: "short" });
          const formattedDate = `${monthName}`;

          monthsOrQuarters.push(formattedDate);
          purchaseOrder.push(response.data.purchaseOrder[month]);
          debitNote.push(response.data.debitNote[month]);
        }

        this.series4 = [
          {
            name: "debitNote",
            data: debitNote,
          },
          {
            name: "purchaseOrder",
            data: purchaseOrder,
          },
        ];

        // Ensure xaxis and yaxis are properly initialized
        this.chartOptions4 = {
          ...this.chartOptions4,
          xaxis: {
            categories: monthsOrQuarters,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                if (value >= 1000) {
                  return value / 1000 + "k";
                }
                return value;
              },
            },
          },
        };

        // Update the chart
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions(this.chartOptions4);
          this.$refs.chart.updateSeries(this.series4);
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchCountsData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementProject/statusCount/${this.selectedEmployeeId}`
        );
        this.projectStatusCounts = response.data.finalPrStatusCounts;
      } catch (error) {
        this.$handleError(error);
      }
    },

    openModal(selectedPRId) {
      this.selectedPRId = selectedPRId;
    },
    goToProcurementTableByProjectStatus(statusName) {
      if (statusName === "New" && this.isProcurementExecutive) {
        return; // Exit function early if Design Executive has access
      }
      if (statusName === "New") {
        this.selectedEmployeeId = "All";
      }
      this.$router.push({
        name: "ProcurementProjectTableByStatus",
        params: {
          statusName: statusName,
          selectedEmployeeId: this.selectedEmployeeId,
        },
      });
    },
    async fetchLastThreeProject() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/recentPo/${this.selectedEmployeeId}`
        );
        this.lastThreePurchaseorder = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    convertTo12HourFormat(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(":");

      // Convert hours to a number
      let hour = parseInt(hours, 10);

      // Determine if it's AM or PM
      const period = hour >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hour = hour % 12 || 12;

      // Return the formatted time string
      return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
    },

    async fetchrecentpurchaseorders() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/recentPo/${this.selectedEmployeeId}`
        );
        this.recentPurchaseOrders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchPurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/poForApproval/${this.selectedEmployeeId}`
        );
        this.PurchaseOrders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchProcurementPrs() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/recentPrs/${this.selectedEmployeeId}`
        );
        this.ProcurementPrs = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchTodayTasks() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/tasks/calendar/${year}/${month}/${day}`
        );
        this.todayTasks = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTodayEvents() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/events/${year}/${month}/${day}`
        );
        this.todayEvents = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEmployees() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/employee/myTeam/Procurement`
        );
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    getGreeting() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      if (currentHour < 12) {
        return "Good Morning";
      } else if (currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },

    setCurrentDate() {
      const date = new Date();
      this.currentDay = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      }).format(date);
      const options = { day: "numeric", month: "long", year: "numeric" };
      this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0px;
}

.progress-bar {
  height: 10px;
}

.table-responsive thead th {
  background-color: #ccc;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 6px;
  text-align: left;
}

.table-responsive tbody td {
  font-size: 12px;
  text-align: left;
  padding: 4px 6px;
}

.blueactive-SubHeading2 {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  background-image: linear-gradient(to right, #2381f3 4px, transparent 3px);
  background-size: 100% 100%;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
}

.transaction-details {
  position: fixed;
  display: block;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  min-height: 250px;
  height: auto;
  background-color: #f4f4f4;
  color: #000000;
  border: 1px solid #b1c5df;
  border-radius: 10px;
}

#blackThreeDOtDashBoard {
  width: 15px;
  height: 13px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.sales-dashboard {
  margin: 0.5% 2%;
  box-sizing: border-box;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative;
  background-color: #f4f4f4;
  /* Relative positioning for the wrapper */
}

.WhiteHeadSection::-webkit-scrollbar {
  width: 5px;
  /* Set the width to 0 */
}

.WhiteHeadSection::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 4px;
  height: 50px;
}

.WhiteHeadSection::-webkit-scrollbar-track {
  background-color: #ddd;
}

.contentOfWhiteHeadSection {
  position: relative;
  width: 100%;
}

.contentOfWhiteHeadSection2 {
  position: relative;
  width: 100%;
  height: auto;
}

.welcome {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
}

.LeadChart {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
}

.LeadChart2 {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow-y: auto;
}

.otherChart {
  max-height: 250px;
  display: flex;
  justify-content: space-between;
}

.otherChart section {
  max-height: 350px;
  min-height: 350px;
  width: 300px;
  background-color: #ffffff;
  overflow-y: auto;

  border-radius: 5px;
}

.otherChart section::-webkit-scrollbar {
  display: none;
}

.lead-count-chart {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.chart-bar {
  margin-right: 10px;
  text-align: center;
  display: flex;
}

.bar-label {
  font-size: 12px;
  margin-bottom: 5px;
  width: 150px;
  text-align: left;
}

.DashboardSection2 {
  background-color: #000000;
}

.card2 {
  width: 400px;
  height: 100px;
  background-color: #ffffff;
}

.card3 {
  width: 400px;
  min-height: 200px;
  max-height: fit-content;
  background-color: #ffffff;
  border-radius: 0 5px 5px 0;
  overflow-y: auto;
}

.card3::-webkit-scrollbar {
  display: none;
}

#approve {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
}

#textColorOfCard {
  color: rgb(4, 4, 4);
  font-weight: 500;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  /* Distributes space between flex items */
  align-items: center;
  padding-top: 10px;
  /* padding-right: 30px; */
}

#textColorOfCardHeading1,
#textColorOfCardHeading2,
#textColorOfCardHeading3 {
  color: #bcbfc6;
  font-size: 10px;
}

#textColorOfCardContent1 {
  color: #bcbfc6;
  font-size: 10px;
  padding-left: 15px;
}

#textColorOfCardContent2 {
  color: #bcbfc6;
  font-size: 10px;
}

#textColorOfCardContent3 {
  color: #bcbfc6;
  font-size: 10px;
  padding-right: 20px;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .blackHeadSection {
    height: auto;
    padding: 10px;
  }

  .middleHeadSection {
    display: none;
  }

  .LeadChart,
  .LeadChart2,
  .otherChart section {
    min-height: auto;
    max-height: auto;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    padding: 10px;
    overflow-y: scroll !important;
  }

  .contentOfWhiteHeadSection,
  .contentOfWhiteHeadSection2 {
    padding: 10px;
  }

  .otherChart {
    flex-direction: column;
    justify-content: space-around;
  }

  .otherChart section {
    width: 100%;
    margin-bottom: 10px;
  }

  .LeadChart {
    margin-bottom: 10px;
  }

  .lead-count-chart {
    margin-top: 10px;
  }

  .table-responsive thead th {
    font-size: 12px;
    padding: 4px;
  }

  .welcome {
    font-size: 18px;
  }

  .progress-bar-container {
    margin: 5px 0;
  }

  .bar-label {
    width: auto;
  }

  .WhiteHeadSection {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: scroll;
  }

  .contentOfWhiteHeadSectiondiv,
  .contentOfWhiteHeadSection2div {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .content-container,
  .container-child,
  .section-wrapper {
    flex-direction: column;
  }

  .black-head-section,
  .middle-head-section,
  .white-head-section {
    height: auto;
    overflow-y: auto;
  }

  .lead-chart,
  .other-chart,
  .lead-chart2 {
    margin-top: 10px;
  }
  .WhiteHeadSection {
    display: none;
  }
}

/*

.bar {
  width: 50px;
  height: 20px;
  background-color: #007bff; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}*/
</style>
