<template>
  <div>
    <CreateDesigProjectModal
      :OnSiteprojectId="selectedProjectId"
      @fetchData="fetchData"
    />
    <TableDetailsModal :details="details" />
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild overflow-y-scroll">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex align-content-center">
              <div class="col-md-6 d-flex">
                <div class="col-md-3 text-nowrap active-SubHeading">
                  <h5 v-if="statusName === 'New'">New Projects</h5>
                  <h5 v-else-if="statusName === 'In Progress'">Active Projects</h5>
                  <h5 v-else>{{ statusName }} Projects</h5>
                </div>
                <div class="col-md-2" style="visibility: hidden">
                  <img
                    src="../../assets/icons/blueAddicon.png"
                    alt=""
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                    data-bs-whatever="@mdo"
                    id="addicon"
                  />
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Export</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>

                <div class="col-md-3" style="visibility: hidden">
                  <img
                    src="../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-3 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchLeads"
                    />
                  </div>
                  <span>
                    <img
                      src="../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper WEB">
            <table class="tableFormat">
              <caption class="d-none">
                Table Format
              </caption>
              <thead>
                <tr>
                  <th>Project Name</th>

                  <th>Customer Name</th>
                  <th v-if="statusName === 'New'">Customer ID</th>
                  <th>Contact No</th>
                  <th v-if="statusName === 'New'">Email ID</th>
                  <th>Property Address</th>
                  <th v-if="statusName === 'New' && $isManager('Onsite')">Action</th>
                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Sales Person
                  </th>
                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Designer
                  </th>
                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Site Supervisor
                  </th>

                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Start Date
                  </th>
                  <th v-if="statusName === 'Completed'">End Date</th>
                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Status
                  </th>

                  <th
                    class="text-center"
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                  >
                    Documents
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(data, index) in displayedData" :key="index">
                  <td @click="navigateToProjectProfile(data)">
                    {{ data["projects.projectName"] }}
                  </td>
                  <td>{{ data["customers.name"] }}</td>
                  <td v-if="statusName === 'New'">
                    {{ data["customers.customerNumber"] }}
                  </td>
                  <td>{{ data["customers.contactNo"] }}</td>
                  <td v-if="statusName === 'New'">{{ data["customers.email"] }}</td>
                  <td>{{ data["addresses.address"] }}{{ data["addresses.city"] }}</td>
                  <td v-if="statusName === 'New' && $isManager('Onsite')">
                    <button
                      @click="acceptProject(data.id)"
                      type="btn"
                      class="btn btn-sm btn-outline-success"
                      data-bs-toggle="modal"
                      data-bs-target="#createProjectModal"
                      style="cursor: pointer"
                    >
                      Accept
                    </button>
                  </td>

                  <td v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    {{ data["projects.salesPerson.firstName"] }}
                    {{ data["projects.salesPerson.lastName"] }}
                  </td>
                  <td v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    {{ data["projects.designers.firstName"] }}
                    {{ data["projects.designers.lastName"] }}
                  </td>
                  <td v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    <div
                      class="collaborator-avatar"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        v-for="(employee, employeeIndex) in (
                          data.supervisors || []
                        ).slice(0, 3)"
                        :key="employeeIndex"
                      >
                        <span v-if="employee['employees.signedUrl'] !== null">
                          <img
                            class="initials"
                            :src="employee['employees.signedUrl']"
                            alt=""
                          />
                        </span>
                        <span class="initials" v-else>{{
                          employee["employees.initials"]
                        }}</span>
                      </span>
                    </div>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="collaboratorDropdown"
                      style="z-index: 99999; max-height: 200px; overflow: auto"
                    >
                      <!-- Loop through collaborators -->
                      <li
                        v-for="(employee, employeeIndex) in data.supervisors"
                        :key="employeeIndex"
                        class="p-2"
                      >
                        <!-- Display collaborator initials -->
                        <span
                          >{{ employee["employees.firstName"] }}
                          {{ employee["employees.lastName"] }}</span
                        >
                      </li>
                    </ul>
                  </td>
                  <td v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    {{ formatDate(data.projectStartDate) }}
                  </td>
                  <td v-if="statusName === 'Completed'">
                    {{ formatDate(data.projectEndDate) }}
                  </td>
                  <td
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                    style="cursor: pointer"
                  >
                    <div
                      class="d-flex align-content-center justify-content-left text-nowrap progressImage"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        v-if="data.status === 'Completed'"
                        src="../../assets/icons/completed.png"
                        alt="Completed"
                      />
                      <img
                        v-else-if="data.status === 'On Hold'"
                        src="../../assets/icons/onHold.png"
                        alt="On Hold"
                      />
                      <img
                        v-else-if="data.status === 'In Progress'"
                        src="../../assets/icons/inProgress.png"
                        alt="In Progress"
                      />

                      <h6
                        class="bodyText mt-1"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ data.status }}
                      </h6>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button
                          class="dropdown-item"
                          @click="changeStatusofProject(data.id, 'In Progress', index)"
                        >
                          In Progress
                        </button>
                        <button
                          class="dropdown-item"
                          @click="changeStatusofProject(data.id, 'Completed', index)"
                        >
                          Completed
                        </button>
                        <button
                          class="dropdown-item"
                          @click="changeStatusofProject(data.id, 'On Hold', index)"
                        >
                          On Hold
                        </button>
                      </div>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                  >
                    <img
                      class="ms-3"
                      src="../../assets/icons/signOffDocumentFolder.png"
                      alt=""
                      style="width: 17px; height: 17px"
                      id="dropdownSignOffDocumentButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownSignOffDocumentButton"
                    >
                      <ol
                        v-for="(folder, index) in data.signOffDocuments"
                        :key="index"
                        style="padding: 0px 10px"
                      >
                        <li
                          class="d-flex justify-content-between align-items-center"
                          style="font-size: 14px"
                        >
                          <span class="text-start mt-1">
                            {{ folder["files.awsFiles.fileName"] }}
                          </span>
                          <span class="text-end ms-3">
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              @click="
                                $downloadFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                            />

                            <img
                              @click="
                                $viewFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                              class="ms-1"
                              src="../../assets/icons/view.png"
                              alt=""
                              style="width: 17px; height: 17px"
                            />
                          </span>
                        </li>
                      </ol>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-wrapper MOBILE">
            <table class="tableFormat">
              <caption class="d-none">
                Table Format
              </caption>
              <thead>
                <tr>
                  <th>Project Name</th>
                  <!-- <th>Customer Name</th> -->
                  <th v-if="statusName === 'New' && $isManager('Onsite')">Action</th>
                  <!-- <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Status
                  </th> -->
                  <th
                    class="text-center"
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                  >
                    Documents
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayedData" :key="index">
                  <td @click="navigateToProjectProfile(data)" class="ellipsis">
                    {{ data["projects.projectName"] }}
                  </td>
                  <!-- <td class="ellipsis">{{ data["customers.name"] }}</td> -->
                  <td v-if="statusName === 'New' && $isManager('Onsite')">
                    <button
                      @click="acceptProject(data.id)"
                      type="btn"
                      class="btn btn-sm btn-outline-success"
                      data-bs-toggle="modal"
                      data-bs-target="#createProjectModal"
                      style="cursor: pointer"
                    >
                      Accept
                    </button>
                  </td>

                  <!-- <td
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                    style="cursor: pointer"
                  >
                    <div
                      class="d-flex align-content-center justify-content-left text-nowrap progressImage"
                    >
                      <img
                        v-if="data.status === 'Completed'"
                        src="../../assets/icons/completed.png"
                        alt="Completed"
                      />
                      <img
                        v-else-if="data.status === 'On Hold'"
                        src="../../assets/icons/onHold.png"
                        alt="On Hold"
                      />
                      <img
                        v-else-if="data.status === 'In Progress'"
                        src="../../assets/icons/inProgress.png"
                        alt="In Progress"
                      />

                      <h6
                        class="bodyText mt-1"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ data.status }}
                      </h6>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button
                          class="dropdown-item"
                          @click="changeStatusofProject(data.id, 'In Progress', index)"
                        >
                          In Progress
                        </button>
                        <button
                          class="dropdown-item"
                          @click="changeStatusofProject(data.id, 'Completed', index)"
                        >
                          Completed
                        </button>
                        <button
                          class="dropdown-item"
                          @click="changeStatusofProject(data.id, 'On Hold', index)"
                        >
                          On Hold
                        </button>
                      </div>
                    </div>
                  </td> -->
                  <td
                    class="text-center"
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                  >
                    <img
                      class="ms-3"
                      src="../../assets/icons/signOffDocumentFolder.png"
                      alt=""
                      style="width: 17px; height: 17px"
                      id="dropdownSignOffDocumentButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownSignOffDocumentButton"
                    >
                      <ol
                        v-for="(folder, index) in data.signOffDocuments"
                        :key="index"
                        style="padding: 0px 10px"
                      >
                        <li
                          class="d-flex justify-content-between align-items-center"
                          style="font-size: 14px"
                        >
                          <span class="text-start mt-1">
                            {{ folder["files.awsFiles.fileName"] }}
                          </span>
                          <span class="text-end ms-3">
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              @click="
                                $downloadFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                            />

                            <img
                              @click="
                                $viewFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                              class="ms-1"
                              src="../../assets/icons/view.png"
                              alt=""
                              style="width: 17px; height: 17px"
                            />
                          </span>
                        </li>
                      </ol>
                    </div>
                  </td>
                  <td>
                    <img
                      src="../../assets/icons/info.png"
                      alt=""
                      srcset=""
                      data-bs-target="#tableDetailModal"
                      data-bs-toggle="modal"
                      style="width: 18px; height: 18px"
                      @click="showDetails(data)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="changePage(-1)"
                :disabled="currentPage === 1"
                alt="Prev"
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="changePage(1)"
                :disabled="currentPage === pagination.length"
                alt="Next"
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import CreateDesigProjectModal from "../Modal/CreateDesignProjectModal.vue";
import TableDetailsModal from "../Modal/TableDetailsModal.vue"; // Import the modal
export default {
  name: "OnSiteTableByProjectStatus",
  components: {
    Header,
    Nav,
    CreateDesigProjectModal,
    TableDetailsModal,
  },
  data() {
    return {
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedLeadId: null,
      statusName: "",
      selectedProjectId: null, // New data property for the selected project ID
      details: [],
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.TableData.length / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.ArchiveData.length / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.TableData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.ArchiveData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    displayedData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.TableData.slice(startIndex, endIndex);
    },
    displayedArchiveData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.ArchiveData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.statusName = this.$route.params.statusName;
    this.fetchData();
  },
  methods: {
    showDetails(rowData) {
      // Map row data to label-value pairs
      this.details = [
        { label: "Project Name", value: rowData["projects.projectName"] },
        { label: "Customer Name", value: rowData["customers.name"] },
        { label: "Contact No", value: rowData["customers.contactNo"] },
        { label: "Property Address", value: rowData["addresses.address"] },
        { label: "Email ID", value: rowData["customers.email"] },
        { label: "Start Date", value: this.formatDate(rowData.projectStartDate) },
        { label: "End Date", value: this.formatDate(rowData.projectEndDate) },
        // Add more fields as necessary
      ];
    },
    async changeStatusofProject(ProjectId, Status, index) {
      this.TableData[index].status = Status;
      try {
        await axios.put(`${this.$apiEndPoint}/onSiteProject/updateProject/${ProjectId}`, {
          status: Status,
        });
        // Assuming you have a method to fetch source after status change
        this.fetchData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$handleError(xhr.statusText);
        }
      };

      xhr.onerror = function () {
        this.$handleError(xhr.statusText);
      };

      xhr.send();
    },
    acceptProject(projectId) {
      this.selectedProjectId = projectId;
    },
    navigateToProjectProfile(data) {
      if (this.statusName !== "New") {
        // Navigate to ProjectProfile page with project ID as parameter
        this.$router.push({
          name: "OnSiteProjectProfile",
          params: {
            Id: data.id,
          },
        });
      }
    },

    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },
    openModal(leadId) {
      this.selectedLeadId = leadId;

    },
    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },
    async fetchData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteProject/projectStatus/${this.statusName}`
        );
        this.TableData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteProject/projectStatus/${this.statusName}/archived`
        );
        this.ArchiveData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },

    // Method to delete the selected lead
    DeleteLead(data) {
      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
   
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveLead(data) {
      axios
        .put(`${this.$apiEndPoint}/customer/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
     
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(
          `${this.$apiEndPoint}/designProjects/searchStatus/${this.statusName}/archived`,
          searchData
        )

        .then((response) => {
          this.ArchiveData = response.data; // Ensure it's an array or provide default
  
          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.ArchiveData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    //for Serch api for Unclaimleads
    searchLeads() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(
          `${this.$apiEndPoint}/designProjects/searchStatus/${this.statusName}`,
          searchData
        )

        .then((response) => {
          this.TableData = response.data; // Ensure it's an array or provide defaul
          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.TableData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //for all selected lead Unclaim and delete api

    ArchiveLeads() {
      if (this.selectedCustomers.length === 0) {
        return;
      }

      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/customers/archive`, payload)
        .then(() => {
     
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    deleteLead() {
      if (this.selectedCustomers.length === 0) {
        return;
      }

      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, { data: payload })
        .then(() => {
         
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    changePage(delta) {
      this.currentPage += delta;
    },
    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    createEstimate(selectedLead) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedLead.name,
          PropertyId: selectedLead.propertyId,
          ContactNo: selectedLead.contactNo,
          Email: selectedLead.email,
          leadId: selectedLead.id,
          address: selectedLead.address,
          propertyName: selectedLead["property.name"],
        },
      });
    },

    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    changeStatus(Id, status) {
      this.selectedStatus = status;
      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },
  },
};
</script>
<style scoped>
.FormatTable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

.table-wrapper {
  max-width: 99%;
  margin: 0% 1%;
  padding: 0 0.5%;
  height: 87%;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 99;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
}
.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 30px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 15px;
  height: 15px;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
}
.progressImage img {
  width: 12px;
  height: 12px;
  margin: 7px 5px 2px 0;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.progressImage img {
  width: 10px;
  height: 10px;
}
#FilterIconOfTable {
  width: 20px;
  height: 15px;
  margin-left: 10%;
}
/* Style for collaborator avatars */
.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: -5px;

  /* Adjust margin to control the overlap */
}

/* Style for initials */
.initials {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
  border: 2px solid white;
}

.additional-count {
  width: 15px;
  /* Adjust size as needed */
  height: 15px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Make the container circular */
  background-color: #b1c5df;
  /* Provide a background color */
  color: #000000;
  /* Set text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  /* Adjust font size as needed */
  cursor: pointer;

  z-index: 2;
}

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }
  #addicon {
    width: 100%;
  }

  .table-wrapper {
    margin: 1% 2%;
    width: 96%;
    overflow-x: auto; /* Ensures responsiveness on smaller screens */
    max-height: 90%;
    overflow-y: auto;
  }

  .tableFormat {
    width: 100%; /* Ensures the table takes full width */
    table-layout: auto;
  }

  .tableFormat th,
  .tableFormat td {
    font-size: 14px; /* Adjust font size for better readability on mobile */
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
    margin-top: 5px;
  }

  #ArchiveIcon {
    width: 30%;
  }
  #FilterIconOfTable {
    display: none;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 1%;
    padding: 0 5px;
  }
  .MOBILE .tableFormat th:nth-child(1) {
    border-radius: 5px 0px 0px 5px; /* Applies rounded corners to the first column header */
  }

  .MOBILE .tableFormat th:last-child {
    border-radius: 0px 5px 5px 0px; /* Applies rounded corners to the last column header */
  }
}
</style>
