<template>
  <div class="FinalEstimate border-black">
    <div class="row subHeaderHeadingBgColor align-items-center FinalEstimateHeader m-2">
      <div class="col-8 mt-1 companyName">
        <h4>ARIVU</h4>
        <h4 class="estimate">Sign Off Document</h4>
      </div>
    </div>

    <div class="EstimateContent mb-2" style="padding: 30px 10px">
      <div>
        Dear  Sir/Mam , <br />
        Please review the attached “Sign Off Document”. Kindly approve or reject it by
        providing your feedback. Your prompt response will help us proceed to the next
        stage of the project.
      </div>
      <div class="mt-5">
        <span
     
          style="
            min-width: 300px;
            max-width: fit-content;
            background-color: #f8f8f8;
            font-weight: 500;
            padding: 10px;
            margin: 5px 5px;
            display: flex;
            border-radius: 5px;
            border: 1px solid #2381f3;
            cursor: pointer;
          "
        >
          <!-- Display PDF image if the file type is PDF -->
          <div>
            <img
              src="../../assets/icons/pdfImage.png"
              alt=""
              style="width: 50px; height: 50px"
            />
          </div>
          <a
          :href="this.signedUrl"
            class="ms-2 mt-1"
            target="_blank"
            style="
              color: inherit; /* Inherit the color from the parent */
              text-decoration: none;
            "
            >{{ fetchedSignOffData["files.awsFiles.fileName"] }}</a
          >

          <div style="margin-left: auto" class="d-flex flex-column"></div>
        </span>
      </div>
      <div class="col-md-12 mt-5">
        <div class="container">
          <h6><b>Comment</b></h6>
          <section>
            <textarea
              class="form-control position-relative bodyText"
              name=""
              id=""
              cols="150"
              rows="6"
              v-model="fetchedSignOffData.comment"
              placeholder="Write your comment here"
              required
            ></textarea>
          </section>
        </div>
      </div>

      <!-- Buttons -->
      <div class="footerOfEstimate bg-white mt-3">
        <div class="row justify-content-center">
          <div class="col-md-4"></div>
          <div
            class="col-md-4 d-flex footerButtons"
            v-if="
              fetchedSignOffData.status === 'Verified' ||
              fetchedSignOffData.status === 'Sent'
            "
          >
            <button class="approveButton" @click="approveEstimateEmail">Approve</button>
            <button class="rejectButton" @click="rejectEstimateEmail">Reject</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "signOffApproval",
  components: {},

  props: {
    leadIds: Array, // Define the expected type of passed data
  },

  data() {
    return {
      UUID: null,
      showMailSentModal: false,
      gstDetails: [],
      GSTOptions: [5, 12, 18, 28],
      toggleWidththings: false,
      toggleHeightthings: false,
      estimateId: "",
      SignOffData: {},
      fetchedSignOffData: {},
      companyDetails: [{}],
      unitTypes: ["mm", "cm", "meter", "inch", "feet"],
      signedUrl:null
    };
  },
  mounted() {
    this.UUID = this.$route.params.UUID;
    this.fetchSignOffData();
  },

  methods: {
    openMailSentModal() {
      this.showMailSentModal = true; // Set showMailSentModal to true to open the modal
    },

    // Method to close the mail sent modal
    closeMailSentModal() {
      this.showMailSentModal = false; // Set showMailSentModal to false to close the modal
    },
    printEstimateDetailsWithoutHeader() {
      window.print();
    },

    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },

   
    async fetchSignOffData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designFiles/validateUUID/${this.UUID}`
        );
        this.fetchedSignOffData = response.data.signOffData;
        this.signedUrl =response.data.signedUrl;
      } catch (error) {
        this.$handleError(error);
      }
    },

// Approve estimateEmail
async approveEstimateEmail() {
  const isConfirmed = confirm("Are you sure you want to approve your Sign Off?");

  if (isConfirmed) {
    try {
     await axios.put(
        `${this.$apiEndPoint}/designFiles/customerApproval/${this.fetchedSignOffData.id}`,
        {
          status: "Approved",
          comment: this.fetchedSignOffData.comment,
        }
      );
    
      alert("Your Sign Off was approved successfully");

      
      // Navigate back to the previous page or a specific route
      this.$router.go(-1); // Use this line to go back
      // this.$router.push('/your-desired-route'); // Uncomment and specify if you want to go to a specific route
    } catch (error) {
      this.$handleError(error);
    }
  }
},

// Reject estimateEmail
async rejectEstimateEmail() {
  const isConfirmed = confirm("Are you sure you want to reject your Sign Off?");

  if (isConfirmed) {
    try {
   await axios.put(
        `${this.$apiEndPoint}/designFiles/customerApproval/${this.fetchedSignOffData.id}`,
        {
          status: "Rejected",
          comment: this.fetchedSignOffData.customerComment,
        }
      );

     
      alert("Your Sign Off was rejected successfully");
  
      
      // Navigate back to the previous page or a specific route
      this.$router.go(-1); // Use this line to go back
      // this.$router.push('/your-desired-route'); // Uncomment and specify if you want to go to a specific route
    } catch (error) {
      this.$handleError(error);
    }
  }
},

  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.companyName {
  color: #ffffff;
  display: flex;
}

.companyName h4 {
  margin-top: 1%;
}

.estimate {
  margin-left: 55%;
  margin-top: 1%;
}

.FinalEstimateHeader label,
.FinalEstimateHeader p {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  padding: 2px;
}

.active {
  text-decoration: underline;
}

.FinalEstimateHeader {
  padding: 0 5%;
  border-radius: 5px;
}

.FinalEstimateHeader img {
  width: 6%;
  margin: 0 2%;
  /* Adjust margin as needed */
  aspect-ratio: 1/1;
  /* Maintain aspect ratio */
  object-fit: contain;
  cursor: pointer;
}

.FinalEstimateHeader img:hover {
  width: 7%;
  /* To make images responsive */
}

.glass-effect {
  position: relative;
}

.glass-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.EstimateContent {
  height: 95%;
  margin: 0 10%;
  padding: 0 2%;
  background-color: #ffffff;
}
.footerOfEstimate {
  position: absolute;
  bottom: 0;
  margin-left: 20%;
}

.EstimateBill::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate thead tr .tableOfEstimate thead tr th {
  color: #000000;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f8f8;
  text-align: center;
}

.tableOfEstimate tbody {
  width: 100%;
}

.tableOfEstimate tbody::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate tbody tr,
.tableOfEstimate thead tr {
  display: flex;
}

.tableOfEstimate thead th,
.tableOfEstimate tbody tr td {
  min-width: 9.5%;
  max-width: 9.5%;
  text-align: center;
}

.tableOfEstimate thead th:nth-child(1),
.tableOfEstimate tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
}

.tableOfEstimate tbody tr td input[type="number"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.tableOfEstimate tbody tr td input[type="text"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
}

.tableOfEstimate tbody td:nth-child(2),
.tableOfEstimate thead th:nth-child(2) {
  min-width: 20.8%;
  max-width: 20.8%;
  word-break: break-all;
}

.itemBlock {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.ItemHeaderOfThings p {
  position: relative;
  height: 5px;
  color: #000000;
  font-size: 15px;
  background-color: #ccc;
  font-weight: bolder;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.itemBlock span button {
  background: transparent;
  display: flex;
  padding: 0.5%;
  border-radius: 10px;
  color: #000000;
  outline: none;
  border: none;
}

#itemRowEstimate {
  border: 1px solid #edeae9;
}

.itemBlock #HeaderOfBillItem {
  background-color: transparent;
  width: 100%;
  margin-left: 45%;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bolder;
  text-align: left;
}

#itemAdding {
  display: flex;
  background-color: #084f5b;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid #ffaa23;
}

#itemrowAdding {
  display: flex;
  color: #000000;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}

.toggleWidththings,
.toggleHeightthings {
  position: fixed;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  letter-spacing: 2px;
}

.toggleWidththings p,
.toggleHeightthings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
  font-weight: 100;
}

/* Responsive table styles */
.tableOfEstimate {
  width: 100%;
  border-collapse: collapse;
}

.tableOfEstimate tbody tr {
  padding-top: 2px;
  padding-bottom: 2px;
}

.subHeaderHeadingBgColor {
  background-color: #000000;
}

.ItemHeaderOfThings {
  display: flex;
  align-items: center;
  padding: 2px !important;
  white-space: nowrap;
  background-color: #ccc;
  height: 35px;
  width: 100%;
  font-size: 16px;
  font-weight: bolder;
}

.ItemHeaderOfThings input {
  flex: 1;
  text-align: center;
}

.dropdownToggle {
  cursor: pointer;
}

.EstimateBill {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
}

/* Responsive table container */
.table-responsive {
  overflow-x: auto;
}

button {
  margin-top: 10px;
}

.container {
  font-size: 14px;
}

.FinalEstimate {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #494949;
  font-family: "Inter", sans-serif;
}

pre {
  font-size: 14px;
  color: #000000;
  word-break: break-all;
  white-space: pre-wrap;
  font-family: "Inter", sans-serif !important;
}

.approveButton {
  letter-spacing: 2px;
  margin: 5px 10px;
  background-color: #0000ff;
  padding: 10px 25px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
}

.rejectButton {
  letter-spacing: 2px;
  margin: 5px 15px;
  background-color: red;
  padding: 10px 25px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
}

#companylogoInput {
  height: 80px;
  width: fit-content;
  border: 1px solid #ccc;

  border-radius: 5px;
  outline: none;
  max-width: 300px;
}
/* Styles for responsiveness */
@media only screen and (max-width: 768px) {
  .FinalEstimateHeader img {
    width: 20%;
    /* To make images responsive */
    margin: 0 5%;
    /* Adjust margin as needed */
    aspect-ratio: 1/1;
    /* Maintain aspect ratio */
    object-fit: contain;
  }

  .tableOfEstimate tbody td,
  .tableOfEstimate thead th {
    white-space: nowrap;
    min-width: 120px;
    position: relative;
  }

  label {
    white-space: nowrap;
    flex: 1;
  }

  .customer-details {
    margin-top: -10%;
  }

  .tableOfEstimate tbody tr,
  .tableOfEstimate thead tr {
    display: flex;
    overflow: scroll;
  }

  .footerOfEstimate {
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
  }

  .footerButtons {
    margin-left: 20%;
  }
}
</style>
