<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="Login Image" />
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5">
        <div class="login align-items-center">
          <div
            class="logo d-flex justify-content-center"
            style="font-family: 'Athena', sans-serif"
          >
            <h1>ARIVU</h1>
          </div>
          <h5 class="d-flex justify-content-center">
            <b>Employee Sign Up</b>
          </h5>
          <div class="Employeesignup">
            <span class="input-wrapper mt-4">
              <label for="firstname">First Name</label>
              <input type="text" id="firstname" v-model="firstname" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="lastname">Last Name</label>
              <input type="text" id="lastname" v-model="lastname" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="emailId">Email Id</label>
              <input
                type="email"
                id="emailId"
                v-model="emailId"
                :class="{ 'has-value': emailId }"
              />
            </span>
            <span class="input-wrapper mt-4">
              <label for="phoneNumber">Mobile Number</label>
              <input type="tel" id="phoneNumber" v-model="phoneNumber" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="password">Password</label>
              <input type="password" id="password" v-model="password" />
            </span>
            <button class="mt-4" id="resetSubmitButton" @click="createUser">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EmployeeSignUp",
  data() {
    return {
      uuidToken: null,
      firstname: "",
      lastname: "",
      emailId: "",
      phoneNumber: "",
      companyName: "",
      teamName: "",
      companyId: null,
      role: "",
      password: "",
    };
  },
  methods: {
    EmployeeSignUp() {
      // Make a request to the validation endpoint with the UUID token
      axios
        .get(`${this.$apiEndPoint}/employee/validateToken/${this.uuidToken}`)
        .then((response) => {
          // If the token is valid, populate the UI with the received data
          const data = response.data;
          this.firstname = data.firstName;
          this.lastname = data.lastName;
          this.emailId = data.employeeEmailId;
          this.phoneNumber = data.mobileNumber;
          this.companyId = data.companyId;

          this.password = "";
        })
        .catch((error) => {
          this.$handleError(error);
          // Handle the case where the token is not valid
        });
    },
    createUser() {
      // Validate all mandatory fields
      if (
        !this.firstname.trim() ||
        !this.lastname.trim() ||
        !this.emailId.trim() ||
        !this.phoneNumber.trim() ||
        !this.password.trim() ||
        !this.companyId
      ) {
        alert("All fields are mandatory. Please fill in all the details.");
        return;
      }

      const userData = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.emailId,
        mobile: this.phoneNumber,
        password: this.password,
        companyId: this.companyId,
        // Add other fields as needed
      };

      // Make a POST request to create the user
      axios
        .post(`${this.$apiEndPoint}/employee/createUser/`, userData)
        .then(() => {
          this.$toast(`Employee Created Successfully!`, "success");
          // localStorage.setItem("user-info", JSON.stringify(response.data));
          this.$router.push({
            name: "Login",
            params: { email: this.emailId, password: this.password },
          });
        })
        .catch((error) => {
          this.$handleError(error);
          // Handle the case where user creation fails
        });
    },
  },
  mounted() {
    this.uuidToken = this.$route.params.uuidToken;

    this.EmployeeSignUp();
  },
};
</script>

<style scoped>
.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

/* .Employeesignup p {
    color: #fff;
} */

#resetSubmitButton {
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.signUp {
  margin-top: -5%;
}

.EmployeeSignUpFooter a {
  color: #4884f7;
  cursor: pointer;
}

.input-wrapper {
  min-width: 400px;
  height: 40px;
  position: relative;
}

/* .input-wrapper p {
  margin-left: 55%;
  margin-top: 2%;
} */

.input-wrapper input {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -13px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1rem;
  transition: all 0.4s ease;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
}

.input-wrapper input:focus {
  border-color: #4884f7;
}

.input-wrapper input:focus ~ label,
.input-wrapper input:not(:placeholder-shown) ~ label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #000000;
}

.input-wrapper input:not(:focus):not(:placeholder-shown) ~ label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #000000;
}

@media (max-width: 768px) {
  .loginImg img {
    display: none;
  }

  .EmployeesignupGroup h2 {
    margin-bottom: 10%;
  }

  #EmployeeSignupHeading {
    margin-top: -40%;
    font-size: 1.5rem;
  }

  .input-wrapper {
    min-width: 320px;
    height: 40px;
    position: relative;
  }

  /* .input-wrapper p {
    margin-left: 55%;
    margin-top: 2%;
  } */

  .input-wrapper input {
    padding: 5% 2%;
    height: 100%;
    width: 100%;
    z-index: 99;
    border: 1.5px solid black;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background-color: #ffffff;
    font-weight: bolder;
    /* Set input text color */
    font-size: 1rem;
  }
}
</style>
