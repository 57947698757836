const formatDateDMY = (dateInput) => {
    if (!dateInput) return '-';
  
    // Convert to a Date object if it's a string
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  
    // Check for invalid date
    if (isNaN(date.getTime())) return '-';
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  };
  
  export default formatDateDMY;