<template>
  <!-- Modal for creating a new project -->
  <div
    class="modal fade"
    id="UpdateDesignProjectModal"
    tabindex="-1"
    aria-labelledby="UpdateDesignProjectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title SubheadingUnderLine" id="UpdateDesignProjectModalLabel">
            Update Project
          </h5>
          <img
            src="../../assets/icons/cancel.png"
            alt=""
            data-bs-dismiss="modal"
            aria-label="Close"
            style="width: 20px; height: 20px; cursor: pointer"
          />
        </div>
        <div class="modal-body">
          <div class="form-group mt-2 row">
            <label for="projectName" class="col-sm-4 col-form-label">Project Name:</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="projectName"
                v-model="newProject.projectName"
              />
            </div>
          </div>
          <div class="form-group mt-2 row">
            <label for="customerName" class="col-sm-4 col-form-label"
              >Customer Name:</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="customerName"
                v-model="newProject.customerName"
                readOnly
              />
            </div>
          </div>
          <div class="form-group mt-2 row">
            <label for="customerNumber" class="col-sm-4 col-form-label"
              >Customer ID:</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="customerNumber"
                v-model="newProject.customerNumber"
                readOnly
              />
            </div>
          </div>
          <div class="form-group mt-2 row">
            <label for="familyStatus" class="col-sm-4 col-form-label"
              >Family Status:</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="familyStatus"
                v-model="newProject.familyStatus"
              />
            </div>
          </div>
          <div class="form-group mt-2 row">
            <label for="buildingConfiguration" class="col-sm-4 col-form-label"
              >Building Configuration:</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="buildingConfiguration"
                v-model="newProject.buildingConfiguration"
              />
            </div>
          </div>
          <div class="form-group mt-2 row">
            <label for="assignTo" class="col-sm-4 col-form-label">Assigned To:</label>
            <div class="col-sm-8">
              <select
                v-model="newProject.assignTo"
                name="assignTo"
                id="assignTo"
                class="form-control form-select"
              >
                <option
                  v-for="employee in employees"
                  :key="employee.employeeId"
                  :value="employee.employeeId"
                >
                  {{ employee["employees.firstName"] }}
                  {{ employee["employees.lastName"] }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-primary"
            @click="createProject"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateDesignProjectModal",
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchEmployee: "",
      showEmployeeDropdown: false,
      employees: [],
      newProject: {
        projectName: "",
        customerNumber: "",
        customerName: "",
        customerId: "",
        familyStatus: "",
        buildingConfiguration: "",
        assignTo: null,
      },
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter((employee) => {
        const fullName = `${employee["employees.firstName"]} ${employee["employees.lastName"]}`;
        return fullName.toLowerCase().includes(this.searchEmployee.toLowerCase());
      });
    },
  },
  mounted() {
    this.fetchEmployees();
    // this.fetchProjectData(this.projectId);
  },
  watch: {
    projectId(newValue) {
      // Check if newValue is not null or undefined
      if (newValue) {
        this.fetchEmployees();
        this.fetchProjectData(newValue); // Use newValue to fetch project data
      } else {
        this.fetchEmployees();
      }
    },
  },

  methods: {
    async fetchProjectData(projectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/getById/${projectId}`
        );
        const projectData = response.data;
        this.newProject.projectName = projectData.projectName;
        this.newProject.customerName = projectData["customers.name"];
        this.newProject.customerNumber = projectData["customers.customerNumber"];
        this.newProject.familyStatus = projectData.familyStatus;
        this.newProject.buildingConfiguration = projectData.buildingConfiguration;
        this.newProject.assignTo = projectData.assignTo;
        this.newProject.customerId = projectData["customers.id"];

      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEmployees() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/employee/myTeam/Designing`
        );
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    searchEmployees() {
      this.showEmployeeDropdown = true;
    },
    selectEmployee(employee) {
      this.searchEmployee = `${employee["employees.firstName"]} ${employee["employees.lastName"]}`;
      this.newProject.assignTo = employee.employeeId;
      this.showEmployeeDropdown = false;
    },
    async createProject() {
      try {
       await axios.put(
          `${this.$apiEndPoint}/designProjects/${this.projectId}`,
          this.newProject
        );
        location.reload();
        this.$emit("fetchData");
        this.$toast("Project updated successfully", "success"); 
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
.employee-dropdown {
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  z-index: 1000;
  width: 60%;
}

.employee-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown li {
  padding: 5px 10px;
}

.employee-dropdown li:hover {
  background-color: #f0f0f0;
}
label,
input,
select {
  font-size: 13px;
}
</style>
