<template>
  <ProcurementProductList
    @fetchData="fetchProductsFromAPI(this.Products.length - 1)"
    :selectedTargetId="selectedTargetId"
  />
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request">
          <div class="row header mb-2">
            <div class="d-flex justify-content-between">
              <h5 class="ms-3 text-decoration-underline">Purchase Order</h5>
              <div class="close">
                <img
                  src="../../../assets/icons/cancel.png"
                  alt=""
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                />
              </div>
            </div>
          </div>
          <div class="row ms-2">
            <!-- Row 1: Project Name and Reference -->
            <div class="col-md-5 mb-3 d-flex align-items-center">
              <label for="projectName" class="col-md-4 col-form-label"
                >Project Name: <span class="text-danger">*</span></label
              >
              <div class="col-md-8">
                <select
                  class="form-control border-black form-select"
                  id="category"
                  v-model="form.projectId"
                  @click="fetchPurchaseRequestById(form.projectId)"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.projectId"
                    :value="Project.projectId"
                  >
                    {{ Project.projectName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2 mb-3 d-flex align-items-center"></div>

            <div class="col-md-5 mb-3 d-flex align-items-center">
              <label for="Reference" class="col-md-4 col-form-label">Reference: <span class="text-danger">*</span></label>
              <div class="col-md-8">
                <select
                  class="form-control border-black form-select"
                  id="Reference"
                  v-model="form.prId"
                >
                  <option value="null">Direct PO</option>
                  <option
                    v-for="PurchaseRequest in PurchaseRequests"
                    :key="PurchaseRequest.id"
                    :value="PurchaseRequest.id"
                  >
                    {{ PurchaseRequest.prNumber }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Row 2: Vendor Name and Date -->
            <div class="col-md-5 mb-3 d-flex align-items-center">
              <label for="vendorName" class="col-md-4 col-form-label">Vendor Name: <span class="text-danger">*</span></label>
              <div class="col-md-8">
                <select
                  v-model="form.vendorId"
                  @change="changeVendorAddress(form.vendorId)"
                  class="form-control border-black form-select"
                  id="contractor"
                >
                  <option v-for="Vendor in Vendors" :key="Vendor.id" :value="Vendor.id">
                    {{ Vendor.companyName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2 mb-3 d-flex align-items-center"></div>
            <div class="col-md-5 mb-3 d-flex align-items-center">
              <label for="date" class="col-md-4 col-form-label">Date: <span class="text-danger">*</span></label>
              <div class="col-md-8">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control border-black"
                  id="date"
                />
              </div>
            </div>
          </div>

          <div class="row ms-2">
            <!-- Vendor Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
              >
                <label for="vendorAddress" class="form-label mb-0">Vendor Address: <span class="text-danger">*</span></label>
              </div>

              <div class="mt-2" style="background-color: #f8f9fa">
                <div class="AddressCard">
                  <div class="form-control" id="vendorAddress" style="height: 130px">
                    {{ billingVendorAddressData.name }}<br />
                    {{ billingVendorAddressData.address
                    }}<span v-if="billingVendorAddressData.city">,</span>
                    {{ billingVendorAddressData.city }}<br />
                    {{ billingVendorAddressData.state
                    }}<span v-if="billingVendorAddressData.pincode">-</span
                    >{{ billingVendorAddressData.pincode }}<br />
                    {{ billingVendorAddressData.contactNo }}<br />
                    {{ billingVendorAddressData.email }}<br />
                    {{ billingVendorAddressData.gstNumber }}<br />
                  </div>
                </div>
              </div>
            </div>

            <!-- Billing Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
                @click="toggleBillingDropdown"
              >
                <label for="billingAddress" class="form-label mb-0"
                  >Billing Address: <span class="text-danger">*</span></label
                >
                <img
                  src="../../../assets/icons/downArrow.png"
                  alt=""
                  @click="toggleBillingDropdown"
                  style="width: 14px; height: 8px; cursor: pointer"
                />
              </div>
              <div
                v-if="showBillingDropdown"
                class="mt-2 position-absolute"
                style="z-index: 999; width: 31.2%"
              >
                <select
                  class="form-select border-black"
                  id="billingAddress"
                  v-model="selectedBillingAddress"
                  @change="updateBillingAddress(selectedBillingAddress)"
                >
                  <option
                    v-for="address in BillingAddresses"
                    :key="address.id"
                    :value="address"
                  >
                    {{ address.name }} - {{ address.city }}
                  </option>
                </select>
              </div>
              <div class="mt-2" style="background-color: #f8f9fa">
                <div class="AddressCard">
                  <div class="form-control" style="height: 130px">
                    {{ selectedBillingAddress.name }}<br />
                    {{ selectedBillingAddress.address
                    }}<span v-if="selectedBillingAddress.city">,</span>
                    {{ selectedBillingAddress.city }}<br />
                    {{ selectedBillingAddress.state
                    }}<span v-if="selectedBillingAddress.pincode">-</span
                    >{{ selectedBillingAddress.pincode }}<br />
                    {{ selectedBillingAddress.contactNo }}<br />
                    {{ selectedBillingAddress.email }}<br />
                    {{ selectedBillingAddress.gstNumber }}<br />
                  </div>
                </div>
              </div>
            </div>

            <!-- Shipping Address Card -->
            <div class="col-md-4 mb-3">
              <div
                class="d-flex justify-content-between align-items-center btn btn-sm btn-primary"
                @click="toggleShippingDropdown"
              >
                <label for="shippingAddress" class="form-label mb-0"
                  >Shipping Address: <span class="text-danger">*</span></label
                >
                <img
                  src="../../../assets/icons/downArrow.png"
                  alt=""
                  style="width: 14px; height: 8px; cursor: pointer"
                />
              </div>
              <div
                v-if="showShippingDropdown"
                class="mt-2 position-absolute"
                style="z-index: 999; width: 31.2%"
              >
                <select
                  class="form-select border-black"
                  id="shippingAddress"
                  placeholder="Select"
                  v-model="selectedShippingAddress"
                  @change="updateShippingAddress(selectedShippingAddress)"
                >
                  <option
                    v-for="address in ShippingAddresses"
                    :key="address.id"
                    :value="address"
                  >
                    {{ address.name }} - {{ address.city }}
                  </option>
                </select>
              </div>
              <div class="mt-2" style="background-color: #f8f9fa">
                <div class="AddressCard">
                  <div class="form-control" style="height: 130px">
                    {{ selectedShippingAddress.name }}<br />
                    {{ selectedShippingAddress.address
                    }}<span v-if="selectedShippingAddress.city">,</span>
                    {{ selectedShippingAddress.city }}<br />
                    {{ selectedShippingAddress.state
                    }}<span v-if="selectedShippingAddress.pincode">-</span
                    >{{ selectedShippingAddress.pincode }}<br />
                    {{ selectedShippingAddress.contactNo }}<br />
                    {{ selectedShippingAddress.email }}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive small-wrapper mb-4">
            <table class="small-table table table-bordered" >
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr class="table-header-heading" >
                  <!-- Table headers -->
                  <th style="width: 80px">SL/No</th>
                  <th style="width: 200px">Product Name</th>
                  <th style="width: 300px">Product Description</th>
                  <th style="width: 100px">HSN Code</th>
                  <th style="width: 100px">Article No.</th>
                  <th style="width: 150px">Category</th>
                  <th style="width: 100px">Unit Type</th>
                  <th style="width: 100px">Quantity</th>
                  <th style="width: 100px">Discount(%)</th>
                  <th style="width: 100px">Unit Price</th>
                  <th style="width: 150px">Amount</th>
                  <th style="width: 100px">Gst</th>
                  <th style="width: 150px">Total Amount</th>
                  <th style="width: 150px">Expected Date</th>
                  <th style="width: 150px">Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in Products" :key="index">
                  <td>
                    <div class="cell-content">
                      <span class="delete-icon" @click="removeRow(index)">
                        <img src="../../../assets/icons/redDelete.png" alt="Delete" />
                      </span>
                      <span class="row-index">{{ index + 1 }}</span>
                    </div>
                  </td>
                  
                  
                  <td>
                    <input
                      class="w-100"
                      v-model="product.searchQuery"
                      @input="fetchProductsFromAPI(index)"
                      :ref="'searchQuery-' + index"
                      placeholder="Search product..."
                    />
                    <ul
                      v-if="product.productName !== product.searchQuery  && product.searchQuery.trim() !== '' "
                      class="dropdown-menu show"
                    >
                      <li
                        v-for="(item, idx) in product.filteredProducts"
                        :key="idx"
                        class="dropdown-item"
                        @click="selectProduct(item, index)"
                      >
                        <span>{{ item.productName }}</span>
                      </li>
                      <li
                        v-if="product.filteredProducts.length === 0"
                        class="dropdown-item"
                      >
                        <button
                          class="btn btn-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#ProcurementProductList"
                          @click="openModal(null)"
                        >
                          Add Product
                        </button>
                      </li>
                    </ul>
                  </td>
                  <td class="pt-2" :title="product.description">
                    <input
                     type="text"
                     class="w-100"
                       v-model=" product.description"
                  />
                
                  </td>
                  <td class="pt-2">{{ product.HSNCode }}</td>
                  <td class="pt-2">{{ product.articleNo }}</td>
                  <td class="pt-2">{{ product.category }}</td>
                  <td class="pt-2">{{ product.unitType }}</td>
                  <td class="text-end">
                    <input
                      type="text"
                      class="form-control text-end"
                      v-model.number="product.quantity"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.discount"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.unitPrice"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end pt-2">
                    {{ $formatToIndianCurrency(product.amount) }}
                  </td>
                  <td class="text-end">
                    <input
                      type="number"
                      class="form-control text-end"
                      v-model.number="product.GST"
                      @input="calculateAmounts(product)"
                    />
                  </td>
                  <td class="text-end pt-2">
                    {{ $formatToIndianCurrency(product.totalAmount) }}
                  </td>
                  <td>
                    <input
                      type="date"
                      class="form-control"
                      v-model="product.expectedDate"
                    />
                  </td>
                  <td>
                    <input type="text" class="form-control" v-model="product.remark" />
                  </td>
                 
                </tr>
              </tbody>
            </table>
            <button class="add-item btn btn-sm btn-primary" @click="addNewTask">
              Add Item +
            </button>
            <div class="d-flex justify-content-end">
              <div
                class="card"
                style="
                position: fixed;
  bottom: 60px;
 
  right: 20px;
  width: 300px;
  background-color: #f8f8f8;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  max-height: 400px; /* Set a max-height for the summary card */
  overflow: auto; /* Make the summary scrollable if it overflo
                "
              >
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <strong>Amount:</strong>
                    <span>{{ $formatToIndianCurrency(calculateTotalAmount()) }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <strong>CGST:</strong>
                    <span>{{ $formatToIndianCurrency(calculateCgst()) }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <strong>SGST:</strong>
                    <span>{{ $formatToIndianCurrency(calculateSgst()) }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <strong>Total Amount:</strong>
                    <span>{{ $formatToIndianCurrency(calculateGrandTotal()) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footerPuchaseRequest">
          <!-- <button
          v-if="this.$route.params.POId"
            class="btn btn-sm btn-success"
            :disabled="!form.projectId"
           @click="saveAsDraft"
          >
            Update
          </button>
           -->
           <button
            class="confirmationButton btn btn-sm btn-primary"
            :disabled="!form.projectId || !form.vendorId || !form.vendorAddressId || !form.billingAddressId || !form.shippingAddressId"
              :class="{ 'confirmationButton--loading': isLoadingDraft }"
            @click="saveAsDraft('Draft')"
          >
          <span class="confirmationButton__text"> Save as Draft</span>
                
          </button>
          <button
            class="confirmationButton  btn btn-sm btn-primary"
            :disabled="!form.projectId || !form.vendorId || !form.vendorAddressId || !form.billingAddressId || !form.shippingAddressId"
            :class="{ 'confirmationButton--loading': isLoadingFinalize }"
            @click="saveAsDraft('Pending')"
          >
          <span class="confirmationButton__text">  Save & Submit</span>
     
          </button>
          <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProcurementProductList from "../../Modal/ProcurementProductList.vue";
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";

export default {
  name: "CreatePurchaseOrder",
  components: {
    Header,
    Nav,
    ProcurementProductList,
  },
  data() {
    return {
      isLoadingDraft: false,
      isLoadingFinalize: false,
      selectedTargetId: null,
      BillingAddresses: [],
      ShippingAddresses: [],
      selectedBillingAddress: {},
      selectedShippingAddress: {},
      showBillingDropdown: false,
      showShippingDropdown: false,
      billingVendorAddressData: {
        gstNumber: null,
      },
      Vendors: [],
      PurchaseRequests: [],
      Projects: [],
      ProductList: [],
      Products: [],
      searchQuery: "",
      selectedProduct: null,
      form: {
        date: new Date().toISOString().slice(0, 10),
        vendorId: null,
        projectId: null,
        prId: null,
        vendorAddressId: null,
        shippingAddressId: null,
        billingAddressId: null,
        poStatus:""
      },
    };
  },
  mounted() {
    this.fetchProjects();
    this.fetchVendors();
    if(this.$route.params.POId)
  {
    this.featchPObyId(this.$route.params.POId);
  }
 
  },
  computed: {
    filteredProducts() {
      if (this.searchQuery.trim() === "") {
        return [];
      }
      return this.ProductList.filter((product) =>
        product.productName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },

  methods: {
    async featchPObyId(Id) {
  try {
    const response = await axios.get(`${this.$apiEndPoint}/purchaseOrder/${Id}`);
    const poData = response.data;
    
    this.form = {
      ...poData,
      poStatus: poData.status,
      date: poData.date,
      vendorId: poData.vendorId,
      projectId: poData.projectId,
      prId: poData.prId,
      vendorAddressId: poData.vendorAddressId,
      billingAddressId: poData.billingAddressId,
      shippingAddressId: poData.shippingAddressId,
    };

    // Populate line items
    this.Products = poData.Lineitems.map((item) => ({
      id: item.id,
      productId: item.productId,
      description: item.description,
      HSNCode: item['productLists.HSNCode'],
      articleNo: item['productLists.articleNo'],
      category: item['productLists.category'],
      unitType: item['productLists.unitType'],
      quantity: item.quantity,
      discount: item.discount,
      GST: item.gst,
      unitPrice: item.unitPrice,
      amount: item.amount,
      totalAmount: item.totalAmount,
      expectedDate: item.expectedDate,
      remark: item.remark,
      filteredProducts: [],
      productName: item['productLists.productName'],
      searchQuery: item['productLists.productName']
    }));

    this.changeVendorAddress(this.form.vendorId);
    await this.fetchPurchaseRequestById(this.form.projectId); // Keep existing logic
    await this.fetchBillingAddressForPO(); // Fetch billing addresses
    await this.fetchShippingAddressForPO(); // Fetch shipping addresses
  } catch (error) {
    this.$handleError(error);
  }
},


saveAsDraft(POStatus) {
 // Validate that all product quantities are greater than zero
  const invalidProducts = this.Products.filter(product => product.quantity <= 0);
  
  if (invalidProducts.length > 0) {
    // Show an alert or toast message
    alert("Quantity for all products must be greater than zero. Please check your inputs.");
    // You can replace `alert` with your preferred toast library method
    return; // Exit the function early if validation fails
  }

  // Prepare the line items data
  const lineItems = this.Products.map((product) => ({
    id: product.id,
    productId: product.productId,
    description: product.description,
    quantity: product.quantity,
    discount: product.discount,
    gst: product.GST,
    unitPrice: product.unitPrice,
    totalAmount: product.totalAmount,
    amount: product.amount,
    expectedDate: product.expectedDate,
    remark: product.remark,
  }));


  const payload = {
    date: this.form.date,
    prId: this.form.prId,
    projectId: this.form.projectId,
    vendorId: this.form.vendorId,
    vendorAddressId: this.form.vendorAddressId,
    billingAddressId: this.form.billingAddressId,
    shippingAddressId: this.form.shippingAddressId,
    poStatus: POStatus,
    amount: this.calculateTotalAmount(),
    CGST: this.calculateCgst(),
    SGST: this.calculateSgst(),
    totalAmount: this.calculateGrandTotal(),
    lineitems: lineItems,
  };


 
  if (POStatus === "Pending") {
  // Use the native window confirmation dialog
  if (window.confirm("This PO cannot be updated again and will be sent for approval. Do you wish to proceed?")) {
    this.isLoadingDraft = false;
    this.isLoadingFinalize = true;
    this.submitPurchaseOrder(payload);
  } 
} else {
  this.isLoadingDraft = true;
  this.isLoadingFinalize = false;
  this.submitPurchaseOrder(payload);
}

},

submitPurchaseOrder(payload) {

  const url = `${this.$apiEndPoint}/purchaseOrder${this.$route.params.POId ? `/${this.$route.params.POId}` : ''}`;
  const requestMethod = this.$route.params.POId ? axios.put : axios.post;

  requestMethod(url, payload)
    .then(() => {
      this.isLoadingDraft = false;
      this.isLoadingFinalize = false;
      this.$toast(this.$route.params.POId ? "Purchase Order updated successfully." : "Purchase Order created successfully.", "success");
      this.goBack();

    })
    .catch((error) => {
      this.isLoadingDraft = false;
      this.isLoadingFinalize = false;
      this.$handleError(error);
    });
},


async fetchProductsFromAPI(index) {

  // Retrieve the search query for the product at the specified index and trim any whitespace
  const query = this.Products[index].searchQuery.trim();
  // If the search query is empty, clear the filtered products array and exit the function
  if (!query) {
    this.Products[index].filteredProducts = [];
    return;
  }

  try {
    // Make an API call to fetch the list of products based on the search query
    const response = await axios.post(
      `${this.$apiEndPoint}/productList/searchProducts`, // API endpoint for searching products
      { searchString: query } // Payload containing the search query
    );

    // Update the filtered products array for the product at the specified index with the response data
    this.Products[index].filteredProducts = response.data;
  } catch (error) {
    // Handle any errors encountered during the API call
    this.$handleError(error);
  }
},



    
    calculateTotalAmount() {
      return this.Products.reduce((sum, product) => {
        const discountedPrice =
          product.unitPrice * product.quantity * (1 - product.discount / 100);
        return sum + discountedPrice;
      }, 0);
    },

    // Calculate the CGST amount (assuming CGST is half of the total GST for each product)
    calculateCgst() {
      return this.Products.reduce((sum, product) => {
        const productTotal =
          product.unitPrice * product.quantity * (1 - product.discount / 100);
        const cgstAmount = (productTotal * product.GST) / 200; // Divide by 200 to get half of GST
        return sum + cgstAmount;
      }, 0);
    },

    // Calculate the SGST amount (assuming SGST is half of the total GST for each product)
    calculateSgst() {
      return this.Products.reduce((sum, product) => {
        const productTotal =
          product.unitPrice * product.quantity * (1 - product.discount / 100);
        const sgstAmount = (productTotal * product.GST) / 200; // Divide by 200 to get half of GST
        return sum + sgstAmount;
      }, 0);
    },

    // Calculate the grand total amount (total amount + total GST)
    calculateGrandTotal() {
      const totalAmount = this.calculateTotalAmount();
      const totalGst = this.calculateCgst() + this.calculateSgst();
      return totalAmount + totalGst;
    },
    // Other existing methods...

    openModal(TargetId) {
      this.selectedTargetId = TargetId;
    },
    handleInput(index) {
      this.Products[index].filteredProducts = this.getFilteredProducts(
        this.Products[index].searchQuery
      );
    },
    getFilteredProducts(query) {
      if (query.trim() === "") {
        return [];
      }
      return this.ProductList.filter((product) =>
        product.productName.toLowerCase().includes(query.toLowerCase())
      );
    },
    selectProduct(item, index) {
      this.Products[index] = {
        productId: item.id,
        productName: item.productName,
        searchQuery: item.productName,
        description: item.description,
        HSNCode: item.HSNCode,
        articleNo: item.articleNo,
        category: item.category,
        unitType: item.unitType,
        quantity: 0, // Default value or as needed
        discount: item.discount, // Default value or as needed
        unitPrice: item.unitPrice,
        amount: 0, // Default value or as needed
        GST: item.GST, // Default value or as needed
        totalAmount: 0, // Default value or as needed
        expectedDate: null,
        remark: "",

        filteredProducts: [], // Clear filtered products for the row
      };

      // Remove the selected product from the ProductList
      // this.ProductList = this.ProductList.filter(
      //   (product) => product.productName !== item.productName
      // );
    },

    isProductInList(item) {
      return (
        item && this.Products.some((product) => product.productName === item.productName)
      );
    },
    async fetchVendors() {
      try {
        const response = await axios.post(`${this.$apiEndPoint}/vendor/All`, {
          searchString: "",
        });
        this.Vendors = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchBillingAddressForPO() {
  if (this.form.projectId) {
    try {
      const response = await axios.get(
        `${this.$apiEndPoint}/address/addressForPo/Billing/${this.form.projectId}`
      );
      this.BillingAddresses = response.data;

      // Set the selected billing address based on the form data
      const selectedAddress = this.BillingAddresses.find(
        (address) => address.id === this.form.billingAddressId
      );
      this.selectedBillingAddress = selectedAddress || {}; // set to an empty object if not found
    } catch (error) {
      this.$handleError(error);
    }
  }
},

async fetchShippingAddressForPO() {
  if (this.form.projectId) {
    try {
      const response = await axios.get(
        `${this.$apiEndPoint}/address/addressForPo/Shipping/${this.form.projectId}`
      );
      this.ShippingAddresses = response.data;

      // Set the selected shipping address based on the form data
      const selectedAddress = this.ShippingAddresses.find(
        (address) => address.id === this.form.shippingAddressId
      );
      this.selectedShippingAddress = selectedAddress || {}; // set to an empty object if not found
    } catch (error) {
      this.$handleError(error);
    }
  }
},

    async changeVendorAddress(vendorId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Vendor/${vendorId}`
        );
        this.billingVendorAddressData = response.data[0];
 
        this.form.vendorAddressId = response.data[0].id;
      } catch (error) {
        this.$handleError(error);
      }
    },
    calculateAmounts(product) {
      const gstRate = parseFloat(product.GST) || 0;
      const quantity = parseFloat(product.quantity) || 0;
      const unitPrice = parseFloat(product.unitPrice) || 0;
      const discount = parseFloat(product.discount) || 0;

      const initialAmount = quantity * unitPrice;
      const discountAmount = initialAmount * (discount / 100);
      product.amount = initialAmount - discountAmount;
      const gstAmount = product.amount * (gstRate / 100);
      product.totalAmount = product.amount + gstAmount;
    },
    addNewTask() {
      // Check if the Products array is empty
      if (this.Products.length === 0) {
        this.Products.push({
          productName: "",
          description: "",
          HSNCode: "",
          articleNo: "",
          category: "",
          unitType: "",
          quantity: 0,
          discount: 0,
          unitPrice: 0,
          amount: 0,
          GST: 0,
          totalAmount: 0,
          expectedDate: null,
          remark: "",
          searchQuery: "",
          filteredProducts: [],
          productId: "", // Assuming you have a productId field
        });
      } else {
        const lastProduct = this.Products[this.Products.length - 1];

        // Check if the last product has productName and productId filled out
        if (lastProduct?.productName && lastProduct.productId) {
          this.Products.push({
            productName: "",
            description: "",
            HSNCode: "",
            articleNo: "",
            category: "",
            unitType: "",
            quantity: 0,
            discount: 0,
            unitPrice: 0,
            amount: 0,
            GST: 0,
            totalAmount: 0,
            expectedDate: null,
            remark: "",
            searchQuery: "",
            filteredProducts: [],
            productId: "", // Assuming you have a productId field
          });
        } else {
          // Alert the error message
          alert("Please fill out the current product details before adding a new one.");

          // You could also focus on the first row's productName or searchQuery input field
          // For example, if you're using refs in Vue, you can do something like this:
          // this.$refs['productName'].focus();
        }
      }
    },

 

    toggleBillingDropdown() {
      this.showBillingDropdown = !this.showBillingDropdown;
      //this.selectedBillingAddress = null;
    },
    toggleShippingDropdown() {
      this.showShippingDropdown = !this.showShippingDropdown;
      //this.selectedShippingAddress = null;
    },
    updateBillingAddress(selectedBillingAddress) {
      this.form.billingAddressId = selectedBillingAddress.id;
      this.showBillingDropdown = false;
    },
    updateShippingAddress(selectedShippingAddress) {
      this.form.shippingAddressId = selectedShippingAddress.id;
      this.showShippingDropdown = false;
    },
    async fetchPurchaseRequestById(projectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/getApprovedPr/${projectId}`
        );
        this.PurchaseRequests = response.data;
        this.fetchBillingAddressForPO();
        this.fetchShippingAddressForPO();


      } catch (error) {
        this.fetchBillingAddressForPO();
        this.fetchShippingAddressForPO();

        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
    removeRow(index) {
      this.Products.splice(index, 1);
    },
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/purchaseRequest/getProjectToCreatePr/Procurement`)
        .then((response) => {
          this.Projects = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
.card-body select {
  height: 130px;
}

.AddressCard div {
  height: 130px;
  font-size: 14px;
  overflow-y: auto;
}

.AddressCard div::-webkit-scrollbar {
  display: none;
}

.purchase-request {
  padding: 10px 10px;
  height: 83vh;
  width: 100%;
}

.purchase-request .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  width: 98%;
  margin-top: 20px;
  padding: 0px 20px;
  overflow-x: scroll;
  position: relative;
}

.small-wrapper {
  width: 100%;
  height: 89vh;
  overflow-x: scroll;
  overflow-y: auto;
  margin-bottom: 100px;
  padding-bottom: 200px;
}

.small-table {
  min-width: 100%;

  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  overflow-x: scroll;
  border-collapse: collapse;
  /* Ensure the table layout is fixed and borders collapse */
}

.table-header-heading th {
  background-color: #ccc;
  text-align: center;
  position: sticky;
  top: 0;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
  /* Ensure the header stays on top */
}

.add-item {
  margin-top: 10px;
}

tbody td {
  padding: 2px 5px;
  margin: 0;
  width: 200px;
  max-width: fit-content;
  font-size: 14px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Ensures the overflowed text is hidden */
  text-overflow: ellipsis; /* Adds the ellipsis when text overflows */
}

.table-header-heading td:nth-child(1) {
  text-align: center;
}

input {
  border: none;
  padding: 5px;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest {
  margin-top: 20px;
  position: fixed;
  border-radius: 0 0 15px 15px;
  bottom: 12px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 74px);
}

.footerPuchaseRequest .btn {
  margin: 5px 25px;
}

.input-bg {
  background-color: #fff;
}

.description {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-input {
  width: 100%;
  text-align: end;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

#date {
  border: 1px solid #000000;
}

.dropdown-menu {
  height: fit-content;
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 7px;
}
.form-label {
  color: white !important;

}
.cell-content {
  display: flex;
 justify-content: space-around;
  align-items: center;
  width: 100%; /* Ensures the div takes the full width of the td */
  margin-top: 7px;
}

.delete-icon img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.row-index {
  /* Optional: Adjust margin or padding as needed */

  font-size: 14px; /* Adjust font size if necessary */
  color: #000; /* Adjust text color if necessary */
}
.AddImage
{
  height:20px;
  width:20px;
  cursor: pointer; 
}
@media (max-width: 768px) {
  .purchase-request {
    padding: 5px 10px;
    height: 50%;
    width: 100%;
  }

  .d-flex.align-items-center {
    display: block !important;
  }

  .d-flex.align-items-center label,
  .d-flex.align-items-center .form-control {
    width: 100% !important;
  }

  .small-table {
    display: block;
    overflow-x: auto;
  }

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  label {
    flex: 0.5;
    white-space: nowrap;
  }

  input {
    flex: 0.5;
  }

}
</style>

<!-- 
<td>
  <input
    class="w-100"
    v-model="product.searchQuery"
    @input="handleInput(index)"
    :ref="'searchQuery-' + index"
    placeholder="Search product..."
  />
  <ul
    v-if="!product.productName && product.searchQuery.trim() !== ''"
    class="dropdown-menu show"
  >
    <li
      v-for="(item, idx) in getFilteredProducts(product.searchQuery)"
      :key="idx"
      class="dropdown-item"
      @click="selectProduct(item, index)"
    >
      <span>{{ item.productName }}</span>
    </li>
    <li
      v-if="getFilteredProducts(product.searchQuery).length === 0"
      class="dropdown-item"
    >
      <button
        class="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target="#ProcurementProductList"
        @click="openModal(null)"
      >
        Add Product
      </button>
    </li>
  </ul>
</td> -->
