<template>
  <div>
    <div
      class="modal fade"
      id="CreateProjectModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">New Project</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <!-- Step Header -->
          <div class="steps-header d-flex justify-content-between">
            <div :class="['step-item', step >= 1 ? 'active' : '']">
              <span>1</span>
              <p>Customer Details</p>
            </div>
            <div :class="['step-item', step >= 2 ? 'active' : '']">
              <span>2</span>
              <p>Estimate Details</p>
            </div>
            <div v-if="showSignOff" :class="['step-item', step >= 3 ? 'active' : '']">
              <span>3</span>
              <p>Sign-Off Document</p>
            </div>
            <div :class="['step-item', step >= (showSignOff ? 4 : 3) ? 'active' : '']">
              <span>{{ showSignOff ? 4 : 3 }}</span>
              <p>Action</p>
            </div>
          </div>

          <div class="modal-body">
            <!-- Step 1: Customer Details -->
            <div v-if="step === 1">
              <div class="mb-3 row">
                <label for="customerName" class="col-md-4 col-form-label"
                  >Name: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    id="customerName"
                    v-model="lead.name"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="customerEmail" class="col-md-4 col-form-label"
                  >Email: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="email"
                    class="form-control"
                    id="customerEmail"
                    v-model="lead.email"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="customerContact" class="col-md-4 col-form-label"
                  >Contact: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    id="customerContact"
                    v-model="lead.contactNo"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="address" class="col-md-4 col-form-label"
                  >Property Address: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    v-model="address.address"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="city" class="col-md-4 col-form-label"
                  >City: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    v-model="address.city"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="state" class="col-md-4 col-form-label"
                  >State: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    id="state"
                    v-model="address.state"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="salesPersonId" class="col-md-4 col-form-label"
                  >Sales Person: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-control"
                    v-model="salesPersonId"
                    @change="setSelectedEmployee"
                    style="cursor: pointer; font-size: 14px"
                  >
                    <option
                      v-for="employee in employees"
                      :key="employee['employees.id']"
                      :value="employee['employees.id']"
                    >
                      {{ employee["employees.firstName"] }}
                      {{ employee["employees.lastName"] }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Step 2: Estimate Details -->
            <div v-if="step === 2">
              <div class="mb-3 row">
                <label for="estimateTotal" class="col-md-4 col-form-label"
                  >Total Amount: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    class="form-control"
                    id="estimateTotal"
                    v-model="estimate.total"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="gst" class="col-md-4 col-form-label"
                  >GST (%): <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <select
                    style="cursor: pointer; font-size: 14px"
                    v-model="estimate.gst"
                    class="form-select form-control"
                  >
                    <option v-for="option in GSTOptions" :key="option" :value="option">
                      {{ option }}%
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="gstAmount" class="col-md-4 col-form-label"
                  >GST Amount: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    class="form-control"
                    id="gstAmount"
                    v-model="estimate.gstAmount"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="grandTotal" class="col-md-4 col-form-label"
                  >Grand Total: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="number"
                    class="form-control"
                    id="grandTotal"
                    v-model="estimate.grandTotal"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="transactionDetails" class="col-md-4 col-form-label"
                  >Payment Transaction Details: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    id="transactionDetails"
                    v-model="transactionDetails"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="EstimateAwsFileId" class="col-md-4 col-form-label"
                  >Upload Estimate Document: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="file"
                    class="form-control"
                    id="EstimateAwsFileId"
                    @change="handleFileUpload()"
                  />
                </div>
              </div>
            </div>

            <div v-if="step === 3 && showSignOff">
              <!-- Design Manager Selection -->
              <div class="mb-3 row">
                <label for="designManager" class="col-md-4 col-form-label"
                  >Design Manager: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-control"
                    v-model="designProject.designManager"
                    @change="setSelectedEmployee"
                    style="cursor: pointer; font-size: 14px"
                  >
                    <option
                      v-for="employee in DesignTeamManager"
                      :key="employee['employees.id']"
                      :value="employee['employees.id']"
                    >
                      {{ employee["employees.firstName"] }}
                      {{ employee["employees.lastName"] }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Designer Selection -->
              <div class="mb-3 row">
                <label for="designer" class="col-md-4 col-form-label"
                  >Designer: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-control"
                    v-model="designProject.designer"
                    style="cursor: pointer; font-size: 14px"
                  >
                    <option
                      v-for="employee in DesignTeam"
                      :key="employee['employees.id']"
                      :value="employee['employees.id']"
                    >
                      {{ employee["employees.firstName"] }}
                      {{ employee["employees.lastName"] }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Design Start and End Dates -->
              <div class="mb-3 row">
                <label for="startDate" class="col-md-4 col-form-label"
                  >Design Start Date: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="date"
                    class="form-control"
                    v-model="designProject.designStartDate"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="endDate" class="col-md-4 col-form-label"
                  >Design End Date: <span class="text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="date"
                    class="form-control"
                    v-model="designProject.designEndDate"
                  />
                </div>
              </div>

              <!-- Sign-Off Document Upload -->
              <div class="mb-3 row">
                <label for="signOffDoc" class="col-md-4 col-form-label"
                  >Sign-Off Document:</label
                >
                <div class="col-md-7">
                  <div
                    class="mt-2"
                    @click="triggerFileUpload"
                    style="
                      width: 25px;
                      height: 25px;
                      border-radius: 50%;
                      border: 2px dashed #ccc;
                      cursor: pointer;
                    "
                  >
                    <span class="d-flex justify-content-center align-content-center"
                      >+</span
                    >
                  </div>
                  <input
                    type="file"
                    id="signOffDoc"
                    multiple
                    ref="fileInput"
                    @change="handleDesignFileUpload"
                    style="display: none"
                  />

                  <!-- Display uploaded file names -->
                  <ul v-if="uploadedFiles.length">
                    <li
                      v-for="(file, index) in uploadedFiles"
                      :key="file.name"
                      class="file-item mr-2 d-flex justify-content-between align-items-center"
                    >
                      <!-- Display PDF image if the file type is PDF -->
                      <div>
                        <img
                          src="../../assets/icons/pdfImage.png"
                          alt="PDF"
                          class="file-icon"
                        />
                      </div>
                      <!-- Display file name and size -->
                      <div class="file-info">
                        <span class="file-name" :title="file.name">{{ file.name }}</span>
                        <span class="file-size">{{ formatFileSize(file.size) }}</span>
                      </div>
                      <!-- Remove file button -->
                      <div>
                        <span @click="removeFile(index)" class="remove-file-button"
                          >&#10005;</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Step 3: Action -->
            <div v-if="step === (showSignOff ? 4 : 3)">
              <div class="mb-3">
                <div class="step">
                  <span class="step-number">1.</span>
                  <p>
                    <strong>{{ this.lead.name }}</strong> will be created as a customer
                    under the sales team.
                  </p>
                </div>
                <div class="step">
                  <span class="step-number">2.</span>
                  <p>An approved estimate will be generated for this customer.</p>
                </div>
                <div class="step">
                  <span class="step-number">3.</span>
                  <p>
                    The sales target and total sales for the approved estimate will be
                    credited to the assigned sales executive or manager.
                  </p>
                </div>
                <div class="step">
                  <span class="step-number">4.</span>
                  <p>
                    A design team project will be initiated for
                    <strong>{{ this.lead.name }}</strong
                    >.
                  </p>
                </div>
              </div>

              <div class="custom-checkbox mt-5">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="agreementCheckbox"
                  v-model="agreementChecked"
                />
                <label
                  v-if="!agreementChecked"
                  class="form-check-label ms-2 text-danger"
                  for="agreementCheckbox"
                >
                  I Agree to all the above instructions.
                </label>
                <label
                  v-else
                  class="form-check-label ms-2 text-success"
                  for="agreementCheckbox"
                >
                  Thank You
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- Previous and Next Buttons -->
            <button
              v-if="step > 1"
              type="button"
              class="btn btn-sm btn-secondary"
              @click="previousStep"
            >
              Back
            </button>
            <button
              v-if="step < (showSignOff ? 4 : 3)"
              type="button"
              class="btn btn-sm btn-primary"
              @click="nextStep"
            >
              Next
            </button>
        
            <!-- Submit Button -->
            <button
              v-if="step === (showSignOff ? 4 : 3)"
              type="button"
              class="btn btn-sm btn-success confirmationButton"
              :disabled="!agreementChecked || loading"
              @click="submitForm"
              id="EmployeeSignButton"
            >
              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-if="!loading" class="confirmationButton__text">Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CreateProjectModal",
  props: {
    showSignOff: {
      type: Boolean,
      default: false, // This will control if the sign-off step is shown or not
    },
  },
  data() {
    return {
      loading: false,
      DesignTeamManager: [],
      DesignTeam: [],
      agreementChecked: false,
      GSTOptions: [0, 5, 12, 18, 28],
      employees: [],
      step: 1, // Current step
      salesPersonId: null,
      status: "Sold",
      transactionDetails: " ",
      lead: {
        name: "",
        email: "",
        contactNo: "",
      },
      address: {
        address: "",
        city: "",
        state: "",
      },
      estimate: {
        total: null,
        gst: null,
        gstAmount: null,
        grandTotal: null,
      },
      designProject: {
        awsFileId: [],
        designer: null,
        designManager: null,
        designStartDate: null,
        designEndDate: null,
      },
      uploadedFiles: [], // Array to store uploaded file details
      awsFileIds: [], // Array to store uploaded file IDs
      awsFileId: null,
    };
  },
  mounted() {
    this.fetchEmployees();
    this.fetchEmployeesOfDesign();
  },
  methods: {
    async submitForm() {
  const btn = document.querySelector(".confirmationButton");
  btn.classList.add("confirmationButton--loading");
  this.loading = true;

  // Run validation before proceeding
  if (!this.validateForm()) {
    btn.classList.remove("confirmationButton--loading");
    this.loading = false;
    return; // Stop function if validation fails
  }

  // Prepare formData
  const formData = {
    salesPersonId: this.salesPersonId,
    status: this.status,
    transactionDetails: this.transactionDetails,
    lead: {
      name: this.lead.name,
      email: this.lead.email,
      contactNo: this.lead.contactNo,
    },
    address: {
      address: this.address.address,
      city: this.address.city,
      state: this.address.state,
    },
    estimate: {
      total: this.estimate.total,
      gst: this.estimate.gst,
      gstAmount: this.estimate.gstAmount,
      grandTotal: this.estimate.grandTotal,
      awsFileId: this.awsFileId,
    },
    // Conditionally add 'designProject' if 'showSignOff' is true
    ...(this.showSignOff ? { designProject: this.designProject } : {}),
  };

  const apiUrl = this.showSignOff
    ? `${this.$apiEndPoint}/directProject/Procurement`
    : `${this.$apiEndPoint}/directProject/Designing`;

  try {
    // Post data using axios
    await axios.post(apiUrl, formData);
    this.$toast("Project created successfully.", "success");
    location.reload(); // Reload page or navigate if needed
  } catch (error) {
    this.$handleError(error);
  } finally {
    btn.classList.remove("confirmationButton--loading");
    this.loading = false; // Reset loading state after submission
  }
},

    removeFile(index) {
      // Remove the file from the uploadedFiles array
      this.uploadedFiles.splice(index, 1);

      // Remove the corresponding file ID from the awsFileIds array
      this.designProject.awsFileId.splice(index, 1);
    },

    formatFileSize(size) {
      if (size === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handleDesignFileUpload(event) {
      const files = event.target.files;
      if (files.length) {
        Array.from(files).forEach((file) => {
          this.uploadFileToS3(file);
        });
      }
    },
    nextStep() {
      // Only go to the Sign-Off step if the parameter allows it
      if (this.step === 2 && this.showSignOff) {
        this.step = 3; // Move to Sign-Off step
      } else {
        this.step++; // Regular step movement
      }
    },

    fetchEmployeesOfDesign() {
      axios
        .get(`${this.$apiEndPoint}/employee/myTeam/Designing`) // replace with your API endpoint
        .then((response) => {
          this.DesignTeam = response.data;
          this.DesignTeamManager = response.data.filter(
            (employee) => employee.role === "Manager"
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    uploadFileToS3(file) {
      axios
        .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          fileCategory: "signOffDocument",
          moduleName: "design",
        })
        .then((response) => {
          const signedUrl = response.data.s3URL;
          const fileId = response.data.id;

          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
      

              // Add uploaded file info to arrays
              this.uploadedFiles.push(file);
              this.designProject.awsFileId.push(fileId);
            } else if (xhr.status !== 200) {
              this.$handleError(xhr.responseText); 
            }
          };

          xhr.send(file);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    handleFileUpload() {
      const file = document.getElementById("EstimateAwsFileId").files[0]; // Get the selected file
      if (file) {
        // Make API call to get signed URL for file upload
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "estimate",
            moduleName: "sales",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.awsFileId = response.data.id;

            // Use XMLHttpRequest to upload the file to the signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$toast("File uploaded successfully", "success");

                  // Handle successful upload
                } else {
                  this.$handleError(xhr.responseText);
                  // Handle upload error
                }
              }
            };

            // Send the file as binary data
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/employee/myTeam/Sales`) // replace with your API endpoint
        .then((response) => {
          this.employees = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    previousStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    validateForm() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.remove("confirmationButton--loading");
      // Basic validation for mandatory fields
      if (
        !this.salesPersonId ||
        !this.status ||
        !this.transactionDetails ||
        !this.lead.name ||
        !this.lead.email ||
        !this.lead.contactNo ||
        !this.address.address ||
        !this.address.city ||
        !this.address.state ||
        !this.estimate.total ||
        !this.estimate.gst ||
        !this.estimate.gstAmount ||
        !this.estimate.grandTotal ||
        !this.awsFileId
      ) {
        this.$toast("Please fill out all mandatory fields.", "error");
        const btn = document.querySelector(".confirmationButton");
        btn.classList.remove("confirmationButton--loading");
        return false; // Validation failed
      }

      // Additional validation if 'showSignOff' is true
      if (this.showSignOff && ( !this.designProject.designer ||!this.designProject.designManager || !this.designProject.designStartDate || !this.designProject.designEndDate) || !this.designProject.awsFileId) {
        this.$toast("Please fill out the design project details.", "error");
      
        return false; // Validation failed
      }

     
      return true; // Validation passed
    },

    // Your original submitForm logic

    resetForm() {
      // Reset all the form fields to their initial state
      this.salesPersonId = null;
      this.status = "Sold";
      this.transactionDetails = " ";
      this.lead = {
        name: "",
        email: "",
        contactNo: "",
      };
      this.address = {
        address: "",
        city: "",
        state: "",
      };
      this.estimate = {
        total: null,
        gst: null,
        gstAmount: null,
        grandTotal: null,
      };
      this.designProject = {
        awsFileId: [],
        designer: null,
        designManager: null,
        designStartDate: null,
        designEndDate: null,
      };
      this.uploadedFiles = [];
      this.awsFileIds = [];
      this.agreementChecked = false;
      this.step = 1; // Reset to the first step
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}

.modal-content {
  min-width: 550px; /* Set a fixed width */
  max-width: 550px;
  min-height: 600px; /* Set a fixed height */
  max-height: 600px;
  display: flex;
  flex-direction: column; /* Allow header, body, and footer to stack */
}

.modal-header,
.modal-footer {
  padding: 0.5rem 1rem; /* Reduce padding */
}

.modal-header {
  margin-bottom: 0; /* Remove bottom margin to reduce space */
}

.modal-footer {
  margin-top: 0; /* Remove top margin to reduce space */
}

.modal-title {
  font-size: 1.25rem; /* Adjust title size if needed */
}
.modal-body {
  font-size: 14px;
  overflow-y: auto; /* Enable vertical scrolling */
  flex: 1; /* Allow modal body to take up remaining space */
}
.modal-body::-webkit-scrollbar {
  display: none;
}

.steps-header {
  margin: 10px 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.step-item {
  flex: 1;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
}

.step-item span {
  display: block;
  background-color: #ddd;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 25px;
  color: #fff;

  background-color: #007bff;
}

.step-item.active span {
  background-color: #28a745; /* Green for active/completed steps */
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem; /* Space between items */
}

.step-number {
  margin-right: 0.5rem; /* Space between number and text */
  font-weight: bold; /* Make the number bold */
  flex-shrink: 0; /* Prevents the number from shrinking */
}

.step p {
  margin: 0; /* Remove default margin for paragraphs */
  line-height: 1.5; /* Adjust line height for readability */
}
.round {
  width: 30px;
  height: 30px;
  border: 2px dashed #ccc;
  border-radius: 50%;
}
.round {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-item {
  font-size: 10px;
  background-color: #f8f8f8;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.file-icon {
  width: 25px;
  height: 25px;
}

.file-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.file-name {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}

.file-size {
  font-size: 10px;
}

.remove-file-button {
  cursor: pointer;
  margin-left: 20px;
}



</style>
