import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import GlobalProperties from './plugins/globalProperties';
import './assets/sass/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const app = createApp(App);
app.use(GlobalProperties);
app.use(router);
app.mount('#app');
// app.use(router).mount('#app');
