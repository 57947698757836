<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddonSitePettyCash"
      tabindex="-1"
      aria-labelledby="AddonSitePettyCashLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5
              v-if="pettyCashId === 'null'"
              class="modal-title w-100"
              id="AddonSitePettyCashLabel"
            >
              New Entry
            </h5>
            <h5 v-else class="modal-title w-100" id="AddonSitePettyCashLabel">
              Update Entry
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              @submit.prevent="
                pettyCashId === 'null' ? AddonSitePettyCash() : UpdateData()
              "
            >
              <div class="mb-3 row">
                <label for="activityDate" class="col-sm-4 col-form-label">Date</label>
                <div class="col-sm-8">
                  <input
                    v-model="form.date"
                    type="date"
                    class="form-control"
                    id="activityDate"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="projectName" class="col-sm-4 col-form-label"
                  >Project Name</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control form-select"
                    id="projectName"
                    v-model="form.projectId"
                  >
                    <option
                      v-for="Project in Projects"
                      :key="Project.projectId"
                      :value="Project.projectId"
                    >
                      {{ Project["projects.projectName"] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="account" class="col-sm-4 col-form-label">Account</label>
                <div class="col-sm-8">
                  <select
                    v-model="form.account"
                    class="form-control form-select"
                    id="account"
                  >
                    <option value="Bank">Bank</option>
                    <option value="Cash">Cash</option>
                    <option value="Credit Card">Credit Card</option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="workDescription" class="col-sm-4 col-form-label">
                  Description</label
                >
                <div class="col-sm-8">
                  <input
                    v-model="form.description"
                    type="text"
                    class="form-control"
                    id="workDescription"
                    placeholder="Enter work description"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="cashType" class="col-sm-4 col-form-label">Cash Type</label>
                <div class="col-sm-8">
                  <select
                    v-model="form.cashType"
                    class="form-control form-select"
                    id="cashType"
                  >
                    <option value="cashIn">Cash In</option>
                    <option value="cashOut">Cash Out</option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="transactionAmount" class="col-sm-4 col-form-label"
                  >Transaction Amount</label
                >
                <div class="col-sm-8">
                  <input
                    v-if="form.cashType === 'cashIn'"
                    v-model="form.cashIn"
                    type="number"
                    class="form-control"
                    id="transactionAmount"
                    placeholder="Enter amount"
                  />
                  <input
                    v-else
                    v-model="form.cashOut"
                    type="number"
                    class="form-control"
                    id="transactionAmount"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="uploadBill" class="col-sm-4 col-form-label"
                  >Upload Bill</label
                >
                <div class="col-sm-8">
                  <input
                    type="file"
                    class="form-control"
                    id="uploadBill"
                    @change="handleFileUpload"
                  />
                  <div v-if="form.awsFileId">
                    <p
                      class="file-name"
                      @click="
                        $viewFile(
                          form['awsFiles.awsFileName'],
                          form['awsFiles.fileName'],
                          'onSitePettyCash',
                          'onSite'
                        )
                      "
                      :title="form['awsFiles.fileName']"
                    >
                      {{ form["awsFiles.fileName"] }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-primary btn-sm"
                  data-bs-dismiss="modal"
                >
                  {{ pettyCashId === "null" ? "Submit" : "Update" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AddOnSitePettyCashModal",
  props: {
    pettyCashId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localQuotationId: null,
      onSitePettyCash: null,
      form: {
        id: null,
        date: new Date().toISOString().slice(0, 10),
        projectId: null,
        account: null,
        description: "",
        cashType: "",
        cashIn: null,
        cashOut: null,
        awsFileId: null,
      },
      Projects: [],
    };
  },
  mounted() {
    this.fetchProjects();
  },
  watch: {
    pettyCashId(newValue) {
      this.localQuotationId = newValue;
      if (newValue !== null) {
        this.fetchPettyCashById();
      } else {
        this.form.id = null;
      }
    },
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        date: new Date().toISOString().slice(0, 10),
        awsFileId: null,
        projectId: null,
        account: null,
        description: "",
        cashOut: null,
        cashIn: null,
      };
    },
    async UpdateData() {


      try {
      await axios.put(
          `${this.$apiEndPoint}/pettyCash/${this.localQuotationId}`,
          this.form
        );
      

        this.resetForm();
        this.$emit("fetchData");
      } catch (error) {
        this.$handleError(error);
      }
    },

    async AddonSitePettyCash() {

      try {
        if (this.form.cashIn !== null) {
          await axios.post(`${this.$apiEndPoint}/pettyCash/`, this.form);

          this.resetForm();
          this.$emit("fetchData");
        } else {
         await axios.post(
            `${this.$apiEndPoint}/pettyCash/addExpense`,
            this.form
          );
      
          this.resetForm();
          this.$emit("fetchData");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleFileUpload() {
      const file = document.getElementById("uploadBill").files[0];
      if (file) {
        this.form.awsFileId = "";
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "onSitePettyCash",
            moduleName: "onSite",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.form.awsFileId = response.data.id;
            this.form["awsFiles.fileName"] = file.name;
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$toast("File uploaded successfully", "success");
                } else {
                  this.$handleError(xhr.responseText);
                }
              }
            };
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    fetchProjects() {
      axios
        .get(
          `${this.$apiEndPoint}/onSiteSupervisors/collaboratedProjects/${this.$LocalUser.user.employeeId}`
        )
        .then((response) => {
          this.Projects = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async fetchPettyCashById() {
   
      if (this.localQuotationId !== "null") {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/pettyCash/${this.localQuotationId}`
          );
          const data = response.data;

          // Update form fields with the response data
          this.form = {
            id: data.id,
            date: data.date,
            projectId: data.projectId,
            account: data.account,
            description: data.description,
            cashIn: data.cashIn,
            cashOut: data.cashOut,
            awsFileId: data.awsFileId ? data.awsFileId : null,

            // Dynamically set cashType based on available cashIn or cashOut
            cashType: data.cashIn !== null ? "cashIn" : "cashOut",
          };
          this.form["awsFiles.fileName"] = data["awsFiles.fileName"];
          this.form["awsFiles.awsFileName"] = data["awsFiles.awsFileName"];
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        // Reset the form if no petty cash ID is provided
        this.resetForm();

      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
label,
input,
select,
textarea,
p {
  font-size: 12px;
}
.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  width: calc(100% - 28%);
  color: #000000;
  top: 20%;
  left: 0;
}
.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}
.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
.file-name {
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Hides overflow */
  text-overflow: ellipsis; /* Adds ellipsis when text overflows */
  max-width: 200px; /* Adjust the width as needed */
  display: inline-block;
  cursor: pointer;
}

.file-name:hover {
  text-decoration: underline; /* Adds underline on hover */
}
</style>
