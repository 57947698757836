<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="attendance-page">
          <h6 class="text-start title mt-3 ms-3 mb-2">Attendance</h6>

          <div class="attendance-container">
            <!-- Filter Section -->
            <div class="row">
              <div class="col-12 d-flex flex-wrap gap-2">
                <select
                  v-model="selectedTeam"
                  class="form-select me-3 year-select"
                  @change="handleTeamChange"
                >
                  <option value="All">All</option>
                  <option
                    v-for="(department, index) in departments"
                    :key="index"
                    :value="department.name"
                  >
                    {{ department.name }}
                  </option>
                </select>
                <button
                class="btn btn-sm  me-3 btn-outline-secondary   align-content-center reportButton"
              
              >
              Report  -
                <img
                  src="../../assets/icons/calandar.png"
                  class="mb-1"
                  data-toggle="tooltip"
                  data-placement="top-start"
                  style="width: 16px; height: 16px"
                  alt="Calendar Icon"
                />
                
             
              
              </button>
              <button
              class="btn btn-sm   btn-outline-secondary   align-content-center reportButton"
             
            >
            Export  -
              <img
                src="../../assets/icons/export.png"
                class="mb-1"
                data-toggle="tooltip"
                data-placement="top-start"
                style="width: 16px; height: 16px"
                alt="Calendar Icon"
              />
              
           
            
            </button>
              
              </div>
            </div>

            <!-- Attendance History -->
            <div class="row mt-3">
              <div class="col-12 " >
                <div class="attendance-history-table-container">
                  <table class="table table-striped">
                    <thead class="sticky-top">
                      <tr>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>Login</th>
                        <th>Logout</th>
                        <th>Attendance</th>
                        <!-- <th>Work Location</th> -->
                        <!-- <th>Shift</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="record in attendanceHistory" :key="record.id">
                        <td>
                          <span style="margin-right: 20px">
                            <img
                              v-if="record.signedUrl"
                              :src="record.signedUrl"
                              style="width: 30px; height: 30px; border-radius: 50%"
                              alt=""
                            />
                            <img
                              v-else
                              class="profile-photo"
                              src="../../assets/icons/profile.png"
                              alt="Profile Photo"
                              style="width: 30px; height: 30px; border-radius: 50%"
                            />
                          </span>
                          {{ record.firstName }}&nbsp;{{ record.lastName }}
                        </td>
                        <td>
                          {{ record.employeeNumber }}
                        </td>

                        <td>{{ formatTime(record["employeeAttendance.logInTime"]) }}</td>
                        <td>{{ formatTime(record["employeeAttendance.logOutTime"]) }}</td>
                        <td>
                          <span
                          v-if=" record['employeeAttendance.attendance']"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            :class="{
                              badge: true,
                              'bg-success':
                                record['employeeAttendance.attendance'].toLowerCase() ===
                                'present',
                              'bg-danger':
                                record['employeeAttendance.attendance'].toLowerCase() ===
                                'absent',
                              'bg-info':
                                record['employeeAttendance.attendance'].toLowerCase() ===
                                'halfday',
                            }"
                          >
                            {{ record["employeeAttendance.attendance"] }}
                          </span>
                          <div
                          style=" font-size:12px !important;"
                            class="dropdown-menu statusdropdown"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <button
                              class="dropdown-item text-success"
                              @click="
                                changeStatus(record['employeeAttendance.id'], 'Present')
                              "
                            >
                            Present
                            </button>
                            <button
                              class="dropdown-item text-danger"
                              @click="
                                changeStatus(record['employeeAttendance.id'], 'Absent')
                              "
                            >
                              Absent
                            </button>
                            <button
                              class="dropdown-item text-info" 
                              @click="
                                changeStatus(record['employeeAttendance.id'], 'HalfDay')
                              "
                            >
                              Half Day
                            </button>
                          </div>
                        </td>
                        <!-- <td :title="record.firstLogInLocation" class="ellipsis">
                          {{ record.firstLogInLocation || "-" }}
                        </td> -->
                        <!-- <td></td> -->
                      </tr>
                      <tr v-if="attendanceHistory.length === 0">
                        <td colspan="6" class="text-center">
                          No attendance records found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- Modal -->
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
export default {
  name: "Attendance",
  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    return {
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      attendanceHistory: [],
      todayAttendance: [],
      Checktype: "CheckIn",
      exactLocation: null,
      selectedLocationId: null,
      locationData: [],
      showLocationModal: false,
      currentDate: new Date().toISOString().split("T")[0],
      EmployeeprofileImage: null,
      employeeDetails: {},
      selectedTeam: "All", // Set default to current year
      selectedMonth: currentMonth, // Set default to current month index
      years: Array.from({ length: 11 }, (_, i) => currentYear - 5 + i),
      departments: [
        { name: "Marketing" },
        { name: "Sales" },
        { name: "Designing" },
        { name: "Procurement" },
        { name: "Onsite" },
        { name: "Execution" },
        { name: "Inventory" },
        { name: "Accounts" },
        { name: "HR" },
        { name: "Admin" },
      ],
    };
  },
  components: {
    Header,
    Nav,
  },
  mounted() {
    this.fetchMonthlyAttendance();
  },
  methods: {
    async fetchMonthlyAttendance() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/attendance/today/${this.selectedTeam}/`
        );
        this.attendanceHistory = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async changeStatus(Id, status) {
      try {
        await axios.put(`${this.$apiEndPoint}/attendance/updateAttendance/${Id}`,
         {
          attendance:status,
        }
      );
        // Assuming you have a method to fetch source after status change
        this.fetchMonthlyAttendance();
      } catch (error) {
        console.log(error);
        this.$handleError(error);
      }
    },
    formatTime(time) {
      if (!time) return "";
      const [hours, minutes] = time.split(":");
      const ampm = hours >= 12 ? "p.m" : "a.m";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes} ${ampm}`;
    },
    async fetchTodayAttendance() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/myWorkHub/getTodayAttendance/${this.selectedEmployeeId}`
        );
        this.todayAttendance = response.data.todayAttendance;
        this.employeeDetails = response.data.employee;
        this.EmployeeprofileImage = response.data.employee.signedUrl;
      } catch (error) {
        this.$handleError(error);
      }
    },
    // featchEmployeeData() {
    //   axios
    //     .get(`${this.$apiEndPoint}/employee/${this.selectedEmployeeId}`)
    //     .then((response) => {
    //       this.employeeDetails = response.data;
    //       this.EmployeeprofileImage = response.data.signedUrl;
    //     })
    //     .catch((error) => {
    //       this.$handleError(error);
    //     });
    // },
    async submitSelectedLocation() {
      try {
        const selectedLocation = this.locationData.find(
          (location) => location.id === this.selectedLocationId
        );

        if (!selectedLocation) {
          this.$toast.error("Please select a location");
          return;
        }

        // Filter out null/undefined values and build the address string
        const addressParts = [
          selectedLocation.address,
          selectedLocation.city,
          selectedLocation.state,
          selectedLocation.pincode,
        ].filter((part) => part); // This removes any null, undefined, or empty strings

        const locationAddress = addressParts.join(", ");

        if (!locationAddress) {
          this.$toast.error("Invalid location details");
          return;
        }

        const now = new Date();
        const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(
          now.getMinutes()
        ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

        const payload = {
          location: locationAddress,
          time: currentTime,
        };

        await axios.post(`${this.$apiEndPoint}/myWorkHub/${this.Checktype}`, payload);
        this.$toast(`${this.Checktype} successful`, "success");
        await this.fetchTodayAttendance();
        this.fetchMonthlyAttendance();
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleTeamChange() {
      if (this.selectedTeam) {
        this.fetchMonthlyAttendance();
      }
    },
    // ..

    handleCheckIn(Checktype) {
      this.Checktype = Checktype;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.fetchLocationData(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    async fetchLocationData(latitude, longitude) {
      this.showLocationModal = true; // Show the modal with location details
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/myWorkHub/getGoogleLocation`,
          {
            latitude,
            longitude,
          }
        );
        this.locationData = response.data.similarAddresses || [];
        this.exactLocation = response.data.googleAddress;
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    },
    closeModal() {
      this.showLocationModal = false;
    },
  },
};
</script>

<style scoped>
.attendance-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

.attendance-container {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
  font-size: 0.9rem;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000;
}

.profile-image {
  display: flex;
  align-items: center;
  /* Align image to the start */
}

.TodayAttendanceContainer {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
}

.employee-details span {
  display: block;
  margin-bottom: 5px;
}

.employee-details label {
  font-size: 12px;
}

.employee-details-row {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.action-buttons button {
  width: 80px;
  font-size: 0.8rem;
}

.section-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.attendance-table-container {
  max-height: 150px;
  overflow-y: auto;
}

.year-select,
.month-select
 {
  width: auto;
  font-size: 0.85rem;
}
.reportButton
{
height: 32px;
margin-top: 1px;
}

.attendance-history-table-container {
  /* Replace percentage height with fixed height or viewport units */
  height: 70vh; /* or use vh units: 40vh */
  overflow-y: auto;
  /* Add these properties for better table behavior */
  position: relative;
}

table th {
  background-color: #ccc !important;
}

table th:first-child {
  border-radius: 5px 0 0 5px;
}

table th:last-child {
  border-radius: 0 5px 5px 0;
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.location-ticker {
  margin-top: 10px;
  background: linear-gradient(90deg, #f8f9fa, #e9ecef);

  padding: 8px;
  overflow: hidden;
  position: relative;
}

.ticker-content {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #495057;
  animation: slide 10s linear infinite;
  white-space: nowrap;
}

.ticker-content i {
  color: #dc3545;
  margin-right: 8px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Optional: Pause animation on hover */
.location-ticker:hover .ticker-content {
  animation-play-state: paused;
}

.location-table-body tr:hover {
  background-color: #f0f0f0;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .employee-details-row {
    flex-direction: column;
    gap: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .TodayAttendanceContainer {
    margin-left: 0 !important;
    /* Removes ms-5 margin on mobile */
    margin-top: 15px;
    /* Adds some vertical spacing instead */
  }

  .attendance-page {
    height: 100%;
    width: 100%;
    overflow: scroll;
  }
}
</style>
