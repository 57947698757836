<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="d-flex align-items-center justify-content-between px-3 pt-2 mt-0">
          <div class="d-flex align-items-center gap-3">
            <h5 style="font-weight: bold; padding-top: 18px">Goods Received Note</h5>
            <img
              class="add-icon"
              @click="goToInventoryGrn(this.$route.params.moduleName)"
              src="../../assets/icons/blueAddicon.png"
            />
          </div>

          <div class="search-container">
            <span>
              <img src="../../assets/icons/search.png" class="search-img" alt="" />
            </span>
            <input
              type="text"
              class="search"
              v-model="searchString"
              placeholder="Search"
              style="width: 200px; border: none; border-radius: 0; font-size: small"
              @input="onSearchInput"
            />
          </div>
        </div>

        <div class="table-structure">
          <table class="custom-table">
            <thead>
              <tr>
                <th>GRN No.</th>
                <th>PO Number</th>
                <th>Document No</th>
                <th>Document Type</th>
                <th>Received Date</th>
                <th>Received By</th>
                <th>Project Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in grnData"
                :key="index"
                @click="storeSelectedId(item.id)"
              >
                <td>{{ item.grnNumber }}</td>
                <td>{{ item["purchaseOrders.poNumber"] }}</td>
                <td>{{ item.documentNo }}</td>
                <td>{{ item.documentType }}</td>
                <td>{{ item.receivedDate }}</td>
                <td>{{ item.receiverName }}</td>
                <td>{{ item["projects.projectName"] }}</td>
                <td class="action-icons">
                  <!-- <img
                    v-if="item.documentType === 'Tax Invoice'"
                    @click.stop="viewFile('purchaseInvoice', item)"
                    src="../../assets/icons/view.png"
                    alt="Completed"
                    style="width: 15px; height: 15px; cursor: pointer"
                  />
                  <img
                    v-else
                    @click.stop="viewFile('deliveryChallen', item)"
                    src="../../assets/icons/view.png"
                    alt="Completed"
                    style="width: 15px; height: 15px; cursor: pointer"
                  /> -->

                  <span
                    @click="openGRNModal(item.id)"
                    data-bs-toggle="modal"
                    data-bs-target="#OnSiteGrnDetailsModal"
                  >
                    <img
                      src="../../assets/icons/view.png"
                      data-toggle="tooltip"
                      data-placement="top-start"
                      title="view"
                      alt=""
                    />
                  </span>
                  <span @click="handleDownloadGRN(item.id)">
                    <img
                      src="../../assets/icons/pinkDownold.png"
                      data-toggle="tooltip"
                      data-placement="top-start"
                      title="downold"
                      alt=""
                    />
                  </span>
                  <span>
                    <img
                      @click="DeleteGrn(item.id, index)"
                      src="../../assets/icons/redDelete.png"
                      data-toggle="tooltip"
                      data-placement="top-start"
                      title="Delete"
                      alt=""
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <OnSiteGrnDetailsModal :GRNId="selectedGrnId" ref="OnSiteGrnDetailsModal" />
  </div>
</template>

<script>
import OnSiteGrnDetailsModal from "../Modal/OnSiteGrnDetailsModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  data() {
    return {
      grnData: [],
      searchString: "", // Search input value
      previousSearchString: "", // Keeps the previous value for comparison
      selectedGrnId: null,
    };
  },

  mounted() {
    this.fetchGrnData();
  },

  components: {
    Header,
    Nav,
    OnSiteGrnDetailsModal,
  },
  methods: {
    async DeleteGrn(timeLineid, index) {
      this.grnData.splice(index, 1);
      console.log(index);
      try {
        await axios.delete(`${this.$apiEndPoint}/OnSiteGrn/${timeLineid}`);
        this.fetchGrnData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    storeSelectedId(id) {
      this.selectedGrnId = id; // Store the selected row ID
      console.log("Selected Row ID:", this.selectedGrnId);
    },

    handleDownloadGRN(id) {
      this.selectedGrnId = id;
      setTimeout(() => {
        this.$refs.OnSiteGrnDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    onSearchInput() {
      if (this.searchString !== this.previousSearchString) {
        this.previousSearchString = this.searchString;
        this.fetchGrnData();
      }
    },

    openGRNModal(Id) {
      this.selectedGrnId = Id;
      console.log(this.selectedGrnId);
    },

    goToInventoryGrn(moduleName) {
      this.$router.push({
        name: "GrnForm",
        params: { moduleName: moduleName },
      });
    },

    async fetchGrnData() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/onSiteGrn/grnForInventory/1`,
          {
            searchString: this.searchString,
          }
        );
        this.grnData = response.data.rows;
        console.log("Grn data", this.grnData);
      } catch (error) {
        console.error("Error fetching GRN data:", error);
      }
    },
  },
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
}

.custom-table th,
.custom-table td {
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: small;
  padding: 10px;
}

.custom-table th {
  position: sticky;
  top: 0; /* Sticks the header to the top of the container */
  z-index: 1; /* Keeps header above the body */
  background-color: #bcbfc6;
  font-weight: 600;
  padding: 8px;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #ffff;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #ece9e9;
}

.custom-table .action-icons img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}

.custom-table .action-icons img:hover {
  opacity: 0.8;
}

.table-structure {
  max-height: 80vh; /* Set the maximum height for the table container */
  overflow-y: auto; /* Add vertical scrolling */
  overflow-x: hidden;
  padding: 0 1% 0% 1%;
}

.container-bg {
  background-color: #fcfcfc;
}

.add-icon {
  width: 6%;
  height: 6%;
  margin-top: 2%;
}

.search-img {
  width: 15px;
  height: 15px;
}

.search:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid #141414;
  font-size: small;
}

.search-container {
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
}
</style>
